import React, {Component, useRef, useEffect} from 'react'
import {Button, Loader, Message} from "semantic-ui-react";
import {CustomContainer, CustomDivider, CustomGrid} from './../custom-common';
import Footer from "./../footer";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {setEConsentSigned} from "../../actions/eConsentActions";
import {setIsStopscreenDisplayed} from "../../actions/stopScreensActions";
import {resolveErrorMessage} from "../../utils/common";
import MainMenu from "./../MainMenu";
import withPreventBackNavigation from '../withPreventBackNavigation';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        eConsentData: state.eConsent
    }
};

const mapDispatchToProps = dispatch => ({
    setEConsentSigned: consent => dispatch(setEConsentSigned(consent)),
    setIsStopscreenDisplayed: isStopscreenDisplayed => dispatch(setIsStopscreenDisplayed(isStopscreenDisplayed)),
});

class GenericConsent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consentContent: null,
            consentNoticeError: null,
            updateConsentError: null,
            loading: null,
            loadingConsent: false,
        }
    }

    async componentDidMount() {
        this.setState({loading: true});
        try {
            const result = await axios.post("/api/member/v2/prepareConsentNotice", {carrierState: ''}, {headers: {'Content-Type': 'application/json'}});
            this.setState({consentContent: result.data});
        } catch (e) {
            this.setState({consentNoticeError: resolveErrorMessage(e, "It was not possible to load consent notice")});
        } finally {
            this.setState({loading: false});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {eConsentData, history, setIsStopscreenDisplayed, unblock} = this.props;
        if (eConsentData?.signed) {
            if (unblock) {
                unblock();
            }
            setIsStopscreenDisplayed(false);
            history.push("/plan");
        }
    }

    updateConsent = async () => {
        this.setState({loadingConsent: true})
        const payload = {eConsent: true}
        try {
            await axios.post("/api/member/v2/updateEConsent", payload, {headers: {'Content-Type': 'application/json'}});
            this.props.setEConsentSigned(true);
        } catch (e) {
            this.setState({updateConsentError: resolveErrorMessage(e, 'An unexpected error has occurred')})
        } finally {
            this.setState({loadingConsent: false});
        }
    }

    render() {
        const {loadingConsent, consentContent, loading, consentNoticeError, updateConsentError} = this.state;
        const button = (
            !consentNoticeError && <Button
                primary
                className='nowrap'
                content='I agree'
                loading={loadingConsent}
                onClick={this.updateConsent}
            />
        )
        return loading ? (<Loader active/>) : (
            <div className="member-main-container">
                <MainMenu/>
                <CustomGrid container stackable columns={1} centered>
                    <CustomGrid.Column className={"pageContent"}>
                        <CustomDivider hidden/>
                        {consentNoticeError ? <Message negative>{consentNoticeError}</Message> :
                            <CustomContainer padded={"very"} dangerouslySetInnerHTML={{__html: consentContent}}></CustomContainer>}
                        {!!updateConsentError && <Message negative>{updateConsentError}</Message>}
                    </CustomGrid.Column>
                </CustomGrid>
                <Footer showTopFooter={true} showCenterData={true} button={button} largeButton/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withPreventBackNavigation(GenericConsent)));
