import React from 'react';
import {Modal, Button} from "semantic-ui-react";
import {CustomModalHeader} from '../custom-common';

const RemoveUnverifiedBankAccountModal = (props) => {
    const {
        show,
        close,
        removeBankAccount,
        bankAccountData,
        strings,
        hide,
    } = props;

    const handleSubmit = () => {
        hide(bankAccountData.accountId)
        removeBankAccount(bankAccountData.accountId)
        close()
    };

    return (
        <Modal size={'small'} onClose={close} open={show} style={{maxWidth: '570px'}}>
            <CustomModalHeader title={strings.header} onClose={close} className="customModalHeader" />
            <Modal.Content style={{overflowY: 'auto'}}>
                {strings.contentDeleting}
                <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
                     <>
                        <Button color='grey' basic onClick={close}>{strings.cancel}</Button>
                        <Button className='button primary' onClick={handleSubmit}>{strings.confirm}</Button>
                    </>
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default RemoveUnverifiedBankAccountModal;
