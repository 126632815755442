import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Divider, Grid, Icon, Image, Table, Button, Header} from "semantic-ui-react";
import NewClaimSteps from "./newClaimSteps";
import "./newClaims.css"
import {removeFile} from "../../../actions/newClaimActions";
import {connect} from "react-redux";
import {cropText, getNumberOfUploadsRequired} from "../../../utils/common";
import Footer from "../../footer";
import {Document, Page, pdfjs} from "react-pdf";
import {MAX_FILES_AMOUNT} from "../../../utils/constants";
import {isValidClaimFileType} from "../../../utils/file";
import ClaimStepsLayout from "../claimStepsLayout";
import {ClickableText} from "../../custom-common";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        claimant: state.newClaim.claimant,
        files: state.newClaim.files,
        treatmentInfo: state.newClaim.treatmentInfo,
        userIsCertificateHolder: state.initialData.userIsCertificateHolder
    }
};

class ConfirmUploads extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/claims/new/uploads");
    }

    onNext = () => {
        if (!!this.props.userIsCertificateHolder) {
            this.props.history.push("/claims/new/payment")
        } else {
            this.props.history.push("/claims/new/review")
        }
    }

    removeFile = (idx) => {
        this.props.dispatch(removeFile(idx))
    }

    render() {
        const {files} = this.props;
        const button = <Button primary content="Next step" onClick={this.onNext}/>
        const numberOfUploadsRequired = getNumberOfUploadsRequired(this.props.treatmentInfo)

        return (
            <React.Fragment>
                <NewClaimMenu onBack={this.onBackHandler}/>
                <ClaimStepsLayout>
                    <NewClaimSteps activeStepIdx={1}/>
                    <div style={{marginTop: "56px"}}>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={8} className={"pageContent"}>
                                <Header as={"h2"}>Confirm uploads</Header>
                                <Divider hidden/>

                                {(!!files && files.length > 0) && <Table basic={"very"} unstackable className="confirmUploads">
                                    <Table.Body>
                                        <Table.Row>
                                            <td><b>Preview</b></td>
                                            <td><b>Name</b></td>
                                            <Table.Cell textAlign={"center"}><b>Status</b></Table.Cell>
                                            <td><b></b></td>
                                        </Table.Row>
                                        {files.map((file, idx) => {
                                            const isAcceptedFile = isValidClaimFileType(file);
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell>
                                                        {isAcceptedFile ?
                                                            file.type.startsWith("image/") ?
                                                                <div className="imageFile">
                                                                    <Image centered bordered src={file.url}/>
                                                                </div> :
                                                                (file.type === "application/pdf" ?
                                                                    <div className="pdfFile">
                                                                        <Document file={file.url}><Page pageNumber={1} width={73}/></Document>
                                                                    </div> :
                                                                    <div className="otherFile">
                                                                        <Icon name="file outline"/>
                                                                    </div>)
                                                            :
                                                            <div className="notAcceptedFile">
                                                                <Icon name="warning sign"/>
                                                            </div>
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {cropText(file.name, 45)}
                                                    </Table.Cell>
                                                    <Table.Cell textAlign={"center"} verticalAlign={"middle"}><Icon name="check"/></Table.Cell>
                                                    <Table.Cell textAlign={"right"} verticalAlign={"middle"}>
                                                        <Icon color={"grey"} link onClick={() => {
                                                            this.removeFile(idx)
                                                        }} name={"trash alternate outline"}/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>}
                                {files.length < MAX_FILES_AMOUNT ? (
                                    <ClickableText onClick={this.onBackHandler}>+ Upload more</ClickableText>
                                ) : (
                                    <p className="neutral700" style={{fontStyle: 'italic', marginTop: '1em'}}>
                                        You've reached the maximum number of uploads ({MAX_FILES_AMOUNT}).
                                    </p>
                                )}
                            </Grid.Column>
                        </Grid>
                    </div>
                    <Grid container stackable columns={1} centered style={{alignItems: 'flex-end', flex: 1}}>
                        {(files.length < numberOfUploadsRequired) && <Grid.Column width={8} className={"pageContent"}>
                            <p><b>Note:</b> We would ideally like {numberOfUploadsRequired} documents submitted, submitting a claim with less
                                than that can lead to longer claim review timelines.</p>
                            <Divider hidden/>
                        </Grid.Column>}
                    </Grid>
                    <div style={{marginTop: "100px"}}>
                        <Footer showTopFooter={true}
                                showCenterData={true}
                                button={button}
                        />
                    </div>
                </ClaimStepsLayout>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ConfirmUploads));
