import React, {Component} from "react"
import {Divider, Form, Grid, Icon, Input, Pagination, Responsive, Table, Header} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {SupportEmailLink} from "../base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {debounce} from "throttle-debounce";
import axios from "axios";
import {firstCharUpper} from "../../utils/common";
import ConditionsWithKeyword from "./conditionsWithKeyword";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";

const mapStateToProps = (state, ownProps) => {
    const {certificate: {conditionListVersionId, assetCoverages}} = state.initialData;
    const hasChronic = !!assetCoverages.chronic;
    const hasMental = !!assetCoverages.mental;

    return {
        ...ownProps,
        hasChronic,
        hasMental,
        conditionListVersionId
    }
};

class Conditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            resultsPerPage: 20,
            page: 1,
            totalResults: 0,
            loading: false,
            error: null,
            data: [],
            afterSearch: false
        };

        this.debounceSearch = debounce(500, this.search);
    }

    handleSearchTermChange = (event, {value}) => {
        if (value === '') {
            return this.setState({searchTerm: value, loading: false, data: [], afterSearch: false, totalResults: 0});
        }
        this.setState({searchTerm: value});
        this.debounceSearch();
    };

    search = () => {
        this.setState({page: 1}, () => this.fetchRecords());
    };

    fetchRecords = async () => {
        this.setState({error: null, loading: true});
            const {searchConditionGroup, searchTerm, page, resultsPerPage} = this.state;
            const {conditionListVersionId} = this.props;
            const payload = {
                conditionGroupIdList: searchConditionGroup,
                searchTerm,
                page,
                resultsPerPage,
                conditionListVersionSfId: conditionListVersionId
            };
        try {
            const result = await axios.post("/api/member/v2/searchCondition", payload, {headers: {'Content-Type': 'application/json'}});
            if (result.status !== 200) {
                throw Error(result.statusText);
            }
            if (this.state.searchTerm === '' || this.state.searchTerm !== searchTerm) return;
            const {totalResults, data} = result.data;
            this.setState({
                loading: false,
                data,
                totalResults,
                afterSearch: true
            })
        } catch (e) {
            console.warn(e);
            this.setState({loading: false, error: 'It was not possible to load condition information'})
        }
    };

    handlePaginationChange = (e, {activePage}) => {
        this.setState({page: activePage, loading: true}, () => this.fetchRecords());
    };

    renderMatchInText = (result, match) => {
        if (!result || result.length === 0) {
            return "";
        }

        const regex = new RegExp(match, "i");
        const parts = result.split(regex);
        let parsed = [];
        parts.forEach((p, idx)=>{
            parsed.push(p);
            if(idx+1 < parts.length){
                parsed.push(<b key={idx}>{result.substr(result.toLowerCase().indexOf(match.toLowerCase()), match.length)}</b>);
            }
        })
        return parsed;
    }

    addNotCovered = (text) => {
        const {hasChronic, hasMental} = this.props;
        if(text === "Chronic" && !hasChronic){
            return "Chronic, not covered"
        } else if(text === "Mental" && !hasMental) {
            return "Mental, not covered"
        } else if (text === "Maternity") {
            return "Maternity, not covered"
        } else {
            return firstCharUpper(text);
        }
    }

    render() {
        const {data, page, resultsPerPage, totalResults, afterSearch, searchTerm, loading} = this.state;
        const totalPages = data.length > 0 ? Math.ceil(totalResults / resultsPerPage) : 0;

        return (
            <React.Fragment>
                <Grid stackable textAlign={'center'}>
                    <Grid.Row columns={1}>
                        <Grid.Column width={12} textAlign={'left'}>
                            <Form>
                                <Form.Field
                                    id='searchTerms'
                                    name='searchTerms'
                                    control={Input}
                                    placeholder={'Search'}
                                    icon='search'
                                    iconPosition='left'
                                    onChange={this.handleSearchTermChange}
                                    value={this.state.searchTerm}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>

                    {!!searchTerm && <Grid.Row columns={1}>
                        <Grid.Column textAlign={"left"}>
                            {afterSearch && <Header as={'h4'}><b>Searching for '{this.state.searchTerm}'</b></Header>}
                        </Grid.Column>
                    </Grid.Row>}

                    {!!loading && <Grid.Column textAlign={"center"}>
                        <Divider hidden/>
                        <CustomContainer padded={"very"} loading={loading} vertical basic/>
                    </Grid.Column>}

                    {!searchTerm ? (
                        <Grid.Row columns={1}>
                            <ConditionsWithKeyword/>
                        </Grid.Row>
                    ) : (
                        !loading && <Grid.Row columns={1}>
                            <Grid.Column>
                                {data.length > 0 ? (
                                    <Table basic={'very'}>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell width={7}>
                                                    <b style={{height: 'auto', color: '#616161'}}>Condition</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <b style={{height: 'auto', color: '#616161'}}>Category</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <b style={{height: 'auto', color: '#616161'}}>Keyword</b>
                                                </Table.Cell>
                                            </Table.Row>
                                            {
                                                data.map( (d, i) => {
                                                        return <Table.Row data-qa-conditionListRow key={i}>
                                                            <Table.Cell>{this.renderMatchInText(d.conditionName, searchTerm)}</Table.Cell>
                                                            <Table.Cell>{this.renderMatchInText(this.addNotCovered(d.conditionGroupName), searchTerm)}</Table.Cell>
                                                            <Table.Cell>{this.renderMatchInText(d.keywordName, searchTerm)}</Table.Cell>
                                                        </Table.Row>
                                                    }
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    afterSearch && <div style={{textAlign: 'center', height: 'auto'}}>
                                        <Icon name={"search"} size={"big"} color={"grey"}/>
                                        <Header as={"h4"}>Can’t find what you need?</Header>
                                        <p className={"small"}>Call at {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}<br/> or <SupportEmailLink textOverride={"email our team"}/></p>
                                        <Divider hidden/>
                                        <Divider hidden/>
                                        <Divider hidden/>
                                    </div>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>

                {(!loading && data.length > 0) &&
                    <CustomContainer basic textAlign="center">
                        <Responsive {...Responsive.onlyMobile}>
                            <Pagination activePage={page} onPageChange={this.handlePaginationChange}
                                        boundaryRange={0}
                                        totalPages={totalPages}/>
                        </Responsive>
                        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                            <Pagination activePage={page} onPageChange={this.handlePaginationChange}
                                        totalPages={totalPages}/>
                        </Responsive>
                    </CustomContainer>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Conditions));
