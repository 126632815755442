import React, {Component} from "react"
import {connect} from "react-redux";
import axios from "axios";
import {setInitialData} from "../actions/initialDataActions";
import {Container, Loader, Message} from "semantic-ui-react";
import {
    hasOpenEnrollmentEndReachedLimitDays,
    resolveErrorMessage,
    ENROLLMENT_ENDED,
    PAYMENT_REQUIRED,
    SUCCESSFUL_PAYMENT,
    ELIGIBILITY_LOSS,
    GENERIC_CONSENT_ROUTE,
    STATE_SPECIFIC_CONSENT_ROUTE,
    PLAN_ROUTE,
} from "../utils/common";
import {withRouter} from "react-router-dom";
import {setClaimList, setNIGOClaims} from "../actions/claimsActions";
import {setAOEInitialDependents, setAOEStatus} from "../actions/anualOpenEnrollmentActions";
import {setLanguage} from "../actions/userActions";
import {languages} from "../utils/localization";
import {setLastRoute} from "../actions/navigationActions";
import moment from "moment";
import {setBillingData} from "../actions/billingActions";
import {setIsStopscreenDisplayed} from "../actions/stopScreensActions";
import {setEConsentSigned} from "../actions/eConsentActions";
import Settings from "./settings/settings";
import MemberContainer from "./memberContainer";

const mapStateToProps = (state, ownProps) => {
    const {auth} = state;
    return {
        ...ownProps,
        auth: auth,
        contactSfId: !!auth.loginInfo ? auth.loginInfo.contactSfId : null,
    }
};

class PrepareApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            prepareError: '',
            redirectToSettings: false,
        }
    }

    componentDidMount() {
        const { history } = this.props;
        if (history?.location?.state?.backToSettings) {
            this.setState({
                redirectToSettings: true,
                loading: false
            });
        }else {
            this.init().then();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.dispatch(setLastRoute(prevProps.location.pathname))
    }

    init = async () => {
        const {auth} = this.props;
        const {loginInfo} = auth;

        let missingLoginToken = !loginInfo || !loginInfo.authToken;

        if (missingLoginToken) {
            this.props.history.replace("/login");
        } else if(!auth.mfaEnabled && auth.mfaNotEnabledGracePeriodEnded){
            this.props.history.replace("/mfa");
        } else {
            try {
                this.setState({loading: true})
                const result = await axios.post("/api/member/v2/prepareApp", null, {headers: {'Content-Type': 'application/json'}});
                const data = result.data;
                let user = null;

                if (data.redirectAuthenticated) {
                    window.location = data.redirectAuthenticated;
                    return;
                }

                const userIsCertificateHolder = this.props.contactSfId === data.certificateHolder.id;
                if (userIsCertificateHolder) {
                    user = data.certificateHolder
                } else if (!!data.dependents && data.dependents.length > 0) {
                    const dependent = data.dependents.filter(dependent => dependent.id === this.props.contactSfId)
                    user = dependent[0]
                }

                const payload = {
                    dependents: data.dependents,
                    user: user,
                    employer: data.employer,
                    certificateHolder: data.certificateHolder,
                    disclaimers: data.disclaimers,
                    limitations: data.limitations,
                    doctorsOfficeOptions: data.doctorsOfficeOptions,
                    urgentCareOptions: data.urgentCareOptions,
                    facilityOptions: data.facilityOptions,
                    documents: data.documents,
                    cost: data.cost,
                    employerContribution: data.employerContribution,
                    payrollFrequency: data.payrollFrequency,
                    certificate: data.certificate,
                    userIsCertificateHolder: userIsCertificateHolder,
                    qleReasonOptions: data.qleReasonOptions,
                    contract: data.contract,
                    increments: data.increments,
                    eligibility: data.certificate.ratedClass.contractTerms.eligibility,
                    quoteInfo: data.quoteInfo,
                    employerContributions: data.employerContributions,
                    presets: data.certificate.ratedClass.contractTerms.presets,
                    coverageHistory: data.coverageHistory,
                    futurePendingAsset: data.futurePendingAsset,
                    frauds: data.frauds,
                    openEnrollment: data.openEnrollment,
                    hasRestrictedAccess: data.hasRestrictedAccess,
                    isDirectBillingEnabled: data.isDirectBillingEnabled,
                    stopScreenInfo: data.stopScreenInfo,
                    isNotEligible: data.isNotEligible,
                    riderLabels: data.riderLabels,
                    benefitLimits: data.benefitLimits,
                }
                const notInGoodOrderClaims = (!!result.data.claims && result.data.claims.length > 0) ? result.data.claims.filter(claim => claim.status === "Not in Good Order") : [];

                this.props.dispatch(setInitialData(payload));
                this.props.dispatch(setClaimList(result.data.claims));
                this.props.dispatch(setNIGOClaims(notInGoodOrderClaims));
                this.props.dispatch(setLanguage(languages.ENGLISH));
                this.props.dispatch(setAOEInitialDependents(data.dependents));
                this.props.dispatch(setAOEStatus(data.annualOpenEnrollmentStatus));
                this.props.dispatch(setIsStopscreenDisplayed(false));
                const { billingInfo } = data;
                if (billingInfo) {
                    this.props.dispatch(setBillingData(billingInfo));
                }

                const {isDirectBillingEnabled, stopScreenInfo} = payload;

                if (stopScreenInfo?.isEligibilityLoss) {
                    this.props.history.push(ELIGIBILITY_LOSS);
                    return;
                }

                const paymentStatus = stopScreenInfo?.enrollmentPaymentStatus;
                const isPaymentStatusNeeded = paymentStatus === 'Needed';

                const bankAccountPaymentStatus = stopScreenInfo?.bankAccountPaymentStatus;
                const isBankAccountPaymentStatusPending = bankAccountPaymentStatus === "pending";

                const effectiveDate = moment(payload.certificate.effectiveDate);
                const isEffectiveDateBeforeNow = effectiveDate.isBefore(moment());
                const isEffectiveDateBeforeNowOrToday = effectiveDate.isSameOrBefore(moment(), 'day');

                const end = moment(data.certificate.enrollmentEndDate);
                const isEndDateBeforeNow =  end.isBefore(moment());

                if(isDirectBillingEnabled) {
                    if (isPaymentStatusNeeded) {
                        if (hasOpenEnrollmentEndReachedLimitDays(end) && isEffectiveDateBeforeNow) {
                            this.props.history.push(ENROLLMENT_ENDED);
                        } else {
                            const path = isBankAccountPaymentStatusPending ? SUCCESSFUL_PAYMENT : PAYMENT_REQUIRED;
                            this.props.history.push(path);
                        }
                        return;
                    } else if (!isEndDateBeforeNow && !isEffectiveDateBeforeNowOrToday ) {
                        this.props.history.push(SUCCESSFUL_PAYMENT);
                        return;
                    }
                }
                if (!data.hasRestrictedAccess) {
                    await this.handleEConsent()
                }
                this.setState({loading: false, prepareError: ''})
            } catch (e) {
                console.warn(e)
                this.setState({loading: false, prepareError: resolveErrorMessage(e, "An unexpected error ocurred")})
            }
        }
    }

    handleEConsent = async () => {
        const {dispatch, location, history} = this.props;
        const eConsentResponse = await axios.post("/api/member/v2/getEConsent");
        const {signed, step} = eConsentResponse.data;
        dispatch(setEConsentSigned(signed))
        let targetRoute = PLAN_ROUTE;
        if (!signed && step === "genericConsent") {
            targetRoute = GENERIC_CONSENT_ROUTE;
        } else if (!signed && step === "docusignConsent") {
            targetRoute = STATE_SPECIFIC_CONSENT_ROUTE;
        }
        if (location.pathname !== targetRoute) {
            history.push(targetRoute);
        }
    };

    render() {

        const { loading, prepareError, redirectToSettings } = this.state;

        let content = <React.Fragment>{this.props.children}</React.Fragment>
        if (loading) {
            content = <Loader active={true}/>
        } else if (prepareError.length > 0) {
            content =
                <Container style={{marginTop: '2em'}}><Message negative>{prepareError}</Message></Container>
        } else if(redirectToSettings){
            return <MemberContainer><Settings /></MemberContainer>;
        }
        return content;
    }
}

export default connect(mapStateToProps)(withRouter(PrepareApp));
