import React, {Component} from "react"
import {Grid, Button, Header} from "semantic-ui-react";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getLocalization, interpolateString, parseMultilineString} from "../../utils/localization";
import Footer from "../footer";
import {setPrevRoute} from "../../actions/navigationActions";
import {S3Image} from "../custom-common";
import IMAGE from "../../constants/images";

const mapStateToProps = (state, ownProps) => {
    const {user, initialData, anualOpenEnrollment} = state;
    return {
        user,
        name: anualOpenEnrollment.firstName,
        initialData: initialData,
        lang: user.lang,
        ...ownProps
    }
};

class WouldYouMind extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    render() {
        const localization = getLocalization(this.props.lang);
        const strings = localization.wouldYouMind;
        const name = this.props.name;
        const button = (<Button primary content='Next step' as={NavLink}
                                to={this.props.nextRoute}/>)
        return (
            <React.Fragment>
                <div className="member-main-container" style={{background: 'white'}}>
                    <Grid container stackable columns={1} textAlign={'center'} style={{margin: '5em'}}>
                        <Grid.Row>
                            <Grid.Column>
                                <S3Image {...IMAGE.LEVERS}/>
                                <Header as='h2'>{interpolateString(strings.title, [name])}</Header>
                                <p>{parseMultilineString(strings.subTitle)}</p>
                                <br/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Footer showTopFooter showCenterData button={button}/>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(WouldYouMind));
