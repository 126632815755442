import React from 'react';
import {Modal, Button} from "semantic-ui-react";
import moment from "moment";
import {PaymentDetailsContext} from "./paymentDetails/PaymentDetailsContainer";
import {CustomModalHeader} from './custom-common';

const ConfirmPaymentModal = ({displayModal, onClose, paymentAmount, strings}) => {
    const {
        isBankAccountPmSelected, isCreditCardPmSelected, bankAccountPmData, creditCardPmData,
        makePayment, setMakePaymentError, makePaymentLoading
    } = PaymentDetailsContext.useContainer();
    const [paymentDate] = React.useState(moment().format("MMM DD, YYYY"));
    const [paymentMethod, setPaymentMethod] = React.useState(null);

    React.useEffect(() => {
        if (isBankAccountPmSelected()) {
            setPaymentMethod(`${bankAccountPmData.bank_name} (*******${bankAccountPmData.last4})`)
        } else if (isCreditCardPmSelected()) {
            setPaymentMethod(`Credit card ending with ${creditCardPmData.card.last4}`);
        }
    }, [bankAccountPmData, creditCardPmData, isBankAccountPmSelected, isCreditCardPmSelected]);

    const closeModal = () => {
        onClose();
        setMakePaymentError(null);
    }

    const submitPayment = () => {
        makePayment(paymentAmount);
        closeModal();
    }

    return (
        <Modal size={'small'} onClose={closeModal} open={displayModal} style={{maxWidth: '562px'}}>
            <CustomModalHeader title={strings.confirmPaymentModal.confirmYourPayment} onClose={onClose} className="customModalHeader" />
            <Modal.Content style={{overflowY: 'auto'}} className={"small"}>
                <p style={{fontSize: '16px'}}>
                    {strings.confirmPaymentModal.pleaseConfirmTheDetails}
                </p>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '15px'}}>
                    <b>{strings.confirmPaymentModal.paymentMethod}</b>
                    <span>{paymentMethod}</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '15px'}}>
                    <b>{strings.confirmPaymentModal.date}</b>
                    <span>{paymentDate}</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <b>{strings.confirmPaymentModal.amount}</b>
                    <h2 className={'vibranBlueHeader'} style={{margin: 0}}>
                        ${paymentAmount}
                    </h2>
                </div>
                <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button basic primary onClick={closeModal}>{strings.confirmPaymentModal.cancel}</Button>
                    <Button primary
                            loading={makePaymentLoading}
                            onClick={submitPayment}>
                        {strings.confirmPaymentModal.submitPayment}
                    </Button>
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default ConfirmPaymentModal;

