import React, {Component} from "react"
import {Divider, Grid, Button, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "./footer";
import FullScreenPageMenu from "./fullScreenPageMenu";
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import {S3Image} from "./custom-common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        verifyName: state.auth.verifyName,

    }
};

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onNext = () => {
            this.props.history.push(this.props.nextRoute)
    }

    render() {
        const {loading} = this.state;

        const {verifyName} = this.props;

        const button = <Button primary content="Next step"
                               onClick={this.onNext}/>;
        return (
            <div className="member-main-container" style={{background: 'white'}}>
                <FullScreenPageMenu center={"Create account"}/>

                <Divider hidden/><Divider hidden/><Divider hidden/><Divider hidden/>
                <Grid container stackable columns={1} centered>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column textAlign='center'>
                            <S3Image centered name={"welcome"} common style={{marginTop: 60}} width={280}/>
                            <Divider hidden/>
                            <Header as={"h1"} textAlign='center'>
                                Hi {verifyName},<br/> welcome to {STRATEGIC_PARTNER.LABEL}!
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} style={{padding: 0}} textAlign={"center"}>
                            <Header as='h4' className={"neutral900Text"} textAlign='center' style={{fontSize: '1.11rem'}}>
                                Let’s explore your shiny, new account.
                            </Header>
                            <p>Use your {STRATEGIC_PARTNER.LABEL} account to look up plan information, file claims, and check the status of your benefits.</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Footer
                    showTopFooter={true} showCenterData={true}
                    loading={loading}
                    button={button}
                />
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Welcome));
