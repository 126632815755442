import {combineReducers} from 'redux'
import {authActionTypes} from "../actions/actionTypes";
import {newClaimReducer} from "./newClaimReducer";
import {authReducer} from "./authReducer";
import {navigationReducer} from "./navigationReducer";
import {initialDataReducer} from "./initialDataReducer";
import {claimsReducer} from "./claimsReducer";
import {planChangeReducer} from "./planChangeReducer";
import {newMessagesReducer} from "./newMessagesReducer";
import {getHelpReducer} from "./getHelpReducer";
import {toastReducer} from "./toastReducer";
import {anualOpenEnrollmentReducer} from "./anualOpenEnrollmentReducer";
import {userReducer} from "./userReducer";
import {billingReducer} from "./billingReducer";
import {stopScreensReducer} from "./stopScreensReducer";
import {mobileReducer} from "./mobileReducer";
import {eConsentReducer} from "./eConsentReducer";

const appReducer = combineReducers({
    newClaim: newClaimReducer,
    auth: authReducer,
    navigation: navigationReducer,
    initialData: initialDataReducer,
    claims: claimsReducer,
    planChange: planChangeReducer,
    newMessages: newMessagesReducer,
    getHelp: getHelpReducer,
    toast: toastReducer,
    anualOpenEnrollment: anualOpenEnrollmentReducer,
    user: userReducer,
    billing: billingReducer,
    stopScreens: stopScreensReducer,
    mobile: mobileReducer,
    eConsent: eConsentReducer,
});


const rootReducer = (state, action) => {
    // Clear all data in redux store to initial on logout.
    if (action.type === authActionTypes.SET_LOGOUT) state = { mobile: state.mobile };

    return appReducer(state, action);
 };

export default rootReducer;
