import React from 'react';
import {Header, Container, Button} from 'semantic-ui-react';
import {CustomContainer, CustomGrid, S3Image} from '../custom-common';
import Footer from "../footer";
import {NavLink} from "react-router-dom";
import {DOCUSIGN_CONSENT_ROUTE} from "../../utils/common"
import IMAGE from "../../constants/images"
import withPreventBackNavigation from '../withPreventBackNavigation';

const EconsentInterstitial = () => {
    const button = (<Button primary content="Continue" as={NavLink} to={DOCUSIGN_CONSENT_ROUTE}/>)
    return (
        <CustomGrid centered>
            <CustomGrid.Row className="econsentInterstitialRow">
                <CustomGrid.Column className="econsentInterstitialColumn">
                    <CustomContainer className="econsentInterstitialContainer">
                        <Container className="econsentInterstitialImageContainer">
                            <S3Image {...IMAGE.ELLIPSE_14} />
                            <Container className="econsentInterstitialImage">
                                <S3Image {...IMAGE.ICON_CLIPBOARD_2X}/>
                            </Container>
                        </Container>
                        <Header color={"green"} className="econsentInterstitialHeader">Your state requires <br /> state specific documents</Header>
                        <p>Your state requires additional documentation before you can proceed. Please click below to continue—</p>
                    </CustomContainer>
                </CustomGrid.Column>
            </CustomGrid.Row>
            <Footer showTopFooter hasToDisplayCarrierDisclaimer button={button} />
        </CustomGrid>
    );
};

export default withPreventBackNavigation(EconsentInterstitial);
