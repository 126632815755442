import React, {Component} from "react";
import {CustomContainer} from '../custom-common';
import {Dimmer, Grid, Loader, Header} from "semantic-ui-react";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {formatMoney, strFormatMoney} from "../../utils/utils";
import moment from "moment";
import {setAccountSummaryCardLoading} from "../../actions/billingActions";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        currentInvoices: state.billing.currentInvoices,
        historicalInvoices: state.billing.historicalInvoices,
        accountBalance: state.billing.accountBalance,
        currentBilling: state.billing.currentBilling,
        pastDue: state.billing.pastDue,
        today: state.billing.today,
        chargeAttempts: state.billing.chargeAttempts,
        lastPaymentDate: state.billing.lastPaymentDate,
        loading: state.billing.accountSummaryCardLoading,
        autoPay: state.billing.autoPay
    }
};

class AccountSumaryCard extends Component {

    componentDidMount() {
        this.props.dispatch(setAccountSummaryCardLoading(false))
    }

    render() {
        const {currentInvoices, pastDue, accountBalance, historicalInvoices, loading, autoPay} = this.props;
        const notAvailable = (currentInvoices?.length === 0 && accountBalance === "0.00" && pastDue === "0.00" && (!historicalInvoices || (!!historicalInvoices && historicalInvoices.length === 0)));
        const bkgColor = notAvailable ? "#FFF7EC" : "#E3EEED";
        return (
        <React.Fragment>
            <Dimmer.Dimmable dimmed={loading}>
            <CustomContainer padded style={{minHeight: '384px', display: 'flex', flexDirection: 'column'}}>
                <Grid columns={2} verticalAlign={"middle"}>
                    <Grid.Column width={7}><Header as="h4">Account summary</Header></Grid.Column>
                    {autoPay && <Grid.Column width={5} textAlign={"right"}><span className={"vibranBlueHeader"}>AutoPay is on</span></Grid.Column>}
                </Grid>
                <CustomContainer style={{backgroundColor: bkgColor, flex: 1, display: 'flex', alignItems: 'center'}}>
                    {notAvailable ?
                        this.renderNotYetAvailable() :
                            (accountBalance === '0.00') ?
                                this.renderUpToDate() :
                                this.renderBillingData()
                    }
                </CustomContainer>
            </CustomContainer>
            <Dimmer active={loading} inverted> <Loader /> </Dimmer>
            </Dimmer.Dimmable>
         </React.Fragment>
        )

    }

    renderNotYetAvailable() {
        return <React.Fragment>
            <Grid textAlign={"center"} style={{paddingLeft: 25, paddingRight: 25}}>
                <Grid.Row>
                    <Image centered
                           src="https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/app-icon_hourglass-fog.png"
                           style={{width: '80px'}}/>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <p style={{textAlign: "center"}}><span className={"small"}><b>A preview of your first invoice will be available towards the end of the month.</b></span>
                    </p>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <p style={{textAlign: "center"}}><span className={"small"}><b>You’ll receive an email when it’s ready to view!</b></span>
                    </p>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    }

    renderUpToDate() {
        const {lastPaymentDate} = this.props;
        let mLastPaymentDate = "-";
        if (lastPaymentDate) {
            mLastPaymentDate = moment(lastPaymentDate).format('MMM D, YYYY');
        }
        return <React.Fragment>
            <Grid textAlign={"center"}>
                <Grid.Row>
                    <Image centered src="https://brella-platform-assets.s3.amazonaws.com/employer/success.svg"
                           style={{width: '80px'}}/>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <span className={"small neutral700Text"}><b>Your account is all paid up!</b></span><br/>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <span className={"small neutral600Text"}><i>Last payment date {mLastPaymentDate}</i></span>
                </Grid.Row>
            </Grid>
        </React.Fragment>

    }

    renderBillingData() {
        const {currentInvoices, accountBalance, pastDue, currentBilling, chargeAttempts} = this.props;
        return (
            <React.Fragment>
                <Grid columns={1}>
                    {currentBilling !== "0.00" &&
                    <Grid.Column textAlign={"left"}>
                        <Grid columns={2}>
                            <Grid.Column textAlign={"left"} width={8}>
                                <p style={{marginBottom: 0}}><span className={"smaller neutral700Text"}><b>Current invoice</b></span>
                                </p>
                                <span className={"smaller neutral700Text"}>
                                    {
                                        // FIXME: if we remove the validation crash due to currentInvoices came up empty for "automationqabrella+db_testing_qa91630502@gmail.com" user
                                        currentInvoices && currentInvoices.length > 0 &&
                                        <i>Coverage period: {moment(currentInvoices[0].billingPeriod.startDate).format("MMM D, YYYY")}-{moment(currentInvoices[0].billingPeriod.endDate).format("MMM D, YYYY")}</i>
                                    }
                                </span>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"} width={4}>
                                <Header as="h4">{strFormatMoney(currentBilling)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    }
                    {!!pastDue && pastDue !== "0.00" &&
                    <Grid.Column textAlign={"left"} style={{paddingTop: 0, paddingBottom: 0}}>
                        <Grid columns={2}>
                            <Grid.Column textAlign={"left"} width={7} style={{paddingRight: 0}}>
                                <p><span
                                    className={"smaller neutral700Text"}><b>Past due from previous billing period</b></span>
                                </p>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"} width={5}>
                                <Header as="h4"><Icon color={"red"} circular inverted name='exclamation'
                                                      style={{
                                                          fontSize: 8,
                                                          marginBottom: 2,
                                                          marginRight: 0
                                                      }}/> {strFormatMoney(pastDue)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    }
                    <Grid.Column textAlign={"right"}>
                        <Grid columns={2}>
                            <Grid.Column textAlign={"left"}>
                                <p><span
                                    className={"small neutral700Text"}><b>Total amount due now</b></span>
                                </p>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"}>
                                <Header as="h3">{strFormatMoney(accountBalance)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    {(!!chargeAttempts && chargeAttempts.length > 0) &&
                    <Grid.Column>
                        <Icon name={"info circle"} style={{float: 'left'}}/>
                        <div style={{float: "left"}}><span className={"smaller"}><i>Total amount may not reflect pending payments:</i><br/>Issued on {moment(chargeAttempts[0].date).format('MMM D, YYYY')}. <b>Amount: {formatMoney(chargeAttempts[0].amount)}</b></span>
                        </div>
                    </Grid.Column>
                    }
                </Grid>
            </React.Fragment>)
    }
}

export default connect(mapStateToProps)(withRouter(AccountSumaryCard));
