import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Divider, Grid, Icon, List, Button, Header} from "semantic-ui-react";
import NewClaimSteps from "./newClaimSteps";
import "./newClaims.css"
import FileUpload from "../../fileUploads";
import {appendFiles} from "../../../actions/newClaimActions";
import {connect} from "react-redux";
import Footer from "../../footer";
import {getNumberOfUploadsRequired} from "../../../utils/common";
import {MAX_FILES_AMOUNT, MAX_FILE_SIZE_MB} from "../../../utils/constants";
import {isValidClaimFileType, isValidClaimFileSize} from "../../../utils/file";
import ClaimStepsLayout from "../claimStepsLayout";

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    claimant: state.newClaim.claimant,
    files: state.newClaim.files,
    treatmentInfo: state.newClaim.treatmentInfo
});

class Uploads extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }
        this.state = {
            analyzing: false,
            invalidFiles: []
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/claims/new/uploadInstructions");
    }

    onNext = () => {
        this.props.history.push("/claims/new/confirmUploads")
    }

    onFilesLoad = async (newFiles) => {
        const {files} = this.props;
        let invalidFiles = [];
        let validFiles = newFiles.filter((file) => isValidClaimFileType(file) && isValidClaimFileSize(file));
        let invalidFilesByType = newFiles.filter((file) => !isValidClaimFileType(file));
        invalidFilesByType.forEach((file) => {
            invalidFiles.push({name: file.name, message: "Unsupported file type."})
        })

        let invalidFilesBySize = newFiles.filter((file) => !isValidClaimFileSize(file));
        invalidFilesBySize.forEach((file) => {
            invalidFiles.push({name: file.name, message: `The file exceeds ${MAX_FILE_SIZE_MB}MB`})
        })

        const totalFiles = files.length + validFiles.length;

        if (totalFiles > MAX_FILES_AMOUNT) {
            const diff = totalFiles - MAX_FILES_AMOUNT;
            const rest = validFiles.slice(-diff);
            rest.forEach((file) => {
                invalidFiles.push({name: file.name, message: "This file exceeds the maximum file count of " + MAX_FILES_AMOUNT})
            })
            invalidFiles = [...invalidFiles, ...rest];
            validFiles = validFiles.slice(0, validFiles.length - diff);
        }

        validFiles.map(async file => {
            file.url = URL.createObjectURL(file);
            await this.uploadFile(file);
        })

        this.setState({
            invalidFiles: invalidFiles
        })
    }

    uploadFile = async (file) => {
        this.props.dispatch(appendFiles([file]))
    }

    render() {
        const {files} = this.props;
        const {analyzing, invalidFiles} = this.state;
        const button = <Button primary content="Next step" onClick={this.onNext}/>;
        const numberOfUploadsRequired = getNumberOfUploadsRequired(this.props.treatmentInfo)
        return (
            <React.Fragment>
                <NewClaimMenu onBack={this.onBackHandler}/>
                <ClaimStepsLayout>
                    <NewClaimSteps activeStepIdx={1}/>
                    <div style={{marginTop: "56px", maxHeight: 735}}>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={8} className={"pageContent"}>
                                <Header as={"h2"}>Upload your pictures or documents</Header>
                                {files.length >= MAX_FILES_AMOUNT ?
                                    <p className="neutral700" style={{fontStyle: 'italic', marginTop: '1em'}}>
                                        You've reached the maximum number of uploads ({MAX_FILES_AMOUNT}).
                                    </p>
                                    :
                                    <>
                                        <p className={"neutral700Text"}>We need <b>at least ({numberOfUploadsRequired})</b> to continue—</p>
                                        <Divider hidden/>
                                        <FileUpload onFilesLoad={this.onFilesLoad} disabled={analyzing}>
                                                <React.Fragment>
                                                    <p><Icon color={"grey"} name='download'/></p>
                                                    <p className={"small neutral700Text"}>Drag and drop or <strong>browse your computer</strong></p>
                                                    <p className={"small neutral700Text"}>File types accepted are JPGs, PNGs, and PDFs</p>
                                                    <p className={"small neutral700Text"}>Max file size {MAX_FILE_SIZE_MB}MB</p>
                                                </React.Fragment>
                                        </FileUpload>
                                    </>
                                }

                                {(!!invalidFiles && invalidFiles.length > 0) &&
                                    <div className="invalidFilesContainer">
                                        {invalidFiles.map((file, idx) => (
                                            <p key={idx} className="small" style={{margin: 0}}>
                                                <Icon name='exclamation circle'/>{file.name}<span> - {file.message}</span>
                                            </p>
                                        ))}
                                    </div>
                                }

                                {(!!files && files.length > 0) &&
                                    <div style={{
                                        marginTop: 13,
                                        overflow: 'auto',
                                        height: '90px',
                                        marginBottom: '30%',
                                    }}>
                                        <List>
                                            {files.map((file, index) => {
                                                return (<List.Item key={index}>
                                                    <Icon name='file'/>
                                                    {file.name}
                                                </List.Item>)
                                            })}
                                        </List>
                                    </div>
                                }

                            </Grid.Column>
                        </Grid>
                    </div>
                    <Footer showTopFooter={true}
                            showCenterData={true}
                            button={button}
                    />
                </ClaimStepsLayout>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Uploads));
