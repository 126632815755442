import React from "react";
import {Modal, Button} from "semantic-ui-react";
import {CustomModalHeader} from "./custom-common";

export const ConfirmDifferentPaymentMethodModal = ({show, strings, handleSubmit, handleClose}) => {

    return (
        <Modal
            size={"small"}
            onClose={handleClose}
            open={show}
            style={{maxWidth: "570px"}}
        >
      <CustomModalHeader title={strings.header} onClose={handleClose} className="customModalHeader" />
      <Modal.Content style={{ overflowY: "auto" }}>
        {strings.contentDeleting}
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <>
            <Button color="grey" basic onClick={handleClose}>
              {strings.cancel}
            </Button>
            <Button className="button primary" onClick={handleSubmit}>
              {strings.confirm}
            </Button>
          </>
        </div>
      </Modal.Content>
    </Modal>
  );
};
