import React, {Component} from 'react';
import {Divider, Form, Grid, Message, Button, Header} from "semantic-ui-react";
import {CustomContainer} from './custom-common';
import Footer from "../components/footer";
import axios from "axios";
import {resolveErrorMessage} from "../utils/common";
import {setLeftNavigation} from "../actions/navigationActions";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import MainMenu from "./MainMenu";
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import SupportEmailLink from "./base/support-email-link";
import {isValidEmail} from "../utils/utils";
import {ClickableText, CustomEmailInput} from "./custom-common";

const mapDispatchToProps = dispatch => {
    return {
        setLeftNavigation: state => dispatch(setLeftNavigation(state)),
    }
};

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactSubmitted: false,
            email: null,
            emailValidationError: null,
            submitError: null,
            submitting: false,
        }
    }

    componentDidMount() {
        const {setLeftNavigation} = this.props

        setLeftNavigation(true);
    }

    register = async () => {
        this.reset();
        const {email} = this.state;
        if (!isValidEmail(email)) {
            this.setState({
                emailValidationError: "Invalid Email"
            });
        } else {
            await this.doRegister({email});
        }
    }

    doRegister = async ({email, resend = false}) => {
        this.setState({
            submitting: true,
        })
        let payload = {email: email}
        try {
            await axios.post("/api/member/v2/registerMember", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({
                contactSubmitted: true,
                submitError: null,
                submitting: false,
            })
        } catch (e) {
            this.setState({
                submitting: false,
                submitError: resolveErrorMessage(e, `Unable to ${resend ? 're-' : ''}send the link.`)
            })
        }
    };

    resendLink = async () => {
        const {email} = this.state;
        this.setState({
            emailResend: false,
            submitError: null,
        })
        await this.doRegister({email, resend: true});
        this.setState({
            emailResend: true,
        })
    }

    handleChange = (e, {value}) => {
        this.setState({
            email: value,
            submitError: null,
            emailValidationError: null
        })
    }

    reset = () => {
        this.setState({contactSubmitted: false, emailResend: false, submitError: null})
    };

    render() {
        const {title = 'New user registration'} = this.props
        const {submitting, submitError, contactSubmitted, emailValidationError, emailResend} = this.state;
        return (
            <div className="member-main-container">
                <MainMenu/>
                <Divider hidden/><Divider hidden/><Divider hidden/>
                <Grid container stackable columns={1} centered>
                    <Grid.Column className={"pageContent"}>
                        <Divider hidden/>
                        <div className={"horizontalGap1"}/>
                        <Grid centered className={"neutral900Text"}>
                            <Grid.Column>
                                <Header as={"h2"} textAlign={"center"}>{title}</Header>
                                {!!contactSubmitted ?
                                    <React.Fragment>
                                        <CustomContainer style={{width: '300px', margin: '0 auto'}}>
                                            <p className={"small"}>
                                                If we have a member record with that email, you’ve been sent a link. Feel free to close your browser tab or to go back to <NavLink
                                                primary to={"/login"}>your member login page.</NavLink>
                                            </p>
                                        </CustomContainer>
                                        <CustomContainer basic style={{width: '300px', margin: '0 auto'}} loading={submitting}>
                                            <p className={"small"}><b>Haven’t received a link?</b><br/>
                                                Either <ClickableText onClick={this.resendLink}>re-send the link</ClickableText> or <ClickableText onClick={this.reset}>try a
                                                    different email</ClickableText>
                                            </p>
                                            {!!submitError && <Message negative>{submitError}</Message>}
                                            {(emailResend && !submitError) && <Message positive size="mini">Email re-sent successfully</Message>}
                                            <Divider hidden/>
                                            <p className={"smaller"}>Still need help? Reach out to our technical support team&nbsp;
                                                {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}&nbsp;
                                                or <SupportEmailLink/>
                                            </p>
                                        </CustomContainer>
                                    </React.Fragment> :
                                    <CustomContainer style={{width: '300px', margin: '0 auto'}} loading={submitting}>
                                        <p className={"small"}>Please enter your email address below and we’ll send a
                                            link
                                            to create your account—</p>
                                        <Form>
                                            <Form.Group>
                                                <CustomEmailInput
                                                    width={12}
                                                    value={this.state.email || ''}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <Button onClick={this.register} primary style={{width: '90px'}}
                                                    loading={submitting} disabled={!this.state.email || !!emailValidationError}>Submit</Button>
                                            {!!submitError && <Message negative>{submitError}</Message>}
                                        </Form>
                                    </CustomContainer>}
                            </Grid.Column>
                        </Grid>
                        <div className={"horizontalGap1"}/>
                    </Grid.Column>
                </Grid>

                <Footer showTopFooter={false} showCenterData={true}/>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(withRouter(Register));
