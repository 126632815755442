import React, {Component} from "react"
import {Container, Divider, Message, Header} from "semantic-ui-react";
import {CustomContainer} from './custom-common';
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {resolveErrorMessage} from "../utils/common";
import {isMobile} from '../utils/utils';
import IMAGE from "../constants/images";
import {S3Image} from "./custom-common";
import {LOGIN_ROUTE, BETTER_ON_THE_APP_ROUTE} from "../utils/common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
    }
};

class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            token: null,
            message: null,
        };
    }

    componentDidMount() {
        window.onbeforeunload = null;
        this.handleSubmit().then();
    }

    handleError = (message) => {
        this.setState({
            loading: false,
            error: true,
            message: message,
        });
    };

    handleSubmit = async () => {
        try {
            this.setState({loading: true, error: false});
            const token = new URLSearchParams(window.location.search).get("token")
            let result = await axios.post("/api/member/v2/verifyEmail", {token});
            if (result.status === 200) {
                this.setState({loading: false});
                window.onbeforeunload = null;
                // this.props.history.push("/login");
            } else {
                this.handleError("Unexpected error");
            }
        } catch (e) {
            this.handleError(resolveErrorMessage(e, "Unexpected error"));
        }
    };

    render() {
        let {error, loading, message} = this.state;
        return (
            <div className="member-main-container">
                <Container textAlign="center">
                    {loading && <Header as={"h2"} textAlign={"center"}>Verifying email...</Header>}
                    {error && <Message negative>{message}</Message>}

                    {!loading && !error &&
                    <CustomContainer>
                        <S3Image {...IMAGE.CHECK_CIRCLE_OUTLINE_64PX}/>
                        <Header as="h2">Success!</Header>
                        <p>Your email address was updated.</p>
                        <Divider hidden/>
                        <NavLink primary to={isMobile()? BETTER_ON_THE_APP_ROUTE : LOGIN_ROUTE}>Back to your account</NavLink>
                    </CustomContainer>
                    }
                </Container>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(VerifyEmail));
