import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Checkbox, Grid, Table, Button, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import {CONFIRM_CHILD_REMOVAL, WHEN_ROUTE} from "../../../../utils/common";
import moment from "moment";
import {removeDependent, setDependent, setDependentList} from "../../../../actions/planChangeActions";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        qleDate: state.planChange.qleDate,
        qleReason: state.planChange.qleReason,
        planChangeDependents: state.planChange.planChangeDependents,
        stateOptions: state.planChange.states,
        genderOptions: state.planChange.genderOptions,
        childMaxEligibilityAge: state.initialData.contract.childMaxEligibilityAge,
        nameSuffixOptions: state.planChange.nameSuffixOptions,
        dependents: state.initialData.dependents
    }
};

class RemoveChild extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkAll: false
        }
    }

    onBackHandler = () => {
        this.props.history.push(WHEN_ROUTE);
    }

    onNext = () => {
        this.props.history.push(CONFIRM_CHILD_REMOVAL)
    }

    handleAllChecked = (children) => {
        const current = this.state.checkAll
        this.setState({
            checkAll: !current
        })
        if (current === true) {
            this.props.dispatch(setDependentList([]))
        } else {
            this.props.dispatch(setDependentList(children))
        }
    }

    handleCheck = (dependent, index) => {
        const {planChangeDependents} = this.props;
        const isAlready = planChangeDependents.filter(dep=>dep.memberId === dependent.memberId).length > 0;
        if (!!isAlready) {
            this.setState({
                checkAll: false
            })
            this.props.dispatch(removeDependent({idx: index}))
        } else {
            let dependentIdx = !!planChangeDependents ? planChangeDependents.length : 0;
            this.props.dispatch(setDependent(dependent, dependentIdx));
        }
    }

    render() {
        const {dependents, planChangeDependents} = this.props;
        const button = <Button primary content="Next step" onClick={this.onNext}/>;
        const children = dependents.filter(dep => dep.relToEmployee === "Child");
        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as={"h2"}>Loss of child(ren) custody</Header>
                                <p className={"neutral700Text"}>Select which members you are terminating coverage for—</p>
                                <Table basic>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Checkbox checked={!!this.state.checkAll} onChange={()=>{this.handleAllChecked(children)}}/>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b>First name</b>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b>Last name</b>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b>Relationship</b>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <b>Age</b>
                                            </Table.Cell>
                                        </Table.Row>
                                {(!!children && children.length > 0) && children.map(((dependent, i) => {
                                    const mNow = moment(Date.now());
                                    const mDate = moment(dependent.birthDate)
                                    const age = mNow.diff(mDate, "years")
                                    const isChecked = planChangeDependents.filter(dep=>dep.memberId === dependent.memberId).length > 0;
                                    return <Table.Row key={i}>
                                        <Table.Cell><Checkbox checked={isChecked || this.state.checkAll} onChange={()=>{this.handleCheck(dependent, i)}}/></Table.Cell>
                                        <Table.Cell>{dependent.firstName}
                                        </Table.Cell>
                                        <Table.Cell>{dependent.lastName}</Table.Cell>
                                        <Table.Cell>{dependent.relToEmployee}</Table.Cell>
                                        <Table.Cell>{age}</Table.Cell>
                                    </Table.Row>
                                }))}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(RemoveChild));
