import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Icon, Message, Header, Button} from "semantic-ui-react";
import {connect} from "react-redux";
import Footer from "../footer";
import AnualOpenEnrollmentMenu from "./anualOpenEnrollmentMenu";
import {cancelAOEFlow, onBackHandler} from "../../utils/utils";
import moment from "moment";
import CoverageComparisonTable from "./coverageComparisonTable";
import AOESteps from "./aOESteps";
import {setPrevRoute} from "../../actions/navigationActions";
import {AOE_REVIEW_FAMILY_ROUTE, AOE_SELECT_PLAN_ROUTE, aOEStatus, resolveErrorMessage, convertDisplayDateFormatToServer, getArrayByLanguage} from "../../utils/common";
import axios from 'axios';
import {setAOEStatus} from "../../actions/anualOpenEnrollmentActions";
import {interpolateString} from "../../utils/localization";
import CancelLink from "./cancelLink";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        certificate: state.initialData.certificate,
        dependents: state.anualOpenEnrollment.dependents,
        aOEDependents: state.anualOpenEnrollment.aOEDependents,
        anualOpenEnrollment: state.anualOpenEnrollment,
        moderateIncrements: (!!state.anualOpenEnrollment && !!state.anualOpenEnrollment.generalInitialData.increments && state.anualOpenEnrollment.generalInitialData.increments.moderate) || [],
        severeIncrements: (!!state.anualOpenEnrollment && !!state.anualOpenEnrollment.generalInitialData.increments && state.anualOpenEnrollment.generalInitialData.increments.severe) || [],
        catastrophicIncrements: (!!state.anualOpenEnrollment && !!state.anualOpenEnrollment.generalInitialData.increments && state.anualOpenEnrollment.generalInitialData.increments.catastrophic) || [],
        isBrellaChoice: !!state.anualOpenEnrollment.generalInitialData.ratedClass.contractTerms && state.anualOpenEnrollment.generalInitialData.ratedClass.contractTerms.planOffering === "Brella Choice",
        upcomingPolicyEffectiveDate: state.anualOpenEnrollment.generalInitialData.upcomingPolicyEffectiveDate,
        prevRoute: state.navigation.prevRoute,
        user: state.initialData.user,
        keepCoverage: state.anualOpenEnrollment.keepCoverage,
        changedFamilyMembers: state.anualOpenEnrollment.changedFamilyMembers,
        frauds: state.anualOpenEnrollment.generalInitialData.frauds,
        lang: state.user.lang,
        coverageOptions: state.anualOpenEnrollment.generalInitialData.coverageOptions
    }
};

class ConfirmPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitPlanLoading: false,
            submitError: null,
            loadError: null,
            showModal: false,
        }
    }

    componentDidMount() {
        const {keepCoverage, changedFamilyMembers}  = this.props;
        const prevRoute = (keepCoverage && !changedFamilyMembers) ? AOE_REVIEW_FAMILY_ROUTE : AOE_SELECT_PLAN_ROUTE;
        this.props.dispatch(setPrevRoute(prevRoute))
    }

    translateSliderSelectionToAmount = () => {
        const {
            moderateIncrements,
            severeIncrements,
            catastrophicIncrements,
            anualOpenEnrollment: {moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected}
        } = this.props;
        return {
            moderateCoverageSelected: moderateIncrements[moderateCoverageSelected - 1],
            severeCoverageSelected: severeIncrements[severeCoverageSelected - 1],
            catastrophicCoverageSelected: catastrophicIncrements[catastrophicCoverageSelected - 1],
        }
    }

    coverageSelected = () => {
        const {isBrellaChoice, anualOpenEnrollment} = this.props;
        let moderateCoverageSelected = anualOpenEnrollment.moderateCoverageSelected
        let severeCoverageSelected = anualOpenEnrollment.severeCoverageSelected
        let catastrophicCoverageSelected = anualOpenEnrollment.catastrophicCoverageSelected
        if (isBrellaChoice) {
            const translatedCoverage = this.translateSliderSelectionToAmount();
            moderateCoverageSelected = translatedCoverage.moderateCoverageSelected;
            severeCoverageSelected = translatedCoverage.severeCoverageSelected;
            catastrophicCoverageSelected = translatedCoverage.catastrophicCoverageSelected;
        }
        if (!moderateCoverageSelected && !severeCoverageSelected && !catastrophicCoverageSelected) {
            const { catastrophic, moderate, severe } = anualOpenEnrollment.generalInitialData.planComparison.upcomingPlan.benefitAmounts;
            moderateCoverageSelected = moderate;
            severeCoverageSelected = severe;
            catastrophicCoverageSelected = catastrophic;
        }
        return {moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected}
    }

    filterDependentsBasedOnTier = (dependents, familyTier) => dependents.filter(d => {
        switch (familyTier){
            case("employee"):
                return false;
            case("spouse"):
                return d.relToEmployee === "Spouse"  || d.relToEmployee === 'Domestic Partner';
            case("child"):
                return d.relToEmployee === "Child";
            case("family"):
                return true
            default:
                return false;
        }
    });

    sanitizeDependents = (dependents) => {
        dependents = dependents.map(dependent => {
            return {
                ...dependent,
                birthDate: convertDisplayDateFormatToServer(dependent.birthDate),
                socialSecurityNumber : dependent.ssn
            }
        })

        return dependents;
    }

    submit = async () => {
        this.setState({submitPlanLoading: true, submitError: null});
        try {
            const {dependents, aOEDependents, anualOpenEnrollment, certificate} = this.props;
            const {keepCoverage, changedFamilyMembers } = anualOpenEnrollment;
            const familyTier = (keepCoverage && !changedFamilyMembers) ? certificate.familyTier : anualOpenEnrollment.coverageOption;
            const totalDependents = dependents.concat(aOEDependents);

            const coverageSelected = this.coverageSelected()

            let request = {
                familyTier: familyTier,
                moderateBenefit: coverageSelected.moderateCoverageSelected,
                severeBenefit: coverageSelected.severeCoverageSelected,
                catastrophicBenefit: coverageSelected.catastrophicCoverageSelected,
                newAddress: {
                    streetAddress: anualOpenEnrollment.streetAddress,
                    appartment: anualOpenEnrollment.appartment,
                    zipCode: anualOpenEnrollment.zipCode,
                    city: anualOpenEnrollment.city,
                    state: anualOpenEnrollment.selectedState,
                },
                dependents: this.sanitizeDependents(totalDependents),
                firstName: anualOpenEnrollment.firstName,
                middleName: anualOpenEnrollment.middleName,
                lastName: anualOpenEnrollment.lastName,
                suffix: anualOpenEnrollment.suffix,
                gender: anualOpenEnrollment.gender,
                phoneNumber: anualOpenEnrollment.daytimePhoneNumber,
                phoneType: anualOpenEnrollment.phoneType,
                email: anualOpenEnrollment.email,
                ssn: anualOpenEnrollment.ssn,
                birthDate: anualOpenEnrollment.birthDate,
                renewedContractId: anualOpenEnrollment.generalInitialData.contract.renewedToContractId,
                activeContractId: anualOpenEnrollment.generalInitialData.contract.id,
            };

            await axios.post("/api/member/v2/submitUpdatedPlanInfo", request, {headers: {'Content-Type': 'application/json'}});
            this.props.history.push(this.props.nextRoute);

            const {status} = this.props.anualOpenEnrollment;
            if(status === aOEStatus.inProgress){
                this.props.dispatch(setAOEStatus(aOEStatus.completed));
            }
        } catch (e) {
            console.error(e);
            this.setState({submitPlanLoading: false, error: resolveErrorMessage(e, "An unexpected error ocurred")})
        }
    }

    render() {
        const {submitPlanLoading, submitError} = this.state;
        const {user, frauds, lang, coverageOptions} = this.props;
        const {cost, employerContribution, generalInitialData, costPerOption, dependents, aOEDependents, keepCoverage, changedFamilyMembers, coverageOption} = this.props.anualOpenEnrollment;
        const button = <Button primary content="Submit" disabled={submitPlanLoading} loading={submitPlanLoading}
                               onClick={this.submit}/>;
        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;
        const coverageSelected = this.coverageSelected()
        const selectedCostPerOpt = (keepCoverage && !changedFamilyMembers) ? generalInitialData.payrollFrecuencySelected : generalInitialData.costPerOptions.filter(cpo => cpo.id === costPerOption)[0].value;
        const payrollFreq =  selectedCostPerOpt === 'Month' ? generalInitialData.payrollFrequencyMonthly : selectedCostPerOpt;
        const filteredDependentsBasedOnTier = this.filterDependentsBasedOnTier(dependents.concat(aOEDependents), coverageOption);
        const upcomingModifiedCoverage = (keepCoverage && !changedFamilyMembers) ? generalInitialData.planComparison.upcomingPlan : {
            benefitAmounts: {
                moderate: coverageSelected.moderateCoverageSelected,
                severe: coverageSelected.severeCoverageSelected,
                catastrophic: coverageSelected.catastrophicCoverageSelected,
                chronic: generalInitialData.planComparison.upcomingPlan.benefitAmounts.chronic,
                mental: generalInitialData.planComparison.upcomingPlan.benefitAmounts.mental
            },
            costWithErContribution: cost,
            employerContribution,
            payrollFrequency: payrollFreq,
            coveredFamilyMembers: [user.firstName + ' ' + user.lastName].concat(filteredDependentsBasedOnTier.map(dep => dep.firstName + ' ' + dep.lastName)),
        }
        const fraudList = getArrayByLanguage(frauds, lang)
        const selectedCoverageOption = coverageOptions.filter((co) => co.value === coverageOption);
        let disclaimer3FirstConditional = "and those members of my family ";
        if (!!selectedCoverageOption && selectedCoverageOption.length > 0 && selectedCoverageOption[0].value === "employee") {
            disclaimer3FirstConditional = "";
        }
        let disclaimer3SecondConditional = "authorizing the necessary premium deduction to be taken from my salary or wages for the coverage selected";
        const employerContributionCoversTotalAmmount = cost === 0;
        if (employerContributionCoversTotalAmmount) {
            disclaimer3SecondConditional = "accepting this coverage provided by my employer at no cost";
        }
        const disclaimer3 = interpolateString("I hereby elect to enroll myself $1as listed above for the benefits available under my Group’s Limited Benefit Insurance Plan. I understand by signing this form I am $2. I represent to the best of my knowledge and belief all information provided by me on this form is true and correct.", [disclaimer3FirstConditional, disclaimer3SecondConditional]);


            return (
                <React.Fragment>
                    <AnualOpenEnrollmentMenu onBack={() => {
                        onBackHandler(this.props)
                    }}/>
                    <div className="member-main-container" style={{background: 'white'}}>
                        <AOESteps activeStepIdx={2}/>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={9} className={"pageContent"}>
                                <Grid stackable columns={1}>
                                    <Grid.Column className={"confirmPlan"}>

                                        <Header as="h2">Review and confirm your coverage</Header>
                                        <p>Here are your current plan details and the updated plan for next year—</p>

                                        <Divider hidden/>

                                        <Header as="h4"><Icon name={"calendar check outline"}
                                                              style={{fontSize: '1.3em', marginRight: '24px'}}
                                                              className={'circularIcon60 bkgSunshade'}/>Effective
                                            date: {moment(this.props.upcomingPolicyEffectiveDate).format("MMM DD, YYYY")}
                                        </Header>

                                        <Divider hidden/>

                                        <CoverageComparisonTable showCurrent showHeader date={moment(this.props.upcomingPolicyEffectiveDate).format("MMM DD, YYYY")}
                                                                 upcomingModifiedCoverage={upcomingModifiedCoverage}/>

                                        <Divider hidden/>

                                        <p className={"smaller neutral700Text"}>FRAUD WARNING</p>
                                        {fraudList.map((i, idx) => <p key={idx} className={"smaller neutral700Text"}>{i.title}</p>)}
                                        <p className={"smaller neutral700Text"}>This is a limited-benefit Policy that does not constitute comprehensive health insurance coverage and does not satisfy the requirement of Minimum Essential Coverage under the Affordable Care Act.</p>
                                        <p className={"smaller neutral700Text"}>{disclaimer3}</p>

                                        <Divider hidden/>

                                        {!!submitError && <Message negative>{submitError}</Message>}
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        <Footer showTopFooter showCenterData button={button} link={cancel}/>
                    </div>
                </React.Fragment>
            )
    }
}

export default connect(mapStateToProps)(withRouter(ConfirmPlan));
