import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Divider, Form, Grid, Message, Search, Button, Header} from "semantic-ui-react";
import "./newClaims.css"
import axios from "axios";
import {throttle} from "throttle-debounce";
import {connect} from "react-redux";
import {setTreatmentInfo} from "../../../actions/newClaimActions";
import Footer from "../../footer";
import NewClaimSteps from "./newClaimSteps";
import ClaimStepsLayout from "../claimStepsLayout";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        claimant: state.newClaim.claimant,
        treatmentInfo: state.newClaim.treatmentInfo,
        doctorsOfficeOptions: state.initialData.doctorsOfficeOptions,
        urgentCareOptions: state.initialData.urgentCareOptions,
        facilityOptions: state.initialData.facilityOptions
    }
};

class TreatmentInfo extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }

        this.state = {
            facilitesError: false,
            loading: false,
            search: this.props.treatmentInfo.place || '',
            results: [],
            placeError: false,
            lat: null,
            lng: null,
        }
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(
            position => this.setState({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }),
            error => console.log(error)
        );
    }

    onBackHandler = () => {
        this.props.history.replace("/claims/new/whatHappened");
    }

    handleFacilityChange = (selected, selectedFacilityKey) => {
        let {treatmentInfo} = this.props;
        const index = treatmentInfo.facilities.indexOf(selected)
        if (index === -1) {
            treatmentInfo.facilities.push(selected)
        } else {
            treatmentInfo.facilities.splice(index, 1)
        }
        this.props.dispatch(setTreatmentInfo(treatmentInfo));

        const keyIndex = treatmentInfo.facilitiesKeys.indexOf(selectedFacilityKey)
        if (keyIndex === -1) {
            treatmentInfo.facilitiesKeys.push(selectedFacilityKey)
        } else {
            treatmentInfo.facilitiesKeys.splice(keyIndex, 1)
        }
        this.setState({facilitesError: false})
    }

    handleDoctorChange = (e, {name}) => {
        let {treatmentInfo} = this.props;
        const index = treatmentInfo.doctorsOffice.indexOf(name)
        if (index === -1) {
            treatmentInfo.doctorsOffice.push(name)
        } else {
            treatmentInfo.doctorsOffice.splice(index, 1)
        }
        this.props.dispatch(setTreatmentInfo(treatmentInfo));
        this.setState({facilitesError: false})
    }

    handleUrgentCareChange = (e, {name}) => {
        let {treatmentInfo} = this.props;
        const index = treatmentInfo.urgentCare.indexOf(name)
        if (index === -1) {
            treatmentInfo.urgentCare.push(name)
        } else {
            treatmentInfo.urgentCare.splice(index, 1)
        }
        this.props.dispatch(setTreatmentInfo(treatmentInfo));
        this.setState({facilitesError: false})
    }

    handleOnChange = async (e, {value}) => {
        let {treatmentInfo} = this.props;
        this.setState({loading: true, search: value, placeError: false});
        await this.throttledPlacesCall(value);
        this.setState({loading: false});
        treatmentInfo.place = value;
        this.props.dispatch(setTreatmentInfo(treatmentInfo));
    }

    throttledPlacesCall = (value) => {
        throttle(1000, this.handlePlacesCall(value));
    }

    handlePlacesCall = async (value) => {
        const {lat, lng} = this.state;

        if (!value || value.length === 0) {
            let {treatmentInfo} = this.props;
            treatmentInfo.place = null;
            this.props.dispatch(setTreatmentInfo(treatmentInfo));
            return [];
        }
        const payload = {search: value};
        if (!!lat && !!lng) {
            payload.lat = lat;
            payload.lng = lng;
        }
        try {
            const response = await axios.post("/api/member/v2/listPlaces", payload);
            if (response.status === 200) {
                const result = response.data;
                const predictions = result.predictions;

                this.setState({
                    results: predictions.map((r) => {
                        return {
                            key: r.id,
                            title: r.structured_formatting.main_text,
                            description: r.description,
                            id: r.id,
                        }
                    })
                })
            } else {
                throw(new Error("Http status not OK " + response.statusText));
            }
        } catch (e) {
            console.warn(e);
        }

    }

    handleSelectPlace = async (e, {result}) => {
        let {treatmentInfo} = this.props;
        treatmentInfo.place = result.description;
        this.props.dispatch(setTreatmentInfo(treatmentInfo));
        this.setState({search: result.description})
    }

    onBlur = () => {
        let {treatmentInfo} = this.props;
        treatmentInfo.place = this.state.search;
        this.props.dispatch(setTreatmentInfo(treatmentInfo));
    }

    onNext = () => {
        if (this.validateForm() === true) {
            this.props.history.push({pathname: "/claims/new/uploadInstructions"})
        }
    }

    validateForm = () => {
        const {treatmentInfo} = this.props;
        let valid = true;
        if (treatmentInfo.facilities.length === 0) {
            this.setState({facilitesError: true})
            valid = false;
        }

        if (!treatmentInfo.place) {
            this.setState({placeError: true})
            valid = false;
        }

        return valid;
    }

    render() {
        const {treatmentInfo, doctorsOfficeOptions, urgentCareOptions, facilityOptions} = this.props;
        const {facilities, doctorsOffice, urgentCare, facilitiesKeys} = treatmentInfo;
        const {facilitesError, results, loading, search, placeError} = this.state;
        const missingDoctorsOptions = (!!facilitiesKeys && facilitiesKeys.includes(3)) && (!doctorsOffice || doctorsOffice.length === 0)
        const missingUrgentCareOptions = (!!facilitiesKeys && facilitiesKeys.includes(4)) && (!urgentCare || urgentCare.length === 0)
        const button = <Button primary content="Next step"
                               disabled={facilities.length === 0 || !treatmentInfo.place || treatmentInfo.place.length === 0 || missingDoctorsOptions || missingUrgentCareOptions}
                               onClick={this.onNext}/>;
        return (
            <React.Fragment>
                <NewClaimMenu onBack={this.onBackHandler}/>
                <ClaimStepsLayout>
                    <NewClaimSteps activeStepIdx={0}/>
                    <div style={{marginTop: "56px"}}>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={8} className={"pageContent"}>
                                <Header as={"h2"}>Tell us where you were treated</Header>
                                <p className={"neutral700Text"}>This will help us narrow down what we need from you—</p>
                                <Divider hidden/>
                                <Form>
                                    <span className={"requiredField"}><b>What type of facility did you go to?</b></span>
                                    <p className="smaller">Check all that apply—</p>
                                    {facilityOptions.map((facility, index) => {
                                        return <React.Fragment key={index}>
                                            <Form.Checkbox label={facility.text} name={facility.value}
                                                           onChange={(e) => {
                                                               this.handleFacilityChange(facility.value, facility.key)
                                                           }}
                                                           checked={facilities.includes(facility.value)}/>
                                            {(!!facilitiesKeys && facilitiesKeys.includes(3) && facility.key === 3) &&
                                            <div style={{marginLeft: '25px', marginBottom: '16px'}}>
                                                <p className={"small requiredField"}><b>Were you (select all that
                                                    apply):</b></p>
                                                {doctorsOfficeOptions.map((option, index) => {
                                                        return <Form.Checkbox label={option.text} name={option.value}
                                                                              key={index}
                                                                              onChange={this.handleDoctorChange}
                                                                              checked={doctorsOffice.includes(option.value)}/>
                                                    }
                                                )}
                                            </div>}
                                            {(!!facilitiesKeys && facilitiesKeys.includes(4) && facility.key === 4) &&
                                            <div style={{marginLeft: '25px', marginBottom: '16px'}}>
                                                <p className={"small requiredField"}><b>Were you (select all that
                                                    apply):</b></p>
                                                {urgentCareOptions.map((option, index) => {
                                                        return <Form.Checkbox label={option.text} name={option.value}
                                                                              key={index}
                                                                              onChange={this.handleUrgentCareChange}
                                                                              checked={urgentCare.includes(option.value)}/>
                                                    }
                                                )}
                                            </div>
                                            }
                                        </React.Fragment>
                                    })}

                                    {facilitesError && <Message>You have to select at least on option</Message>}

                                    <Divider hidden/>
                                    <span className={"requiredField"}><b>Where did you receive care?</b></span>
                                    <Form.Field/>
                                    <Search fluid scrolling="true" input={{fluid: true, iconPosition: 'left'}}
                                            className="googlePlacesField" loading={loading} value={search}
                                            results={results} onResultSelect={this.handleSelectPlace}
                                            onSearchChange={this.handleOnChange} onBlur={this.onBlur}/>
                                    {placeError && <Message>You have to complete this field</Message>}
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </div>
                    <div style={{marginTop: "100px"}}>
                        <Footer showTopFooter={true}
                                showCenterData={true}
                                button={button}
                        />
                    </div>
                </ClaimStepsLayout>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(TreatmentInfo));
