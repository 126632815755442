import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Form, Grid, Select, Button, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import {setQLE} from "../../../../actions/planChangeActions";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import QLEModal from "./qleModal";
import {WHEN_ROUTE} from "../../../../utils/common";
import { ClickableText } from '../../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        qleReason: state.planChange.qleReason,
        qleReasonOptions: state.initialData.qleReasonOptions,
    }
};

class QLEWhatHappened extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/change");
    }

    handleChange = (e, {value}) => {
        this.props.dispatch(setQLE(value))
    }

    onNext = () => {
        this.props.history.push(WHEN_ROUTE)
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const {qleReason, qleReasonOptions} = this.props;

        const button = <Button primary content="Next step" disabled={!qleReason || qleReason.length === 0}
                               onClick={this.onNext}/>;

        return (
            <React.Fragment>
                <QLEModal showModal={this.state.showModal} onModalClose={this.closeModal}/>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Row>
                        <Grid.Column width={7} textAlign={"center"}>
                            <Header as={"h2"}>What happened</Header>
                            <p className={"neutral700Text"}>Select your recent life event so we can confirm which
                                changes you are eligible to make and when they’ll take effect.</p>
                        </Grid.Column>
                        </Grid.Row>
                        <Grid.Column width={4} className={"pageContent"}>
                            <Form>
                                <Form.Field>
                                    <label>Life event</label>
                                    <Select value={qleReason || ''}
                                            name={"qleReason"}
                                            options={qleReasonOptions}
                                            onChange={this.handleChange}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={12} textAlign={"center"}>
                            <Divider hidden/><Divider hidden/>
                            <p className={"smaller"}>
                                <b>Can’t find your life event?</b><br/>
                                Learn more about <ClickableText onClick={()=>{this.setState({showModal: true})}}>qualifying life events</ClickableText>
                            </p>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(QLEWhatHappened));
