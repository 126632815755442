import React, {Component} from "react"
import {Form, Grid, Button, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getLocalization, languages, parseMultilineString} from "../../utils/localization";
import {setPrevRoute} from "../../actions/navigationActions";
import {handleSaveUserProgress} from "../../utils/common";
import {setFetchPlanRecommendation, setLeftoverMoney} from "../../actions/anualOpenEnrollmentActions";
import Footer from "../footer";
import AOESteps from "./aOESteps";
import {cancelAOEFlow} from "../../utils/utils";
import CancelLink from "./cancelLink";

const mapStateToProps = (state, ownProps) => {
    const {user, anualOpenEnrollment} = state;
    return {
        disposableIncomeOptions: anualOpenEnrollment.generalInitialData.disposableIncomeOptions,
        disposableIncomeSelect: anualOpenEnrollment.leftoverMoney,
        lang: user.lang,
        anualOpenEnrollment,
        ...ownProps
    }
};

class AOEDisposableIncome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            needsUserProgressUpdate: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    handleDisposableIncomeChange = (selectedKey) => {
        this.setState({needsUserProgressUpdate: true});
        this.props.dispatch(setLeftoverMoney(selectedKey));
        this.props.dispatch(setFetchPlanRecommendation(true));
    };

    render() {
        const {needsUserProgressUpdate} = this.state;
        const {disposableIncomeOptions, disposableIncomeSelect, anualOpenEnrollment} = this.props;

        const localization = getLocalization(this.props.lang);
        const strings = localization.healthPlanDetails;
        const stringsIn = strings.income;

        const leftoverMoneyOpts = disposableIncomeOptions.map((o) => {
            return {key: o.id, value: o.id, text: o.value};
        });

        const button = (<Button primary content={localization.next}
                                disabled={!disposableIncomeSelect}
                                onClick={() => {
                                    if(needsUserProgressUpdate){
                                        handleSaveUserProgress(anualOpenEnrollment).then();
                                    }
                                    this.props.history.push(this.props.nextRoute)}}/>)

        const cancel = <CancelLink onCancel={()=>{cancelAOEFlow(this.props)}} />;

        return <React.Fragment>
            <div className="member-main-container" style={{background: 'white'}}>
                <AOESteps activeStepIdx={1}/>

                <Grid container stackable columns={1} centered>
                    <Grid.Column width={8} className={"pageContent"}>
                        <Grid stackable columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as="h2">{strings.budget}</Header>
                                    <p className={"neutral700Text"}>{parseMultilineString(strings.subTitleFinancial)}</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <p>
                                        {stringsIn.afterYouPay}<b>{stringsIn.necesities}</b>
                                        {parseMultilineString(stringsIn.houseingCar)}{' '}<b>{stringsIn.eachMonth}</b>{stringsIn.forDiscretionary}
                                    </p>

                                    <Form>
                                        <Form.Field>
                                            <Form.Group grouped>
                                            {leftoverMoneyOpts.map((lm) => {
                                                let text = lm.text;
                                                const localizationEng = getLocalization(languages.ENGLISH);
                                                const engStrings = localizationEng.healthPlanDetails.income;
                                                if(text === engStrings.opt1){
                                                    text = stringsIn.opt1;
                                                } else if(text === engStrings.opt2){
                                                    text = stringsIn.opt2;
                                                } else if(text === engStrings.opt3){
                                                    text = stringsIn.opt3;
                                                } else if(text === engStrings.opt4){
                                                    text = stringsIn.opt4;
                                                }
                                                return <React.Fragment key={lm.key}>
                                                    <Form.Radio label={text} className={'radioNormalFontSize'}
                                                                value={lm.value}
                                                                checked={disposableIncomeSelect === lm.value}
                                                                onChange={() => this.handleDisposableIncomeChange(lm.key)}
                                                    />
                                                </React.Fragment>
                                            })}
                                            </Form.Group>
                                        </Form.Field>
                                    </Form>
                                    <p style={{paddingTop: '2em'}}><b>{stringsIn.disclaimer}</b></p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </div>
            <Footer showTopFooter showCenterData button={button} link={cancel}/>
        </React.Fragment>;
    }
}

export default withRouter(connect(mapStateToProps)(AOEDisposableIncome));
