import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Form, Grid, Icon, Image, Loader, Modal, Popup, Radio, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {Link} from "../../../base";
import Footer from "../../../footer";
import {connect} from "react-redux";
import {setLeftNavigation, setPrevRoute} from "../../../../actions/navigationActions";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {ADD_OTHER_DEPENDENT_ROUTE, convertDisplayDateFormatToServer, formatMoneyAmount, HEALTH_PLAN_DETAILS_ROUTE, INCOME_ROUTE, truncTwoDec, UPDATE_COVERAGE_ROUTE} from "../../../../utils/common";
import {setCatastrophicCoverage, setCostPerOption, setCoverageOption, setFetchPlanRecommendation, setModerateCoverage, setPlanContribAndCost, setPlanCoverageSelectedAndRecommended, setSevereCoverage,} from "../../../../actions/planChangeActions";
import moment from "moment";
import axios from 'axios';
import ChangeSteps from "../changeSteps";
import {debounce} from "throttle-debounce";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {ClickableText, CustomModalHeader} from '../../../custom-common';

const mapStateToProps = (state, ownProps) => {
    const {initialData} = state;
    const {contract, certificate, user} = initialData;
    const {
        coverageOption,
        costPerOption,
        costPerOptions,
        moderateCoverageSelected,
        severeCoverageSelected,
        catastrophicCoverageSelected,
        maritalStatus,
        totalDependents,
        familyTierOptions,
        selectedTierBrellaSelect,
        moderateCoverageRecommended,
        severeCoverageRecommended,
        catastrophicCoverageRecommended
    } = state.planChange
    let additionalChronic = 0;
    let additionalMental = 0;
    if (!!contract && !!contract.riderSelection && contract.riderSelection.length > 0) {
        contract.riderSelection.forEach((ci) => {
            if (ci.selected) {
                if (ci.name === "chronic") {
                    additionalChronic = ci.coverage;
                } else if (ci.name === "mental") {
                    additionalMental = ci.coverage;
                }
            }
        });
    }
    const includesDomesticPartnerships = !!initialData.eligibility.domesticPartnership;
    return {
        ...ownProps,
        additionalChronic,
        additionalMental,
        includesDomesticPartnerships,
        familyTierOptions,
        totalDependents,
        coverageOption,
        costPerOption,
        costPerOptions,
        moderateCoverageSelected,
        severeCoverageSelected,
        catastrophicCoverageSelected,
        moderateCoverageRecommended,
        severeCoverageRecommended,
        catastrophicCoverageRecommended,
        maritalStatus,
        planChange: state.planChange,
        initialData,
        selectedTierBrellaSelect,
        certificate,
        user,
        prevRoute: state.navigation.prevRoute,
        moderateIncrements: (!!initialData && !!initialData.increments && initialData.increments.moderate) || [],
        severeIncrements: (!!initialData && !!initialData.increments && initialData.increments.severe) || [],
        catastrophicIncrements: (!!initialData && !!initialData.increments && initialData.increments.catastrophic) || [],
    }
};

class PlanChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingPersonalize: false,
            submitPlanLoading: false,
            skipRecommendation: false,
            displayInformationAboutCategoriesModal: false,
            displaySeeWhyWeChoseThisAmountsModal: false,
            displayDontWantCoverageModal: false,
            userModifiedData: false,
        }

        this.debouncedPersonalizePlan = debounce(500, () => this.personalizePlan());
    }

    async componentDidMount() {
        this.props.dispatch(setLeftNavigation(false))
        const {initialData, planChange} = this.props;
        let skipRecommendation = new URLSearchParams(this.props.location.search).get("skipRecommendation")
        skipRecommendation = skipRecommendation !== null;
        if (planChange.fetchPlanRecommendation) {
            this.props.dispatch(setPlanContribAndCost(truncTwoDec(initialData.employerContribution), truncTwoDec(initialData.cost)));

            if (skipRecommendation) {
                const {certificate, initialData} = this.props;

                const costPerOption = initialData.payrollFrequency === "Monthly" ? 3 : 1
                this.props.dispatch(setCostPerOption(costPerOption));
                const deletes = this.alterDependentInfoBasedOnCoverage(certificate.familyTier);
                await this.props.dispatch(setCoverageOption(certificate.familyTier, deletes.deleteChildren, deletes.deleteSpouse));

                const m = initialData.certificate.assetCoverages.moderate;
                const s = initialData.certificate.assetCoverages.severe;
                const c = initialData.certificate.assetCoverages.catastrophic;
                const translated = this.translateAmountToSliderIdx(m, s, c);
                this.props.dispatch(setPlanCoverageSelectedAndRecommended(
                    null, translated.moderateRecommendation,
                    null, translated.severeRecommendation,
                    null, translated.catastrophicRecommendation,
                ))
                await this.personalizePlan(certificate.familyTier, costPerOption, m, s, c).then();
                this.setState({
                    skipRecommendation: skipRecommendation,
                    loading: false,
                })
            } else {
                this.setState({skipRecommendation: false})
                await this.submitInitialPlanDetails();
                this.personalizePlan().then();
            }
        } else {
            const {moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected} = this.props;
            const m = moderateCoverageSelected;
            const s = severeCoverageSelected;
            const c = catastrophicCoverageSelected;
            const translated = this.translateAmountToSliderIdx(m, s, c);
            this.props.dispatch(setPlanCoverageSelectedAndRecommended(
                null, translated.moderateRecommendation,
                null, translated.severeRecommendation,
                null, translated.catastrophicRecommendation,
            ))
        }


        let prevRoute;
        if (skipRecommendation) {
            prevRoute = UPDATE_COVERAGE_ROUTE;
        } else {
            if (!!planChange.leftoverMoney) {
                prevRoute = INCOME_ROUTE;
            } else {
                prevRoute = HEALTH_PLAN_DETAILS_ROUTE;
            }
        }
        this.props.dispatch(setPrevRoute(prevRoute))
    }

    alterDependentInfoBasedOnCoverage = (coverageOptionId) => {
        const {familyTierOptions} = this.props;
        const option = familyTierOptions.filter((c) => c.value === coverageOptionId)[0];
        let deleteChildren;
        let deleteSpouse;
        switch (option.value) {
            case 'employee':
                deleteChildren = true;
                deleteSpouse = true;
                break;
            case 'spouse':
                deleteChildren = true;
                deleteSpouse = false;
                break;
            case 'child':
                deleteChildren = false;
                deleteSpouse = true;
                break;
            default:
                deleteChildren = false;
                deleteSpouse = false;
        }
        return {deleteChildren, deleteSpouse};
    }

    translateSliderSelectionToAmount = () => {
        const {
            moderateIncrements,
            severeIncrements,
            catastrophicIncrements,
            moderateCoverageSelected,
            severeCoverageSelected,
            catastrophicCoverageSelected
        } = this.props;
        return {
            moderateCoverageSelected: moderateIncrements[moderateCoverageSelected - 1],
            severeCoverageSelected: severeIncrements[severeCoverageSelected - 1],
            catastrophicCoverageSelected: catastrophicIncrements[catastrophicCoverageSelected - 1],
        }
    }

    translateAmountToSliderIdx = (moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected) => {
        const {moderateIncrements, severeIncrements, catastrophicIncrements,} = this.props;

        const getSliderIdx = (increments, amount) => {
            let sliderIdx = 1;
            increments.forEach((e, idx) => {
                if (e === amount) {
                    sliderIdx = idx + 1;
                }
            })
            return sliderIdx;
        }

        return {
            moderateRecommendation: getSliderIdx(moderateIncrements, moderateCoverageSelected),
            severeRecommendation: getSliderIdx(severeIncrements, severeCoverageSelected),
            catastrophicRecommendation: getSliderIdx(catastrophicIncrements, catastrophicCoverageSelected),
        }
    }

    personalizePlan = async (forcedCoverageOption, forcedCostPerOpt, moderate, severe, catastrophic) => {
        this.setState({loadingPersonalize: true});
        try {
            const {
                additionalChronic,
                additionalMental,
                coverageOption,
                costPerOption,
                costPerOptions,
                initialData
            } = this.props;
            let selectedCostPerOpt;
            if (!!forcedCostPerOpt) {
                selectedCostPerOpt = forcedCostPerOpt;
            } else {
                selectedCostPerOpt = costPerOptions.filter(cpo => cpo.id === costPerOption)[0].value;
            }
            const payrollFreq = selectedCostPerOpt === 'Month' ? "Month" : initialData.payrollFrequency;
            let coverageOpt = coverageOption;
            if (!!forcedCoverageOption) {
                coverageOpt = forcedCoverageOption;
            }
            const translatedAmounts = this.translateSliderSelectionToAmount();
            let moderateBenefit = !!translatedAmounts.moderateCoverageSelected ? translatedAmounts.moderateCoverageSelected : 0;
            let severeBenefit = !!translatedAmounts.severeCoverageSelected ? translatedAmounts.severeCoverageSelected : 0;
            let catastrophicBenefit = !!translatedAmounts.catastrophicCoverageSelected ? translatedAmounts.catastrophicCoverageSelected : 0;
            if (!!moderate) {
                moderateBenefit = moderate;
                severeBenefit = severe;
                catastrophicBenefit = catastrophic;
            }
            let result = await axios.post("/api/member/v2/personalizePlan", {
                familyTierValue: coverageOpt,
                moderateBenefit: moderateBenefit,
                severeBenefit: severeBenefit,
                catastrophicBenefit: catastrophicBenefit,
                quoteInfo: initialData.quoteInfo,
                mentalBenefit: additionalMental,
                chronicBenefit: additionalChronic,
                employerContributions: initialData.employerContributions,
                presets: initialData.presets,
                payrollFrequencySelected: payrollFreq,
                ageAtPolicyEffectiveDate: initialData.certificate.ageAtPolicyEffectiveDate,
                ratedClassRates: initialData.ratedClass.ratedClassRates,
            });
            if (!!result && !!result.data) {
                const data = result.data;
                const cost = !!data.costChoice ? data.costChoice : 0.0;
                const ec = data.contribChoice ? data.contribChoice : 0.0;
                this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)))
            }
            this.setState({loadingPersonalize: false});
        } catch (e) {
            console.log(e);
            this.setState({loadingPersonalize: false})
        }
    }

    submitInitialPlanDetails = async () => {
        this.setState({loading: true});
        try {
            const {user, planChange, initialData, costPerOptions} = this.props;
            if (planChange.highDeductibleInfo === null || planChange.leftoverMoney === null) {
                this.setState({loading: false});
                return;
            }
            let result = await axios.post("/api/member/v2/submitPlanDetails", {
                planOffering: initialData.contract.planOffering,
                ageAtPolicyEffectiveDate: initialData.certificate.ageAtPolicyEffectiveDate,
                birthDate: user.birthDate,
                presets: initialData.presets,
                quoteInfo: initialData.quoteInfo,
                maritalStatusId: planChange.maritalStatus,
                dependentsId: planChange.dependentsId,
                copayId: planChange.typicallyPayInfo,
                highDeductibleInfoId: planChange.highDeductibleInfo,
                leftoverMoneyId: planChange.leftoverMoney,
                ratedClassRates: initialData.ratedClass.ratedClassRates,
            });
            if (!!result && !!result.data) {
                const data = result.data;
                const moderateRecommendation = (!!data && !!data.choice && data.choice.moderateBenefit) || 0;
                const severeRecommendation = (!!data && !!data.choice && data.choice.severeBenefit) || 0;
                const catastrophicRecommendation = (!!data && !!data.choice && data.choice.catastrophicBenefit) || 0;

                const translated = this.translateAmountToSliderIdx(moderateRecommendation, severeRecommendation, catastrophicRecommendation);
                const moderateCoverageSelected = translated.moderateRecommendation;
                const severeCoverageSelected = translated.severeRecommendation;
                const catastrophicCoverageSelected = translated.catastrophicRecommendation;
                this.props.dispatch(setPlanCoverageSelectedAndRecommended(
                    moderateRecommendation, moderateCoverageSelected,
                    severeRecommendation, severeCoverageSelected,
                    catastrophicRecommendation, catastrophicCoverageSelected,
                ))

                const isMonthly = initialData.payrollFrequency === "Monthly";
                let costPerOption = costPerOptions.filter(cpo => cpo.value === 'Paycheck')[0].id
                if (isMonthly) {
                    costPerOption = costPerOptions.filter(cpo => cpo.value === 'Month')[0].id
                }
                this.props.dispatch(setCostPerOption(costPerOption));

                let recommendedOption = data.familyTierValue;
                const deletes = this.alterDependentInfoBasedOnCoverage(recommendedOption);
                this.props.dispatch(setCoverageOption(recommendedOption, deletes.deleteChildren, deletes.deleteSpouse));

                //const cost = !!data && !!data.choice && !!data.choice.cost ? data.choice.cost : 0;
                //const ec = findEmployerContribution(coverageOption, familyTierOptions, initialData.employerContribution);
                //this.props.dispatch(setPlanContribAndCost(truncTwoDec(ec), truncTwoDec(cost)))
                this.setState({loading: false});
            } else {
                this.setState({loading: false});
            }
        } catch (e) {
            console.error(e);
            this.setState({loading: false})
        }
    }

    doRenderSlider = (printableOnly, stepsAmountsArr, value, recommendedValue, onChange, onAfterChange) => {
        const marks = {};
        const markStyle = {fontSize: '12px', color: '#9E9E9E', fontWeight: 'bold'};

        const sliderStepsArr = [];
        stepsAmountsArr.forEach((e, idx) => {
            sliderStepsArr[idx] = idx + 1; // rc-slider doesn't allow selecting 0
        })

        sliderStepsArr.forEach((step) => {
            const stepAmountsArrIdx = step - 1;
            marks[step] = {style: markStyle}
            if (step === value) {
                marks[step].label = <div style={{
                    fontSize: '12px',
                    color: 'black',
                    fontWeight: 'bold'
                }}>${formatMoneyAmount(stepsAmountsArr[stepAmountsArrIdx])}</div>;
            } else {
                marks[step].label = `$${formatMoneyAmount(stepsAmountsArr[stepAmountsArrIdx])}`;
            }
        });

        if (!!recommendedValue) {
            let recommendedValueIdx = 0;
            stepsAmountsArr.forEach((amount, idx) => {
                if (amount === recommendedValue) {
                    recommendedValueIdx = idx + 1;
                }
            })
            let label = <div>
                <div style={{
                    fontSize: '12px',
                    color: 'black',
                    fontWeight: 'bold'
                }}>${formatMoneyAmount(recommendedValue)}</div>
                Recommended</div>
            if (value !== recommendedValueIdx) {
                label = <div>
                    <div>${formatMoneyAmount(recommendedValue)}</div>
                    Recommended</div>
            }
            marks[recommendedValueIdx] = {
                style: markStyle,
                label: label,
            }
        }

        const min = stepsAmountsArr[0];
        value = value === null ? min : value;
        return <Slider
            onChange={onChange}
            onAfterChange={onAfterChange}
            min={1} max={stepsAmountsArr.length} step={1} dots={false} marks={marks}
            defaultValue={!!value ? value : min}
            value={value}
            //activeDotStyle={{height: '0px', width: '0px', border: '0 none', backgroundColor: '#EAEAEA',}}
            dotStyle={{display: 'none'}}
            handleStyle={[{
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                marginTop: '-7px',
                borderColor: '#D1D1D1',
                boxShadow: 'none'
            }]}
            railStyle={{backgroundColor: '#EAEAEA'}}
            trackStyle={{backgroundColor: '#43806C'}}
        />
    }

    renderSlider = (stepsArr, value, recommendedValue, onChange, onAfterChange) => {
        return this.doRenderSlider(false, stepsArr, value, recommendedValue, onChange, onAfterChange);
    }

    renderCategoryExplanationModal = () => {
        const {displayInformationAboutCategoriesModal} = this.state;
        const onModalClose = () => this.setState({displayInformationAboutCategoriesModal: false});
        return <Modal size={'tiny'} open={displayInformationAboutCategoriesModal} onClose={onModalClose}>
            <CustomModalHeader title={"Category details"} onClose={onModalClose} className="customModalHeader" />
            <Modal.Content style={{maxHeight: '513px', overflowY: 'auto'}} className={"small"}>
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Image
                                src='https://brella-platform-assets.s3.amazonaws.com/enrollment/images/Condition-Moderate-2.png'
                                style={{width: '91px', height: '98px'}}/>
                        </Grid.Column>
                        <Grid.Column width={8} style={{paddingLeft: 0}}>
                            <div className="deepPurpleHeader" style={{fontSize: '16px'}}>Moderate</div>
                            <div className="small">Conditions that require a short visit to the ER or urgent care for
                                diagnosis
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Image
                                src='https://brella-platform-assets.s3.amazonaws.com/enrollment/images/Condition-Severe-1.png'
                                style={{width: '103px', height: '91px'}}/>
                        </Grid.Column>
                        <Grid.Column width={8} style={{paddingLeft: 0}}>
                            <div className="deepPurpleHeader" style={{fontSize: '16px'}}>Severe</div>
                            <div className="small">Serious conditions that often require surgery or more intensive
                                treatment
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2} verticalAlign="middle" style={{marginBottom: '30px'}}>
                        <Grid.Column width={4}>
                            <Image
                                src='https://brella-platform-assets.s3.amazonaws.com/enrollment/images/Condition-Catastrophic-1.png'
                                style={{width: '125px', height: '79px'}}/>
                        </Grid.Column>
                        <Grid.Column width={8} style={{paddingLeft: 0}}>
                            <div className="deepPurpleHeader" style={{fontSize: '16px'}}>Catastrophic</div>
                            <div className="small">Dangerous or life-threatening conditions that require immediate
                                medical intervention
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>
    }

    renderSeeWhyWeChoseThisAmountsModal = () => {
        const {displaySeeWhyWeChoseThisAmountsModal} = this.state;
        const onModalClose = () => this.setState({displaySeeWhyWeChoseThisAmountsModal: false});
        return <Modal open={displaySeeWhyWeChoseThisAmountsModal} size={'tiny'} onClose={onModalClose}>
            <CustomModalHeader title={"Recommendation overview"} onClose={onModalClose} className="customModalHeader" />
            <Modal.Content style={{maxHeight: '513px', overflowY: 'auto'}} className={"small"}>
                <p>Your needs are unique to you. That’s why we made Brella’s plans customizable—so you can choose the
                    payout amounts that are right for you. We assess a number of different factors to make a
                    personalized recommendation. Here’s how each factor comes into play.</p>
                <p><b>Age</b><br/>
                    The types and severity of health issues we face changes over time. As we get older, the risk of
                    serious and dangerous medical issues increases, so we generally recommend increasing payout amounts
                    for the Severe and Catastrophic benefit categories with age. On the other hand, when we are younger
                    and tend to be more active, Brella recommends higher payout amounts in the Moderate benefit
                    category.
                </p>

                <p><b>Family status</b><br/>
                    Your family makeup helps us understand who is eligible for coverage and make some assumptions about
                    their health risks. Given their zest for life, children generally tend to have less serious but more
                    frequent injuries and illnesses, so if you have children we recommend higher Moderate payout
                    amounts.
                </p>

                <p><b>Medical expenses</b><br/>
                    How you feel about the affordability of your health plan’s deductible and your share of costs give
                    us a sense of your financial exposure in the event of an unexpected health issue. If you have
                    concerns about your deductible and / or health cost responsibilities we recommend higher payouts
                    across all three categories.
                </p>

                <p><b>Your budget</b><br/>
                    Your discretionary income helps us recommend a plan with premiums that work for your budget. If you
                    don’t have much left over at the end of the month, we might recommend a Brella plan with a more
                    affordable premium. As your disposable income increases, we may recommend more coverage in line with
                    other factors like your age and family status.
                </p>
            </Modal.Content>
        </Modal>
    }

    getFilteredCoverOptions = () => {
        const {familyTierOptions, includesDomesticPartnerships, totalDependents, maritalStatus} = this.props;

        const coverOpts = familyTierOptions.map(c => {
            let text = c.text;
            if (c.text === "Myself") {
                text = "Myself";
            } else if (c.text === "My spouse and me") {
                if (!!includesDomesticPartnerships) {
                    text = <React.Fragment>My <u>spouse</u> and me</React.Fragment>;
                } else {
                    text = "My spouse and me";
                }
            } else if (c.text === "My child(ren) and me") {
                text = <React.Fragment>My <u>child(ren)</u> and me</React.Fragment>;
            } else if (c.text === "My family and me") {
                text = "My family and me";
            }
            return {key: c.key, text: <label>{text}</label>, value: c.value}
        });

        const married = maritalStatus === 1
        const hasKids = totalDependents.filter(dependent => dependent.relToEmployee === "Child").length > 0

        let filteredCoverOptions;
        if (!!married) {
            if (!hasKids) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('child'))
            } else {
                filteredCoverOptions = coverOpts;
            }
        } else {
            if (hasKids) {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse'))
            } else {
                filteredCoverOptions = coverOpts.filter((opts) => !opts.value.includes('family') && !opts.value.includes('spouse') && !opts.value.includes('child'))
            }
        }
        return filteredCoverOptions;
    }

    getRecommendedPlanLabelForDescription = () => {
        const {recommendedPlan} = this.state;
        if (recommendedPlan.toLowerCase() === 'value') {
            return "Value Plan";
        }
        if (recommendedPlan.toLowerCase() === 'enhanced') {
            return "Enhanced Plan";
        }
        if (recommendedPlan.toLowerCase() === 'premier') {
            return "Premier Plan";
        }
    }

    onBackHandler = () => {
        this.props.history.replace(this.props.prevRoute);
    }

    onNext = () => {
        const skipRecommendation = new URLSearchParams(this.props.location.search).get("skipRecommendation")
        const skip = skipRecommendation !== null
        const route = skip ? "/change/update/choice?skipRecommendation" : "/change/update/choice"
        this.props.dispatch(setPrevRoute(route))

        const {moderateCoverageRecommended, severeCoverageRecommended, catastrophicCoverageRecommended} = this.props;
        const translatedAmounts = this.translateSliderSelectionToAmount();
        let moderateBenefit = !!translatedAmounts.moderateCoverageSelected ? translatedAmounts.moderateCoverageSelected : 0;
        let severeBenefit = !!translatedAmounts.severeCoverageSelected ? translatedAmounts.severeCoverageSelected : 0;
        let catastrophicBenefit = !!translatedAmounts.catastrophicCoverageSelected ? translatedAmounts.catastrophicCoverageSelected : 0;
        this.props.dispatch(setPlanCoverageSelectedAndRecommended(moderateCoverageRecommended, moderateBenefit, severeCoverageRecommended, severeBenefit, catastrophicCoverageRecommended, catastrophicBenefit))
        this.props.history.push("/change/update/confirm")
    }

    render() {
        const {loading, loadingPersonalize, skipRecommendation} = this.state;
        const {
            employerContribution, planChange, additionalChronic, additionalMental,
            moderateIncrements, severeIncrements, catastrophicIncrements,
            costPerOption, costPerOptions, coverageOption, initialData,
            moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected,
            moderateCoverageRecommended, severeCoverageRecommended, catastrophicCoverageRecommended, user,
            includesDomesticPartnerships
        } = this.props;

        const greenBlockStyle = {background: '#E8F6F3', marginTop: '2em', padding: '1em', width: '100%'};
        const infoIcon = <Icon className={'nonprintable'} circular name={'info'}
                               style={{color: 'black', marginLeft: '1em', fontSize: '10px', cursor: 'pointer'}}
                               onClick={() => this.setState({displayInformationAboutCategoriesModal: true})}/>;
        const paycheckOpt = !!costPerOptions && costPerOptions.filter((cpo) => cpo.value === "Paycheck")[0].id;
        const monthlyOpt = !!costPerOptions && costPerOptions.filter((cpo) => cpo.value === "Month")[0].id;

        let descriptionGreen = <ClickableText
                                     onClick={() => this.setState({displaySeeWhyWeChoseThisAmountsModal: true})}>
            See why we chose these amounts.
        </ClickableText>;
        let description = <span>
            Based on the information you provided, we recommend the following. Use the sliders to select or adjust your plan’s payouts and who’s covered. <br/>
            <span>{' '}{descriptionGreen}.{' '}</span>You can choose or adjust your plan and who’s covered below—
        </span>
        let title = "Our recommendation for you";
        if (skipRecommendation) {
            title = "Select your Brella benefits";
            descriptionGreen = <Link to={HEALTH_PLAN_DETAILS_ROUTE}><b>personalized recommendation</b></Link>;
            description = <React.Fragment>Need some help? Answer 3 questions to get a{' '}<span
                className={"mediumBlueText"}>{descriptionGreen}{'\u2192'}</span></React.Fragment>
        }

        let displayAgeReduction = false;
        const {
            ageReduction,
            ageReductionAge,
            ageReductionPercentage
        } = initialData.certificate.ratedClass.contractTerms;
        if (ageReduction) {
            const age = moment().diff(convertDisplayDateFormatToServer(user.birthDate), 'years');
            if (age >= ageReductionAge) {
                displayAgeReduction = true;
            }
        }
        const filteredCoverOptions = this.getFilteredCoverOptions();
        const button = (<Button primary content="Next step" onClick={this.onNext}/>)

        const hasAddedDependentsOutsideQle = planChange.planChangeDependents.filter(dependent => dependent.isQle === false).length > 0;

        if (loading) {
            return <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container"
                     style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                    <div className="member-main-container"
                         style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                        <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                            <Grid.Column width={8} className={"pageContent"} textAlign={"center"}>
                                <Image centered style={{width: '150px'}}
                                       src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Loading.png"}/>
                                <Header as={'h4'}>Just a moment...</Header>
                                <p>We're personalizing your plan</p>
                            </Grid.Column>
                        </Grid>
                    </div>
                </div>
            </React.Fragment>;
        } else {
            return (
                <React.Fragment>
                    {this.renderCategoryExplanationModal()}
                    {this.renderSeeWhyWeChoseThisAmountsModal()}
                    <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                    <div className="member-main-container" style={{background: 'white'}}>
                        <ChangeSteps activeStepIdx={2}/>
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={10} className={"pageContent"} textAlign={"center"}>
                                <Grid stackable container>
                                    <Grid.Row columns={1} centered>
                                        <Grid.Column textAlign={'left'} width={12}>
                                            <Header as='h2' className={'printableTitle'}>{title}</Header>
                                            <p className={'printableText'}>{description}</p>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {!!displayAgeReduction && <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <Divider hidden/>
                                            <CustomContainer basic className={"bkgLinen small"}>
                                                <b>IMPORTANT</b>: Based on your age, you will
                                                receive {ageReductionPercentage}% of any benefit you select here.
                                            </CustomContainer>
                                            <Divider hidden/>
                                        </Grid.Column>
                                    </Grid.Row>}

                                    <Grid.Row columns={1}>
                                        <Grid.Column textAlign={"left"} className={"small"}>
                                            <p className={"neutral600Text"}><b>Current coverage</b></p>
                                            <p>
                                                <b>Moderate:</b> ${formatMoneyAmount(initialData.certificate.assetCoverages.moderate)}
                                                <span style={{
                                                    paddingLeft: '1.5em',
                                                    paddingRight: '1.5em'
                                                }}><b>Severe:</b> ${formatMoneyAmount(initialData.benefits.severe)}</span>
                                                <b>Catastrophic:</b> ${formatMoneyAmount(initialData.benefits.catastrophic)}
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={2} centered>
                                        <Grid.Column width={8}>
                                            <p className={"neutral600Text small"}><b>Adjust your plan</b></p>
                                            <Grid stackable>
                                                <Grid.Row>
                                                    <Grid.Column
                                                        className="small deepPurpleHeader">Moderate{infoIcon}</Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className={'nonprintable'}>
                                                    <Grid.Column style={{marginBottom: '3em'}}>
                                                        {this.renderSlider(moderateIncrements, moderateCoverageSelected, moderateCoverageRecommended, async (value) => {
                                                            await this.setState({userModifiedData: true});
                                                            await this.props.dispatch(setModerateCoverage(value));
                                                            this.props.dispatch(setFetchPlanRecommendation(false));
                                                        }, this.debouncedPersonalizePlan)}
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row>
                                                    <Grid.Column
                                                        className="small deepPurpleHeader">Severe{infoIcon}</Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className={'nonprintable'}>
                                                    <Grid.Column style={{marginBottom: '3em'}}>
                                                        {this.renderSlider(severeIncrements, severeCoverageSelected, severeCoverageRecommended, async (value) => {
                                                            await this.setState({userModifiedData: true});
                                                            await this.props.dispatch(setSevereCoverage(value));
                                                            this.props.dispatch(setFetchPlanRecommendation(false));
                                                        }, this.debouncedPersonalizePlan)}
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row>
                                                    <Grid.Column
                                                        className="small deepPurpleHeader">Catastrophic{infoIcon}</Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className={'nonprintable'}>
                                                    <Grid.Column style={{marginBottom: '3em'}}>
                                                        {this.renderSlider(catastrophicIncrements, catastrophicCoverageSelected, catastrophicCoverageRecommended, async (value) => {
                                                            await this.setState({userModifiedData: true});
                                                            await this.props.dispatch(setCatastrophicCoverage(value));
                                                            this.props.dispatch(setFetchPlanRecommendation(false));
                                                        }, this.debouncedPersonalizePlan)}
                                                    </Grid.Column>
                                                </Grid.Row>

                                            </Grid>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Grid stackable>
                                                <Grid.Column>
                                                    <Form>
                                                        <p className="small"><b>I want to cover:</b></p>
                                                        {filteredCoverOptions.map((opt, idx) => {
                                                            let content = <Radio key={idx} label={opt.text}
                                                                                 name='radioGroup'
                                                                                 style={{marginRight: '0.4em'}}
                                                                                 checked={coverageOption === opt.value}
                                                                                 value={opt.value}
                                                                                 onClick={async (e, {value}) => {
                                                                                     await this.setState({userModifiedData: true});
                                                                                     const deletes = this.alterDependentInfoBasedOnCoverage(value);
                                                                                     await this.props.dispatch(setCoverageOption(value, deletes.deleteChildren, deletes.deleteSpouse));
                                                                                     await this.personalizePlan(null, null);
                                                                                     this.props.dispatch(setFetchPlanRecommendation(false));
                                                                                 }}/>
                                                            if (opt.value === "child") {
                                                                content = <Popup key={idx} content={
                                                                    <span>Children are eligible up to age {initialData.contract.childMaxEligibilityAge}.</span>}
                                                                                 style={{fontSize: '12px'}}
                                                                                 trigger={content}/>
                                                            } else if (opt.value === "spouse" && !!includesDomesticPartnerships) {
                                                                content = <Popup key={idx} content={
                                                                    <span>Includes domestic partnerships and civil unions.</span>}
                                                                                 style={{fontSize: '12px'}}
                                                                                 trigger={content}/>
                                                            }
                                                            return content;
                                                        })}
                                                    </Form>

                                                    <div className={"smaller"} style={{marginTop: '1em'}}>
                                                        Don't see a coverage tier?
                                                        {hasAddedDependentsOutsideQle ?
                                                            <p>Go back and{' '} <Link style={{color: '#437F6C'}}
                                                                                      to={ADD_OTHER_DEPENDENT_ROUTE}>edit
                                                                your family details.</Link></p> :
                                                            <p>You'll be able to add family members outside of this QLE
                                                                at the end of this flow.</p>
                                                        }
                                                    </div>

                                                    <div style={greenBlockStyle}>
                                                        {!!employerContribution && employerContribution > 0 &&
                                                        <div style={{marginBottom: '1em'}}>
                                                            <div className="smaller">Employer contribution</div>
                                                            <b style={{margin: '0', color: '#214E41'}}>
                                                                {!!loadingPersonalize ?
                                                                    <Loader inline active size={'tiny'}/> :
                                                                    <React.Fragment>${employerContribution}{initialData.payrollFrequencySelected === initialData.payrollFrequencyMonthly ? "/mo" : null}</React.Fragment>}
                                                            </b>
                                                        </div>}

                                                        <div style={{marginBottom: '2em'}} className="smaller">
                                                            <div>Your cost</div>
                                                            <Header as="h4" style={{margin: '0'}}>
                                                                {!!loadingPersonalize ?
                                                                    <Loader inline active size={'tiny'}/> :
                                                                    <React.Fragment>${planChange.cost}{initialData.payrollFrequency === "Monthly" ? "/mo" : null}</React.Fragment>}
                                                            </Header>
                                                        </div>

                                                        {initialData.payrollFrequency !== "Monthly" &&
                                                        <React.Fragment>
                                                            <Form.Checkbox radio={true}
                                                                           checked={costPerOption === paycheckOpt}
                                                                           onClick={async () => {
                                                                               await this.setState({userModifiedData: true});
                                                                               await this.props.dispatch(setCostPerOption(paycheckOpt));
                                                                               this.props.dispatch(setFetchPlanRecommendation(false));
                                                                               this.personalizePlan().then();
                                                                           }} label={"Per paycheck"}/>
                                                            <Form.Checkbox radio={true}
                                                                           checked={costPerOption === monthlyOpt}
                                                                           onClick={async () => {
                                                                               await this.setState({userModifiedData: true});
                                                                               await this.props.dispatch(setCostPerOption(monthlyOpt));
                                                                               this.props.dispatch(setFetchPlanRecommendation(false));
                                                                               this.personalizePlan().then();
                                                                           }} label={"Monthly"}/>
                                                        </React.Fragment>
                                                        }
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {(!!additionalChronic || !!additionalMental) && <React.Fragment>
                                        <Grid.Row columns={1}>
                                            <Grid.Column textAlign={'left'}>
                                                <Header as={'h3'}>
                                                    Brella<span style={{color: 'red'}}>+</span>
                                                </Header>
                                                <p>Your plan also covers these conditions—the payout for these
                                                    conditions is listed.</p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={!!additionalChronic && !!additionalMental ? 2 : 1} stretched>
                                            {!!additionalChronic &&
                                            <Grid.Column textAlign={'left'}>
                                                <div className={'bkgFog'} style={{padding: '2em'}}>
                                                    <div style={{marginBottom: '0.3em'}}>
                                                        <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                            <Header as={'h4'} style={{display: 'inline'}}>Chronic{' '}</Header>
                                                        </span>
                                                        ${additionalChronic}
                                                    </div>
                                                    <p>Receive coverage for over <b>4,100</b> additional chronic
                                                        conditions spanning asthma, diabetes, epilepsy, and many more.
                                                    </p>
                                                </div>
                                            </Grid.Column>
                                            }
                                            {!!additionalMental &&
                                            <Grid.Column style={{margin: 0}} textAlign={'left'}>
                                                <div className={'bkgFog'} style={{padding: '2em'}}>
                                                    <div style={{marginBottom: '0.3em'}}>
                                                        <span style={{fontSize: '20px', marginRight: '0.1em'}}>
                                                            <Header as={'h4'} style={{display: 'inline'}}>Mental{' '}</Header>
                                                        </span>
                                                        ${additionalMental}
                                                    </div>
                                                    <p>Additional coverage for <b>600</b> different mental health
                                                        disorders like anxiety, bipolar, and depression.</p>
                                                </div>
                                            </Grid.Column>}
                                        </Grid.Row>
                                    </React.Fragment>}
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        <Footer showTopFooter={true} showCenterData={true}
                                button={button}
                        />
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default connect(mapStateToProps)(withRouter(PlanChoice));
