import React, {Component} from 'react'
import {Divider, Form, Icon, Message, Modal, Segment, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setLeftNavigation} from "../../actions/navigationActions";
import NumberFormat from "react-number-format";
import {convertServerDateFormatToDisplay, resolveErrorMessage} from "../../utils/common";
import Footer from "../footer";
import ErrorInput from "../errorInput";
import axios from "axios";
import {updateDependents} from "../../actions/initialDataActions";
import Alert from "../alert";
import BillingAlert from "../billing/billingAlert";
import FailedPaymentAlert from "../billing/failedPaymentAlert";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {isValidEmail} from "../../utils/utils";
import {CustomDivider, CustomGrid, CustomModalHeader, SSNDisplay, SSNInput, DOBDisplay, CustomEmailInput} from '../custom-common';
import {show as showToast} from "../../actions/toastActions";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        dependents: state.initialData.dependents,
        user: state.initialData.user,
        contactSfId: state.auth.loginInfo ? state.auth.loginInfo.contactSfId : null,
        userIsCertificateHolder: state.initialData.userIsCertificateHolder,
        NIGOClaims: state.claims.NIGOClaims,
        lastPaymentFailed: state.billing.lastPaymentFailed
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLeftNavigation: state => dispatch(setLeftNavigation(state)),
        updateDependents: dependents => dispatch(updateDependents(dependents)),
        showToast: (toastDetails) => dispatch(showToast(toastDetails)),
    }
};


class MyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showInviteModal: false,
            selectedDependent: null,
            validationErrorsMap: new Map(),
        }
    }

    componentDidMount() {
        const {setLeftNavigation} = this.props

        setLeftNavigation(true)
    }

    closeInviteModal = () => {
        this.setState({
            selectedDependent: null,
            showInviteModal: false,
            validationErrorsMap: new Map(),
        })
    }

    invite = (selectedDependent) => {
        this.setState({
            invitationEmail: selectedDependent?.email ? selectedDependent.email : null,
            invitationSsn: selectedDependent?.socialSecurityNumber ? selectedDependent.socialSecurityNumber : null,
            selectedDependent: selectedDependent,
            showInviteModal: true,
            submitError: null,
            validationErrorsMap: new Map(),
        })
    }

    handleChange = (e, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    getError = (key) => {
        const vem = this.state.validationErrorsMap;
        if (!!vem) {
            return vem.get(key);
        }
        return null;
    }

    setError = (key, msg) => {
        const {validationErrorsMap} = this.state;
        validationErrorsMap.set(key, msg)
        this.setState({
            validationErrorsMap,
        })
    }

    setInvitationSSNError = (value) => {
        this.setError("invitationSsn", value);
    }

    isSsnValid(ssn) {
        let number = ssn.replace(/\D/g, '');
        return !isNaN(number) && number.length === 9;
    }

    validInviteData = () => {
        const {invitationSsn, invitationEmail, selectedDependent, validationErrorsMap} = this.state;

        if (!selectedDependent.email || selectedDependent.email !== invitationEmail) {
            if (invitationEmail === null) {
                return false;
            } else if (!isValidEmail(invitationEmail)) {
                validationErrorsMap.set("invitationEmail", "Not a valid Email");
            } else {
                validationErrorsMap.delete("invitationEmail");
            }
        }

        if (!selectedDependent.socialSecurityNumber || selectedDependent.socialSecurityNumber !== invitationSsn) {
            if (invitationSsn === null) {
                return false;
            } else {
                if (!this.isSsnValid(invitationSsn)) {
                    validationErrorsMap.set("invitationSsn", "Not a valid Social Security Number");
                } else {
                    validationErrorsMap.delete("invitationSsn");
                }
            }
        }

        if (validationErrorsMap.size) {
            this.setState({
                validationErrorsMap,
            })
            return false;
        }
        return true;
    };

    send = async () => {
        this.setState({submitError: null});
        if (!this.validInviteData()) {
            return
        }
        const {selectedDependent, invitationEmail, invitationSsn} = this.state;
        const {updateDependents, showToast} = this.props

        this.setState({sending: true})
        try {
            let payload = {
                email: (!!selectedDependent && !!selectedDependent.email) ? selectedDependent.email : invitationEmail,
                socialSecurityNumber: (!!selectedDependent && !!selectedDependent.socialSecurityNumber) ? selectedDependent.socialSecurityNumber : invitationSsn,
                dependentId: selectedDependent.id
            }
            await axios.post("/api/member/v2/inviteDependent", payload, {headers: {'Content-Type': 'application/json'}});
            const result = await axios.post("/api/member/v2/prepareApp", payload, {headers: {'Content-Type': 'application/json'}});
            updateDependents(result.data.dependents)
            showToast({type: 'success', message: `Invite sent to ${invitationEmail}`});
            this.setState({
                sending: false,
                showInviteModal: false,
            })
        } catch (e) {
            console.warn(e)
            this.setState({sending: false, submitError: resolveErrorMessage(e, 'An unexpected error occurred.')})
        }
    }

    viewClaimDetail = (claimId) => {
        this.props.history.push({pathname: "/claims/details", state: {claimId: claimId}})
    }

    render() {
        const {
            invitationEmail,
            invitationSsn,
            selectedDependent,
            sending,
            showInviteModal,
            submitError
        } = this.state;
        const {user, dependents, userIsCertificateHolder, NIGOClaims, lastPaymentFailed} = this.props;
        const alerts = [(!!NIGOClaims && NIGOClaims.length > 0), lastPaymentFailed];
        let count = 0;
        alerts.forEach(alert => {
                if (!!alert) {
                    count = count + 1
                }
            }
        )
        const marginTop = count !== 0 ? count * 38 : -16;

        const invitationEmailError = this.getError("invitationEmail");
        const invitationSsnError = this.getError("invitationSsn");
        const modalHeaderTitle = !!selectedDependent && `Invite ${selectedDependent.firstName} ${selectedDependent.lastName} to ${STRATEGIC_PARTNER.LABEL}`;
        const isDependentSSNPresent = !!selectedDependent?.socialSecurityNumber;

        return (
            <React.Fragment>
                {!!showInviteModal &&
                    <Modal size={'tiny'} closeOnDimmerClick={false} onClose={this.closeInviteModal} open={showInviteModal}>
                        <CustomModalHeader title={modalHeaderTitle} onClose={this.closeInviteModal} className="customModalHeader" />
                        <Modal.Content>
                            <Form>
                                <Form.Group>
                                    <CustomEmailInput
                                        required
                                        width={12}
                                        readOnly={!!selectedDependent?.email}
                                        value={selectedDependent?.email || invitationEmail || ''}
                                        name={"invitationEmail"}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <SSNInput
                                        disabled={isDependentSSNPresent}
                                        label={"Social Security Number"}
                                        note={"This will be used to verify their identity at account creation."}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            this.handleChange(e, {name: "invitationSsn", value: value});
                                        }}
                                        required
                                        setSSNError={this.setInvitationSSNError}
                                        value={isDependentSSNPresent ? selectedDependent.socialSecurityNumber : invitationSsn}
                                        visibilityIconVisible={!isDependentSSNPresent}
                                        width={12}
                                    />
                                </Form.Group>
                            </Form>
                            <Divider hidden/>
                            {!!submitError && <Message negative>{submitError}</Message>}
                            <Segment basic vertical clearing style={{paddingBottom: 0}}>
                                <Button primary floated={"right"} loading={sending}
                                        disabled={sending || (!selectedDependent?.email && !invitationEmail) || (!selectedDependent?.socialSecurityNumber && !invitationSsn) || !!invitationEmailError || !!invitationSsnError}
                                        onClick={this.send}>Send</Button>
                                <Button color={"grey"} basic floated={"right"}
                                        onClick={this.closeInviteModal}>Cancel</Button>
                            </Segment>
                        </Modal.Content>
                </Modal>}
                <div className="member-main-container">
                    <div className={"alertContainer"}>
                        {(!!NIGOClaims && NIGOClaims.length > 0) &&
                        <Alert style={{width: "calc(100% - 220px)"}} type={"warning"}>
                            {NIGOClaims.length > 1 ? <span>We need some additional information from you to
                        process some of your claims. <span onClick={() => {
                                    this.props.history.push("/claims")
                                }} className={"link"}><b>View claims→</b></span>
                        </span> : <span>We need some additional information from you to
                        process your claim. <span onClick={() => {
                                    this.viewClaimDetail(NIGOClaims[0].id)
                                }} className={"link"}><b>View claim→</b></span>
                        </span>
                            }
                        </Alert>}
                        {lastPaymentFailed && <FailedPaymentAlert switchToSettings={()=>{this.props.history.push({pathname: "/billing", state: {activeTab: 'billingSettings'}})}}/>}
                        <BillingAlert switchToOverview={() => {
                            this.props.history.push('/billing')
                        }}/>
                    </div>
                    <Divider hidden/>

                    <CustomGrid container stackable columns={1} centered fitted={'vertically'} style={{marginTop: `${marginTop}px`}}>
                        <CustomGrid.Column width={11}>
                            <Divider hidden/>

                            {!!user && <CustomContainer padded style={{marginBottom: '24px'}}>
                                <CustomGrid columns={3} vertically padded>
                                    <CustomGrid.Row fitted={'vertically'}>
                                        <CustomGrid.Column width={12}>
                                            <span className={"neutral600Text"}><b>SELF</b></span>
                                            <Header as={"h2"}
                                                    style={{margin: '0.5rem 0 1rem 0'}}>{user.firstName} {user.lastName}</Header>
                                            <Header as={"h4"}>Member ID# {user.memberId}</Header>
                                            <Header as={"h4"}>Personal Details</Header>
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                    <CustomGrid.Row>
                                        <CustomGrid.Column width={4} className={"neutral900Text"}>
                                            <DOBDisplay dob={convertServerDateFormatToDisplay(user.birthDate)} visibilityIconVisible={false}/>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column width={5} className={"neutral900Text"}>
                                            <b>Address</b><br/>
                                            <span>{user.address.street}, {user.address.city}, {user.address.state} {user.address.zipCode}</span>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column width={3}
                                                     className={"neutral900Text"}><b>Phone</b><br/>{!!user.phone ?
                                            <NumberFormat displayType={'text'} format={"(###) ###-####"}
                                                          value={user.phone}/> : <span>{user.mobilePhone || "-"}</span>}
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                    <CustomGrid.Row fitted={'vertically'}>
                                        <CustomGrid.Column width={4} className={"neutral900Text"}>
                                            <SSNDisplay ssn={user.socialSecurityNumber} visibilityIconVisible={false}/>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column width={8} className={"neutral900Text"}>
                                            <b>Email address</b><br/>
                                            <span>{user.email || '-'}</span>
                                        </CustomGrid.Column>
                                    </CustomGrid.Row>
                                    <CustomDivider small hidden/>
                                    <CustomGrid.Column width={12}><p className={"small"}>If anything is incorrect or needs to
                                        be updated, call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.</p></CustomGrid.Column>
                                </CustomGrid>
                            </CustomContainer>}
                            {(!!userIsCertificateHolder && !!dependents && dependents.length > 0) && dependents.map((dependent, i) =>
                                <React.Fragment key={i}>
                                    <CustomContainer padded key={i} style={{marginBottom: '24px'}}>
                                        <CustomGrid columns={3}>
                                            <CustomGrid.Row fitted={'vertically'}>
                                                <CustomGrid.Column width={8}>
                                            <span className={"neutral600Text"}
                                                  style={{textTransform: "uppercase"}}><b>{dependent.relToEmployee}</b></span>
                                                    <Header as={"h2"}
                                                            style={{margin: '0.5rem 0 1rem 0'}}>{dependent.firstName} {dependent.lastName}</Header>
                                                    <Header as={"h4"}>Member
                                                        ID# {dependent.memberId}</Header>
                                                    <Header as={"h4"}>Personal Details</Header>
                                                </CustomGrid.Column>
                                                <CustomGrid.Column width={4} textAlign={"right"}>
                                                    {!!dependent.invited ?
                                                        <Button primary basic className="unclickable"><Icon name="check"/>Invite sent</Button> :
                                                        !!dependent.invitable ?
                                                            <Button primary onClick={() => {
                                                                this.invite(dependent)
                                                            }}>Invite</Button> : null
                                                    }
                                                </CustomGrid.Column>
                                            </CustomGrid.Row>
                                            <CustomGrid.Row>
                                                <CustomGrid.Column width={4} className={"neutral900Text"}>
                                                    <DOBDisplay dob={convertServerDateFormatToDisplay(dependent.birthDate)} visibilityIconVisible={false}/>
                                                </CustomGrid.Column>
                                                <CustomGrid.Column width={5} className={"neutral900Text"}>
                                                    <b>Address</b><br/>
                                                    <span>{dependent.address.street}, {dependent.address.city}, {dependent.address.state} {dependent.address.zipCode}</span>
                                                </CustomGrid.Column>
                                                <CustomGrid.Column width={3}
                                                             className={"neutral900Text"}><b>Phone</b><br/><span>{!!dependent.phone ? dependent.phone : dependent.mobilePhone || '-'}</span></CustomGrid.Column>
                                            </CustomGrid.Row>
                                            {!!dependent.invitable ? <CustomGrid.Row fitted={'vertically'}>
                                                    <CustomGrid.Column width={4} className={"neutral900Text"}>
                                                        <SSNDisplay ssn={dependent.socialSecurityNumber} visibilityIconVisible={false}/>
                                                    </CustomGrid.Column>
                                                    <CustomGrid.Column width={8} className={"neutral900Text"}>
                                                        <b>Email address</b><br/>
                                                        <span>{dependent.email || '-'}</span>
                                                    </CustomGrid.Column>
                                                </CustomGrid.Row> :
                                                <CustomGrid.Row fitted={'vertically'}>
                                                    <CustomGrid.Column width={4} className={"neutral900Text"}>
                                                        <SSNDisplay ssn={dependent.socialSecurityNumber} visibilityIconVisible={false}/>
                                                    </CustomGrid.Column>
                                                    <CustomGrid.Column width={5}/>
                                                    <CustomGrid.Column width={3}/>
                                                </CustomGrid.Row>
                                            }
                                            <CustomDivider small hidden/>
                                            <CustomGrid.Column width={12}><p className={"small"}>If anything is incorrect or
                                                needs to be updated, call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.</p></CustomGrid.Column>
                                        </CustomGrid>
                                    </CustomContainer>
                                </React.Fragment>
                            )}
                        </CustomGrid.Column>
                    </CustomGrid>
                    <Footer showTopFooter={false} showCenterData={true}/>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyInfo));
