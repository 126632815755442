import React, {Component} from 'react'
import {Checkbox, Divider, Loader, Message, Modal, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {formatDate, resolveErrorMessage} from "../../utils/common";
import {setLeftNavigation} from "../../actions/navigationActions";
import {setEConsentRevoked, setEConsentSigned} from "../../actions/eConsentActions";
import Footer from "../footer";
import PayoutMethods from "../payoutMethods";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {ClickableText, CustomGrid, CustomModalHeader} from "../custom-common";
import {purgeState} from "../../storePersistence";
import Mfa from '../../mfa';
import {generateBackupCodes, getMfaSettings} from "../../actions/authActions";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        user: state.initialData.user,
        userIsCertificateHolder: state.initialData.userIsCertificateHolder,
        mfaEnabled: state.auth.mfaEnabled,
        eConsentData: state.eConsent,
    }
};
class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consentContent: null,
            consented: true,
            consentNoticeError: null,
            loadingConsent: false,
            loadingConsentNotice: null,
            showConfirmModal: false,
            showConsentModal: false,
            updateConsentError: null,
        }
    }

    async componentDidMount() {
        this.props.dispatch(setLeftNavigation(true))
    }

    handleCheckboxChange = async () => {
        const {signed} = this.props.eConsentData;
        if (signed) {
            this.showConfirmModal(true);
        } else {
            await this.updateConsent(true);
        }
    }

    updateConsent = async (value) => {
        this.setState({loadingConsent: true});
        const payload = {eConsent: value};
        try {
            await axios.post("/api/member/v2/updateEConsent", payload, {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(setEConsentSigned(value));
            this.setState({loadingConsent: false});
            return true;
        } catch (e) {
            this.setState({loadingConsent: false, updateConsentError: resolveErrorMessage(e, 'An unexpected error has occurred')});
            return false;
        }
    }

    showConsentModal = async (value) => {
        this.setState({
            showConsentModal: value,
        })
        if (value) await this.getConsentContent();
    }

    async getConsentContent() {
        this.setState({loadingConsentNotice: true})
        try {
            const result = await axios.post("/api/member/v2/prepareConsentNotice");
            this.setState({loadingConsentNotice: false, consentContent: result.data});
        } catch (e) {
            this.setState({loadingConsentNotice: false, consentNoticeError: resolveErrorMessage(e, "It was not possible to load consent notice")});
        }
    }

    showConfirmModal = (value) => {
        this.setState({
            showConfirmModal: value
        })
    }

    revoke = async () => {
        this.showConfirmModal(false);
        const isConsentRevoked = await this.updateConsent(false);
        if (!isConsentRevoked) return;
        try {
            this.setState({loadingConsent: true});
            await axios.post("/api/member/v2/logout", null, {headers: {'Content-Type': 'application/json'}});
        } catch (e) {
            console.error(e);
        } finally {
            this.props.dispatch(setEConsentRevoked());
            purgeState();
        }
    }

    render() {
        const {showConsentModal, showConfirmModal, consentContent, loadingConsent, consentNoticeError, loadingConsentNotice} = this.state;
        const {userIsCertificateHolder, mfaEnabled, eConsentData} = this.props;
        return (
            <React.Fragment>
                {!!showConsentModal && (loadingConsentNotice ? <Loader active/> : <Modal onClose={async (_) => {
                    await this.showConsentModal(false)
                }} open>
                    <CustomModalHeader title={"Electronic consent"} onClose={async (_) => { await this.showConsentModal(false)}} className="customModalHeader" />
                    <Modal.Content>
                        {consentNoticeError ? <Message negative>{consentNoticeError}</Message> :
                            <div dangerouslySetInnerHTML={{__html: consentContent}}></div>}
                    </Modal.Content>
                </Modal>)}
                {!!showConfirmModal &&
                    <Modal onClose={(_) => {this.showConfirmModal(false)}} open={showConfirmModal} className={"revokeConsentModal"}>
                        <CustomModalHeader title={"Confirm removal of electronic consent"} onClose={(_) => {this.showConfirmModal(false)}} className="customModalHeader" />
                        <Modal.Content>
                            <p>Removing electronic consent will prevent you from using the Member Portal and send all future {STRATEGIC_PARTNER.LABEL} communications through mail</p>
                            <Divider hidden/>
                            <CustomContainer basic vertical clearing style={{paddingBottom: 0}}>
                                <Button primary floated={"right"} onClick={this.revoke}>Revoke consent</Button>
                                <Button color={"grey"} basic floated={"right"} onClick={(_) => {this.showConfirmModal(false)}}>Cancel</Button>
                            </CustomContainer>
                        </Modal.Content>
                    </Modal>
                }
                <div className="member-main-container">
                    <CustomGrid container stackable columns={1} centered fitted={'horizontally'}>
                        <CustomGrid.Column>
                            <Divider hidden/><Divider hidden/>
                            <CustomGrid columns={2}>
                                {!!userIsCertificateHolder && <CustomGrid.Column style={{paddingRight: '.5em'}}>
                                    <CustomContainer padded>
                                        <Header as={"h4"}>Claim payment settings</Header>
                                        <PayoutMethods/>
                                    </CustomContainer>
                                </CustomGrid.Column>}
                                <CustomGrid.Column style={{paddingLeft: '.5em'}}>
                                    <CustomContainer padded>
                                        <Header as={"h4"}>Electronic Consent</Header>
                                        {loadingConsent ?
                                            <div><Divider hidden/><Divider hidden/><Loader active/></div> :
                                            <React.Fragment>
                                                <p>
                                                    <ClickableText onClick={async (_) => await this.showConsentModal(true)} small>
                                                        View Electronic Consent notice
                                                    </ClickableText>
                                                </p>
                                                <span className={"small"}><b>Consent to conduct business electronically</b></span>
                                                {!!eConsentData &&
                                                    <Checkbox style={{float: 'right'}}
                                                              toggle
                                                              onChange={this.handleCheckboxChange}
                                                              checked={eConsentData.signed} />
                                                }
                                            </React.Fragment>}
                                    </CustomContainer>
                                    <Mfa mfaShowSettings={true} mfaEnabled={mfaEnabled} setLeftNavigation={setLeftNavigation} getMfaSettings={getMfaSettings} formatDate={formatDate} generateBackupCodes={generateBackupCodes}/>
                                </CustomGrid.Column>
                            </CustomGrid>
                        </CustomGrid.Column>
                    </CustomGrid>
                    <Footer showTopFooter={false} showCenterData={true}/>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Settings));
