import React from 'react'
import moment from 'moment';
import {Grid, Header, Icon} from 'semantic-ui-react';
import IMAGE from "../../constants/images";
import {PAYOUT_METHODS} from "../../payouts";
import {paypal, venmo} from "../../utils/common";

export const allowedPaymentStatuses = [
    'Pending',
    'Processing',
    'Succeeded',
    'Payment Failed'
]

export const paymentStatusLabel = (status, issuedDate) => {
    if (!allowedPaymentStatuses.includes(status)) return '--'

    if (!issuedDate) return status

    if (status === 'Processing' || status === 'Succeeded') {
        const date = moment(issuedDate).format("MMM Do, YYYY")
        return `Issued on ${date}`
    }

    return status
}

export const renderMethod = (method) => {
    if (!method) return '--';

    const payoutMethod = PAYOUT_METHODS[method] ?? {};
    const {label, icon, Component} = payoutMethod ?? {};

    if ((method === paypal || method === venmo) && Component) {
        const image = method === paypal ? IMAGE.PAYPAL_PM_88PX : IMAGE.VENMO_PM_88PX;
        return <Component image={image} />;
    }

    if (!label) return method;

    return <><Icon name={icon}/><span>{label}</span></>
}

export const ClaimPayment = ({ method, status, amount, issuedDate }) => {
    const methodLabel = renderMethod(method)
    const showDepositMessage = (status === "Processing" || status === "Succeeded") && (method === "bank" || method === "stripe");
    const getAmount = () => {
        if (!amount) { return <span style={{ paddingLeft: '1em' }}>-</span> }

        if(amount !== 'Pending') {
            return (
                <Header style={{marginTop: '0.3em'}} as={"h3"} color={"orange"}>
                    ${amount}
                </Header>
            )
        }
        return <span  className="small neutral600Text">{amount}</span>
    }
    return (
        <Grid columns={4}>
            <Grid.Row>
                <Grid.Column width={2}>
                    <Header as={"h4"} color={"orange"}>
                        Payment<br/>details
                    </Header>
                </Grid.Column>
                <Grid.Column textAlign={"left"} width={3}>
                    <p className="small neutral600Text">
                        <b>Payment method</b><br/>
                        {methodLabel}
                    </p>
                </Grid.Column>
                <Grid.Column width={5}>
                    <p className="small neutral600Text">
                        <b>Payment Status</b><br />
                        {paymentStatusLabel(status, issuedDate)}
                        {showDepositMessage && (
                            <>
                                <br />
                                <i className={"smaller"}>
                                *Deposits take 5-10 business days to show in accounts.
                                </i>
                            </>
                        )}
                    </p>
                </Grid.Column>
                <Grid.Column textAlign={"center"} width={2}>
                    <p className="small neutral600Text">
                        <b>Amount</b><br />
                        {getAmount()}
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
