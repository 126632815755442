import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Form, Grid, Button, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import {setHaveStepChildren} from "../../../../actions/planChangeActions";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import QLEModal from "./qleModal";
import {ADD_DEPENDENT_ROUTE, ADD_STEP_CHILDREN_ROUTE, UPDATE_COVERAGE_ROUTE} from "../../../../utils/common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        qleReason: state.planChange.qleReason,
        qleReasonOptions: state.initialData.qleReasonOptions,
        haveStepChildren: state.planChange.haveStepChildren
    }
};

class HaveStepChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    onBackHandler = () => {
        this.props.history.replace(ADD_DEPENDENT_ROUTE);
    }

    handleChange = (e, {value}) => {
        this.props.dispatch(setHaveStepChildren(value))
    }

    onNext = () => {
        if (this.props.haveStepChildren === "yes") {
            this.props.history.push(ADD_STEP_CHILDREN_ROUTE)
        } else {
            this.props.history.push(UPDATE_COVERAGE_ROUTE)
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const {haveStepChildren} = this.props;

        const button = <Button primary content="Next step" disabled={haveStepChildren === null}
                               onClick={this.onNext}/>;

        return (
            <React.Fragment>
                <QLEModal showModal={this.state.showModal} onModalClose={this.closeModal}/>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Header as={"h2"}>Marriage</Header>

                            <p><b>Do you have any new step-children joining your family?</b></p>
                            <Form>
                                <Form.Field>
                                    <Form.Group grouped>
                                        <Form.Radio
                                                key={"yes"}
                                                label={"Yes"}
                                                value={'yes'}
                                                checked={haveStepChildren === 'yes'}
                                                onChange={this.handleChange}
                                            />
                                        <Form.Radio
                                            key={"no"}
                                            label={"No"}
                                            value={'no'}
                                            checked={haveStepChildren === 'no'}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(HaveStepChildren));
