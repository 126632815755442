import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Form, Grid, Input, Modal, Button, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import {setQLEDate} from "../../../../actions/planChangeActions";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import {convertDisplayDateFormatToServer, formatPartialDate, isEvenNumber, resolveQLERoute,} from "../../../../utils/common";
import moment from "moment";
import {CustomModalHeader} from '../../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        qleDate: state.planChange.qleDate,
        qleReason: state.planChange.qleReason
    }
};

class QLEWhen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/change");
    }

    handleChange = (e, {value}) => {
        this.props.dispatch(setQLEDate(value))
    }

    onNext = () => {
        if (this.validateForm() === true) {
            const route = resolveQLERoute(this.props.qleReason)
            this.props.history.push(route)
        } else {
            this.props.history.push("/change/notEligible")
        }
    }

    validateForm = () => {
        let valid = true
        const mNow = moment(Date.now());
        const mDate = moment(this.props.qleDate)
        const dateTofarInThePast = mNow.diff(mDate, "days") > 60 ;

        if (!!dateTofarInThePast) {
            valid = false
        }
        return valid
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const {qleDate} = this.props;
        const {errorMessage, showModal} = this.state;

        const button = <Button primary content="Next step" disabled={!qleDate || qleDate.length === 0 || !!errorMessage}
                               onClick={this.onNext}/>;

        return (
            <React.Fragment>
                <Modal onClose={this.closeModal} open={showModal} size={"small"}>
                    <CustomModalHeader title={"What date should I use?"} onClose={this.closeModal} className="customModalHeader" />
                    <Modal.Content style={{fontSize: '16px'}}>
                        <p>The date you should use depends on the date of your qualifying life event. Some common dates
                            are listed below. If you have questions, reach out to your Brella Concierge.</p>
                        <Divider hidden/>
                        <Grid columns={2}>
                            <Grid.Column width={4} className={"neutral700Text"}>
                                <b>Life event</b>
                            </Grid.Column>
                            <Grid.Column width={8} className={"neutral700Text"}>
                                <b>Date</b>
                            </Grid.Column>
                        </Grid>
                        <Divider/>
                        <Grid columns={2}>
                            <Grid.Column width={4}>
                                <b>Birth or Adoption of Child</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For birth, you should use the date of birth for your new baby. For adoption, use
                                    the date the adoption became finalized.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>Marriage</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For marriage, you should use the date of marriage on the certificate.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>New Domestic Partner</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For domestic partnership, you should use the date your domestic partnership is
                                    established.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>Divorce or Legal Separation</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For domestic partnership, you should use the date your legal separation or
                                    divorce is final.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>Gain of Child Custody</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For the gain of child custody, you should use the date you established child
                                    custody.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>Dissolution of Domestic Partnership</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For dissolution of domestic partnership, you should use the date your domestic
                                    partnership is dissolved.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>Address Change</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For address change, use the move-in date for your new address.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>Death of Spouse or Dependent</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For the death of a spouse or dependent, use the date on the death
                                    certificate.</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <b>Loss of Child Custody</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <p>For the loss of child custody, you should use the date child custody was
                                    revoked.</p>
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                </Modal>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Row>
                            <Grid.Column width={7} textAlign={"center"}>
                                <Header as={"h2"}>When did this happen?</Header>
                                <p className={"neutral700Text"}>We need to know when your life event occured so we can
                                    confirm when you’re eligible to make changes to your plan.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Column width={4} className={"pageContent"}>
                            <Form>
                                <Form.Field error={!!errorMessage}>
                                    <label>Enter the date of your life event—</label>
                                    <Input name={"qleDate"}
                                                value={qleDate || ''}
                                                placeholder="MM/DD/YYYY"
                                                onFocus={() => {
                                                    this.setState({
                                                        focused: true,
                                                        errorMessage: '',
                                                    });
                                                }}
                                                onBlur={() => {
                                                    const parts = qleDate.split("/");
                                                    let errorMessage = '';
                                                    if (parts.length > 3) {
                                                        errorMessage = "Invalid date";
                                                        this.setState({errorMessage: errorMessage});
                                                        return;
                                                    }
                                                    let mm = parts[0];
                                                    let dd = parts[1];
                                                    let yyyy = parts[2];
                                                    const digitMm = parseInt(mm);
                                                    const digitDd = parseInt(dd);

                                                    let invalidDate = false;
                                                    if (digitMm > 12 || digitDd > 31) {
                                                        invalidDate = true;
                                                    } else {
                                                        const isEvenMonth = isEvenNumber(digitMm);
                                                        if (isEvenMonth) {
                                                            if (digitMm === 2 && digitDd > 29) {
                                                                invalidDate = true;
                                                            } else if (digitDd > 30) {
                                                                invalidDate = true;
                                                            }
                                                        } else if (digitDd > 31) {
                                                            invalidDate = true;
                                                        }
                                                    }
                                                    if (invalidDate || !dd || !mm || !yyyy) {
                                                        errorMessage = "Format should be MM/DD/YYYY";
                                                        this.setState({errorMessage: errorMessage});
                                                        return;
                                                    }

                                                    let dirty = false;
                                                    if (mm.length === 1) {
                                                        mm = "0" + mm;
                                                        dirty = true;
                                                    }
                                                    if (dd.length === 1) {
                                                        dd = "0" + dd;
                                                        dirty = true;
                                                    }
                                                    const processedValue = mm + "/" + dd + "/" + yyyy;
                                                    if (dirty) {
                                                        this.handleChange(null, {value: processedValue});
                                                        this.setState({value: processedValue});
                                                    }

                                                    const validMomentFormat = convertDisplayDateFormatToServer(processedValue);
                                                    const mDate = moment(validMomentFormat);
                                                    invalidDate = !mDate.isValid();
                                                    let dateInTheFuture = false;
                                                    let dateTofarInThePast = false;
                                                    if (!invalidDate) {
                                                        const mNow = moment(Date.now());
                                                        dateInTheFuture = mDate.isAfter(mNow);
                                                        dateTofarInThePast = mNow.diff(mDate, "years") > 200;
                                                    }
                                                    const requiredDateLength = processedValue.length === 10;
                                                    if (!requiredDateLength || invalidDate) {
                                                        errorMessage = "Invalid date";
                                                    } else if (dateInTheFuture) {
                                                        errorMessage = "Date can't be in the future";
                                                    } else if (dateTofarInThePast) {
                                                        errorMessage = "Date too far in the past";
                                                    }
                                                    if (errorMessage.length > 0) {
                                                        this.setState({errorMessage: errorMessage});
                                                    }
                                                }}
                                                onChange={(event, {value}) => {
                                                    if (value.length > 1) {
                                                        const lastChar = value[value.length - 1]
                                                        const isDeletingCharacters = !!qleDate && value.length < qleDate.length;
                                                        if (!(lastChar === "/" && isDeletingCharacters)) {
                                                            const reg = /^\d*$/;
                                                            if (!reg.test(lastChar)) {
                                                                return;
                                                            }
                                                        }
                                                    }
                                                    const date = formatPartialDate(value, qleDate);
                                                    this.handleChange(event, {value: date});
                                                    this.setState({errorMessage: ''});
                                                }}
                                    />
                                    {!!errorMessage && errorMessage.length > 0 && <small className={"warningRedText"}>{errorMessage}</small>}
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={12} textAlign={"center"}>
                            <Divider hidden/><Divider hidden/>
                            <p className={"smaller"}>
                                <b>Not sure what date to use?</b><br/>
                                Click <span className={"linkText"} onClick={() => {
                                this.setState({showModal: true})
                            }}>here</span> to learn more.
                            </p>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(QLEWhen));
