import React from "react";
import {bank, check, paycheck, paypal, stripe, venmo} from "../utils/common";
import {Icon} from "semantic-ui-react";
import {S3Image} from "../components/custom-common";
import IMAGE from "../constants/images";


const PaymentMethodDisplay = ({icon, label, image}) => {
    if (image) return <S3Image {...image} />;
    return (
        <div className="neutral700Text">
            <Icon name={icon} size="large"/>
            <span className="paymentMethodLabel"><b>{label}</b></span>
        </div>
    );
};

const PAYOUT_METHODS = Object.freeze({
    [venmo]: {key: venmo, label: 'Venmo', Component: ({image = IMAGE.VENMO_PM_130PX} = {}) => <PaymentMethodDisplay image={image}/>},
    [paypal]: {key: paypal, label: 'Paypal', Component: ({image = IMAGE.PAYPAL_PM_130PX} = {}) => <PaymentMethodDisplay image={image}/>},
    [bank]: {key: bank, label: 'Bank account', icon: 'university', Component: () => <PaymentMethodDisplay icon={"university"} label={"Bank account"}/>},
    [paycheck]: {key: paycheck, label: 'Paper check', icon: 'envelope outline', Component: () => <PaymentMethodDisplay icon={"envelope outline"} label={"Paper check"}/>},
    [check]: {key: check, label: 'Paper check', icon: 'envelope outline'},
    [stripe]: {key: stripe, label: 'Bank account', icon: 'university'},
});

const getElectronicPayouts = () => [PAYOUT_METHODS.venmo, PAYOUT_METHODS.paypal, PAYOUT_METHODS.bank];

export {
    PAYOUT_METHODS, getElectronicPayouts,
}
