import React, {Component} from "react"
import {Grid, Button, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import Footer from "../footer";
import {setPrevRoute} from "../../actions/navigationActions";
import {connect} from "react-redux";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {S3Image} from "../custom-common";
import IMAGE from "../../constants/images";

const mapStateToProps = (state, ownProps) => {
    return {keepCoverage: state.anualOpenEnrollment.keepCoverage, ...ownProps}
}

class ThanksForReview extends Component {

    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    next = () => {
        this.props.history.push(this.props.nextRoute)
    }

    render() {
        const button = <Button primary content="Next step"
                               onClick={this.next}/>;
        return (
            <React.Fragment>
                <div className="member-main-container" style={{background: 'white'}}>
                        <Grid container stackable columns={1} style={{margin: '5em'}}>
                            <Grid.Row >
                                <Grid.Column textAlign={'center'} >
                                <S3Image {...IMAGE.CHECK_CIRCLE_OUTLINE_67PX}/>
                                    <Header as='h2'>Success!</Header>
                                    <p>Thanks for reviewing your personal information and family members.<br/>
                                    Now let’s adjust your {STRATEGIC_PARTNER.LABEL} coverage—</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    <Footer showTopFooter showCenterData button={button}/>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ThanksForReview));
