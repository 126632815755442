import React from 'react';
import {Modal, Button} from "semantic-ui-react";
import {CustomModalHeader} from '../custom-common';

const ChangeDefaultPaymentMethodModal = (props) => {
    const {show, close, setDefault, paymentMethod} = props;

    const handleSubmit = () => {
        setDefault(paymentMethod.stripeId);
        close();
    };

    return (
        <Modal size={'tiny'} onClose={close} open={show} style={{maxWidth: '502px'}}>
            <CustomModalHeader title={"Change default payment method?"} onClose={close} className="customModalHeader" />
            <Modal.Content style={{overflowY: 'auto'}}>
                Your new default payment method will be used for all automatic payments moving forward. Are you sure you want to change your default payment method?
                <div style={{marginTop: '30px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button color='grey' basic onClick={close}>Cancel</Button>
                    <Button primary onClick={handleSubmit}>Confirm change</Button>
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default ChangeDefaultPaymentMethodModal;
