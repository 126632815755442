import React, {Component} from 'react'
import {NavLink, withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Divider, Grid, Button, Header} from "semantic-ui-react";
import Footer from "../../footer";
import {connect} from "react-redux";
import {setLeftNavigation} from "../../../actions/navigationActions";
import {S3Image} from "../../custom-common";
import ClaimStepsLayout from "../claimStepsLayout";
import IMAGE from "../../../constants/images"

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        claimant: state.newClaim.claimant,
    }
};

class Interstitial extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }
    }

    componentDidMount() {
        this.props.dispatch(setLeftNavigation(false))
    }

    onBackHandler = () => {
        this.props.history.replace('/plan');
    }

    render() {
        const {member} = this.props;
        const button = (<Button primary content="Next step" as={NavLink}
                                to={{pathname: "/claims/new/whatHappened", member: member}}/>)
        return (
            <React.Fragment>
                <NewClaimMenu onBack={this.onBackHandler} user={member}/>
                <ClaimStepsLayout>
                    <Grid container stackable columns={1} centered style={{marginTop: 0}}>
                        <Grid.Column width={8} className={"pageContent"} style={{paddingTop: '80px'}}>
                            <Header as={"h2"}>We make claims easy</Header>
                            <p className={"neutral700Text"}>
                                Goodbye to paper claim forms and long processing times. We make it as easy as possible to file a claim so you're paid in hours, not weeks.
                            </p>
                            <Divider hidden/>
                            <Grid>
                                <Grid.Row className="interstitialImageContainer" columns={4}>
                                    <Grid.Column>
                                        <S3Image {...IMAGE.WHAT_HAPPENED}/>
                                        <p className="small"><b>Tell us what<br/>happened</b></p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <S3Image {...IMAGE.UPLOAD_DOCS}/>
                                        <p className="small"><b>Upload photos or<br/>documents</b></p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <S3Image {...IMAGE.SELECT_PAYOUT_PREFERENCE}/>
                                        <p className="small"><b>Set your payment<br/>preference</b></p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <S3Image {...IMAGE.CLAIM_ANALYZED} />
                                        <p className="small"><b>We’ll analyze your<br/>claim</b></p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid stackable className="interstitialOrText">
                                <Grid.Column width={6} textAlign={"center"} verticalAlign={'middle'}>
                                    <p className="normal">If your claim is approved, we’ll pay it in less than 72
                                        hours.</p>
                                </Grid.Column>

                                <Grid.Column textAlign={"center"} className={'interstitialColumns'} only={'large screen widescreen'}>
                                    <Divider className="small" vertical>OR</Divider>
                                </Grid.Column>

                                <Grid.Column textAlign={"center"} className={'interstitialColumns'} only={'tablet computer'}>
                                    <Divider className="small" vertical>OR</Divider>
                                </Grid.Column>

                                <Grid.Column width={1} textAlign={"center"} only={'mobile'}>
                                    <Divider className="small" horizontal>OR</Divider>
                                </Grid.Column>

                                <Grid.Column width={6} verticalAlign={"middle"} textAlign={"center"}>
                                    <p className="normal">Otherwise, our claims team will reach out to you regarding next steps.</p>
                                </Grid.Column>

                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <div style={{marginTop: "100px"}}>
                        <Footer showCenterData showTopFooter button={button}/>
                    </div>
                </ClaimStepsLayout>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Interstitial));
