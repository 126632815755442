import {eConsentActionTypes} from "./actionTypes";

export function setEConsentSigned(payload) {
    return {
        type: eConsentActionTypes.SET_ECONSENT_SIGNED,
        payload
    }
}

export function setEConsentRevoked() {
    return {
        type: eConsentActionTypes.SET_ECONSENT_REVOKED,
    }
}