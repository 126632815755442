import React from "react";

import {Table, Header} from "semantic-ui-react";
import {CustomDivider} from "../../custom-common";
import {connect} from "react-redux";
import CategoryItem from "./categoryItem";

const mapStateToProps = ({ initialData: { benefitLimits: { title, subtitle, categoryItems, secondColumnName } } }, ownProps) => ({
    ...ownProps,
    title,
    subtitle,
    categoryItems,
    secondColumnName,
});

const BenefitLimits = ({ categoryItems, secondColumnName }) => {
    const [categoryItemSelected, setCategoryItemSelected] = React.useState('');

    return <>
        <Header as={"h3"}>Benefit limits</Header>
        <p>Benefits are paid if the insured is diagnosed with a covered condition. Members may receive a benefit more than once as noted below.</p>
        <CustomDivider hidden/>
        <Table basic={"very"}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>{ secondColumnName }</Table.HeaderCell>
                    <Table.HeaderCell/>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                { categoryItems?.map((categoryItem, index) =>
                    <CategoryItem
                        key={index}
                        {...categoryItem}
                        categoryItemSelected={categoryItemSelected}
                        setCategoryItemSelected={setCategoryItemSelected}
                    />
                )}
            </Table.Body>
        </Table>
    </>
}

export default connect(mapStateToProps)(BenefitLimits);
