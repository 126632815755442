import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Form, Grid, Icon, Input, Loader, Message, Modal, Popup, Segment, Select, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {connect} from "react-redux";
import ErrorInput from "../errorInput";
import {setLeftNavigation} from "../../actions/navigationActions";
import {toggle as toggleGetHelpModal} from '../../actions/getHelpActions';
import axios from "axios";
import NumberFormat from "react-number-format";
import {convertDisplayDateFormatToServer, convertServerDateFormatToDisplay, resolveErrorMessage} from "../../utils/common";
import {ClickableText, DOBInput, CustomModalHeader, CustomEmailInput} from "../custom-common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        member: state.newClaim.member,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLeftNavigation: state => dispatch(setLeftNavigation(state)),
        toggleGetHelpModal: state => dispatch(toggleGetHelpModal(state)),
    }
};

class Beneficiaries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            validationErrorsMap: new Map(),
            beneficiaries: [],
            firstName: null,
            middleName: null,
            lastName: null,
            suffix: null,
            phoneNumber: null,
            birthDate: null,
            ssn: null,
            relationship: null,
            relationshipOther: null,
            typeOfEntity: "Person",
            street: null,
            apt: null,
            zipCode: null,
            city: null,
            selectedState: null,
            country: null,
            displayInfoModal: false,
            forceInfoPopUpOpen: false,
            infoPopUpOpen: false,
            relationshipOptions: [],
            countryOptions: [],
            stateOptions: [],
            typesOfEntities: [],
            loadError: null,
            submitError: null,
            percentage: 100,
            showAddModal: false,
            showEditModal: false,
            suffixes: [],
            email: null,
        }
    }

    componentDidMount() {
        const {setLeftNavigation} = this.props

        setLeftNavigation(true)
        this.loadData();
    }

    loadData = async() => {
        try {
            this.setState({loading: true})
            const result = await axios.post("/api/member/v2/prepareBeneficiaries", null, {headers: {'Content-Type': 'application/json'}});
            this.setState({
                loading: false,
                beneficiaries: result.data.beneficiaries,
                relationshipOptions: result.data.relationships,
                countryOptions: result.data.countries,
                stateOptions: result.data.states,
                typesOfEntities: result.data.typesOfEntities,
                suffixes: result.data.suffixes
            })
        } catch (e) {
            this.setState({loading: false, loadError: resolveErrorMessage("An unexpected error occurred.")})
        }
    }

    handleFormChange = (e, {name, value}) => {
        this.setState({[name]: value})
    }

    submit = async () => {
        const error = this.validate();
        if (!error) {
            try {
                this.setState({loading: true})
                const payload = {
                    beneficiaryId: this.state.beneficiaryId,
                    percentage: this.state.percentage,
                    firstName: this.state.firstName,
                    middleName: this.state.middleName,
                    lastName: this.state.lastName,
                    suffix: this.state.suffix,
                    phoneNumber: this.state.phoneNumber,
                    birthdate: !!this.state.birthDate ? convertDisplayDateFormatToServer(this.state.birthDate) : null,
                    ssn: this.state.ssn,
                    relationship: this.state.relationship,
                    relationshipOther: this.state.relationshipOther,
                    typeOfEntity: this.state.typeOfEntity,
                    address: {
                        zipCode: this.state.zipCode,
                        street: this.state.street,
                        state: this.state.selectedState,
                        country: this.state.country,
                        city: this.state.city
                    },
                    email: this.state.email
                }
                await axios.post("/api/member/v2/submitBeneficiary", payload, {headers: {'Content-Type': 'application/json'}});
                this.setState({
                    loading: false,
                    showAddModal: false,
                    showEditModal: false
                })
                this.loadData();
            } catch (e) {
                this.setState({loading: false, submitError: resolveErrorMessage("An unexpected error occurred.")})
            }
        }
    }

    validate = () => {
        const required = ['firstName', 'lastName', 'relationship', 'street', 'city', 'zipCode', 'selectedState', 'country', 'typeOfEntity', 'phoneNumber'];
        let error = false;

        for (let i = 0; i < required.length; i++) {
            let item = required[i];
            if (!this.state[item] || this.state[item] === null || this.state[item].length === 0) {
               // this.setError(item, "Required");
                error = true
                break
            }
        }
        if (this.state.validationErrorsMap.length > 0) {
            error = true
        }

        return error
    }

    setError = (key, msg) => {
        const {validationErrorsMap} = this.state;
        validationErrorsMap.set(key, msg)
        this.setState({
            validationErrorsMap,
        })
    }

    getError = (key) => {
        const vem = this.state.validationErrorsMap;
        if (!!vem) {
            return vem.get(key);
        }
        return null;
    }

    closeModal = () => {
        this.setState({
            showAddModal: false,
            showEditModal: false
        })
    }

    edit = (b) => {
        this.setState({
            beneficiaryId: b.beneficiaryId,
            showEditModal: true,
            firstName: b.firstName,
            middleName: b.middleName,
            lastName: b.lastName,
            suffix: b.suffix,
            phoneNumber: b.phoneNumber,
            birthDate: !!b.birthdate ? convertServerDateFormatToDisplay(b.birthdate) : null,
            ssn: b.ssn,
            relationship: b.relationship,
            relationshipOther: b.relationshipOther,
            street: b.address.street,
            zipCode: b.address.zipCode,
            city: b.address.city,
            selectedState: b.address.state,
            country: b.address.country,
            email: b.email,
            typeOfEntity: b.typeOfEntity
        })

    }

    render() {
        const {beneficiaries, loading, loadError, showAddModal, showEditModal, submitError} = this.state;
        const {toggleGetHelpModal} = this.props;
        const infoPopUpText = "In the event of death, who do you want the money to go to? Primary beneficiaries are the recipient of the benefits should the insured die. You can always update or change this later."
        const error = this.validate();

        return (
            <React.Fragment>
                {(!!showAddModal || !!showEditModal) && <Modal size={'small'} onClose={this.closeModal} open={showAddModal || showEditModal}>
                    <CustomModalHeader title={"Add beneficiary"} onClose={this.closeModal} className="customModalHeader" />
                    <Modal.Content>
                        {this.renderForm(infoPopUpText)}
                        {!!submitError && <Message negative>{submitError}</Message>}
                        <Divider hidden/>
                        <Segment basic vertical clearing style={{paddingBottom: 0}}>
                            <Button primary floated={"right"} loading={loading} onClick={this.submit} disabled={error || loading}>Save</Button>
                            <Button color={"grey"} basic floated={"right"} disabled={loading}
                                    onClick={this.closeModal}>Cancel</Button>
                        </Segment>
                    </Modal.Content>
                </Modal>}
                {loading ? <div style={{height: 'calc(100vh - 150px)'}}><Loader active/></div> :
                    loadError ? <CustomContainer basic><Message
                        negative>{loadError}</Message></CustomContainer> : !!beneficiaries && beneficiaries.length > 0 ?
                        <React.Fragment>
                            <Divider hidden/>
                            <p className={"small"}>It's important to keep your beneficiary designations up to date as
                                your life
                                changes (marriage, children, divorce, etc.).
                                To add or remove beneficiaries <ClickableText onClick={() => toggleGetHelpModal(true)}>send us a message</ClickableText> or call (888) 300-5382.</p>
                            <Divider hidden/>
                            <Header as={"h4"}>Primary beneficiaries
                                <Popup position={'top left'} wide
                                       className={'greenPopup'}
                                       on={'hover'}
                                       style={{cursor: 'pointer'}}
                                       trigger={
                                           <Icon.Group
                                               style={{color: '#616161', fontSize: '16px', marginLeft: '8px'}}>
                                               <Icon name='circle outline'
                                                     style={{fontSize: '1.3em', margin: '-2px 2px 0 0'}}/>
                                               <Icon name='info'
                                                          style={{fontSize: '0.7em', margin: '-3px 3px 0 -1px'}}/>
                                                </Icon.Group>
                                       }
                                ><span className={"smaller"}>{infoPopUpText}</span></Popup>
                            </Header>
                            {beneficiaries.map((b, i) => <CustomContainer key={i}>
                                <Grid columns={4}>
                                    <Grid.Row>
                                        <Grid.Column width={4}>
                                            <b>Name</b><br/>
                                            <span
                                                className={"vibranBlueText"}><b>{b.firstName} {b.middleName} {b.lastName} {b.suffix ? ',' + b.suffix : null}</b></span>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <b>Relationship to you</b><br/>
                                            <span>{b.relationship}</span>
                                        </Grid.Column>
                                        <Grid.Column
                                            width={3}><b>Allocation</b><br/><span>{b.percentage}%</span></Grid.Column>
                                        <Grid.Column width={1}><span className={"linkText"} onClick={()=>{this.edit(b)}}>Edit</span></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={4}>
                                            <b>Date of birth</b><br/>
                                            <span>{!!b.birthdate ? convertServerDateFormatToDisplay(b.birthdate) : '-'}</span>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <b>Address</b><br/>
                                            <span>{b.address.street}, {b.address.city}, {b.address.state} {b.address.zipCode}</span>
                                        </Grid.Column>
                                        <Grid.Column
                                            width={3}><b>Phone</b><br/><NumberFormat displayType={'text'} format={"(###) ###-####"} value={b.phoneNumber || '-'}/></Grid.Column>
                                        <Grid.Column width={1}/>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={4}>
                                            <b>Social Security Number</b><br/>
                                            <NumberFormat value={b.ssn || '-'} displayType={'text'} format={"###-##-####"}/>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <b>Email address</b><br/>
                                            <span>{b.email || '-'}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Column width={12}><p className={"small"}>This person will receive 100% of the
                                        benefit. If you'd like to designate company beneficiaries, multiple
                                        beneficiaries or secondary beneficiaries. Questions? <ClickableText
                                            onClick={() => toggleGetHelpModal(true)}>Send us a
                                            message</ClickableText> or call (888) 300-5382.</p></Grid.Column>
                                </Grid>
                            </CustomContainer>)}
                        </React.Fragment> :
                        <Segment style={{paddingTop: '8em', paddingBottom: '8em'}} textAlign={"center"}>

                            <Header as={"h4"} className={"neutral600Text"}>You have not designated any beneficiaries</Header>
                            <Divider hidden/>
                            <Button primary onClick={()=>{this.setState({showAddModal: true})}}>Add new beneficiary</Button>

                        </Segment>
                }
            </React.Fragment>
        )
    }

    renderForm = (infoPopUpText) => {
        const {percentage, relationshipOther, firstName, middleName, lastName, suffix, phoneNumber, birthDate, ssn, relationship, street, suffixes, zipCode, city, selectedState, country, email, countryOptions, stateOptions, relationshipOptions, typeOfEntity} = this.state;
        return <Form>
            <Header as={"h4"}>Primary beneficiary<Popup position={'top left'} wide
                                                        on={'hover'}
                                                        style={{cursor: 'pointer'}}
                                                        trigger={
                                                            <Icon.Group
                                                                style={{color: '#616161', fontSize: '16px', marginLeft: '8px'}}>
                                                                <Icon name='circle outline'
                                                                      style={{fontSize: '1.3em', margin: '-2px 2px 0 0'}}/>
                                                                <Icon name='info'
                                                                      style={{fontSize: '0.7em', margin: '-3px 3px 0 -1px'}}/>
                                                            </Icon.Group>
                                                                        }
            ><span className={"smaller"}>{infoPopUpText}</span></Popup>
            </Header>
            <p className={"small"}>If you’d like to designate multiple primary beneficiaries, a Trust or
                Non-profit, or contingent beneficiaries please <ClickableText
                    onClick={() => toggleGetHelpModal(true)}>reach out to
                    Concierge</ClickableText>.
            </p>
            <Divider hidden/>
            <Form.Field width={6} readOnly>
                <label>This person will receive the following %</label>
                <Input style={{width: '70px'}} disabled value={percentage + "%"}/>
            </Form.Field>
            <Form.Group widths={"equal"}>
                <Form.Field required>
                    <label>First name</label>
                    <ErrorInput required value={firstName || ''} name={"firstName"}
                                onChange={this.handleFormChange}/>
                </Form.Field>
                <Form.Field>
                    <label>Middle name</label>
                    <ErrorInput value={middleName || ''} name={"middleName"}
                                onChange={this.handleFormChange}/>
                </Form.Field>
            </Form.Group>
            <Form.Group widths={"equal"}>
                <Form.Field required>
                    <label>Last name</label>
                    <ErrorInput required value={lastName || ''} name={"lastName"}
                                onChange={this.handleFormChange}/>
                </Form.Field>
                <Form.Field>
                    <label>Suffix</label>
                    <Select value={suffix || ''}
                            name={"suffix"}
                            options={suffixes}
                            onChange={this.handleFormChange}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths={"equal"}>
                <Form.Field required>
                   <NumberFormat format="(###) ###-####" placeholder="(XXX) XXX-XXXX"
                                 onFocus={() => {
                                     if (!!this.getError('phoneNumber')) {
                                         this.setError('phoneNumber', null);
                                     }
                                 }}
                                 required fluid
                                 customInput={Form.Input}
                                 label={"Phone number"}
                                 value={phoneNumber || ''}
                                 name={"phoneNumber"}
                                 onBlur={(e) => {
                                     const value = e.target.value;
                                     if (!value || value.trim().length < "(###) ###-####".length) {
                                         this.setError('phoneNumber', 'Invalid phone number');
                                     }
                                     this.handleFormChange(e, {
                                         name: 'phoneNumber',
                                         value
                                     })
                                 }}
                   />
                    {!!this.getError('phoneNumber') && <small
                        className={"warningRedText"}>{this.getError('phoneNumber')}</small>}
                </Form.Field>
                <DOBInput value={birthDate || ''} fluid
                             name={"birthDate"}
                             label={"Date of birth"}
                             setFormError={(msg) => {this.setError("birthDate", msg)}}
                             onFocus={() => {
                                 if (!!this.getError("birthDate")) {
                                     this.setError("birthDate", null);
                                 }
                             }}
                             onChange={(e, { value }) => {
                                this.handleFormChange(e, {
                                    name: "birthdate",
                                    value: value,
                                });
                             }}
                             onBlur={() => {}}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <Form.Field>
                    <NumberFormat fluid label={"Social Security Number"}
                                  format="###-##-####"
                                  placeholder="XXX-XX-XXXX"
                                  customInput={Form.Input}
                                  onChange={(e)=>{
                                      const value = e.target.value;
                                      this.handleFormChange(e, {name:"ssn", value: value});
                                  }}
                                  error={!!this.getError('ssn')}
                                  onFocus={() => {
                                      if (!!this.getError('ssn')) {
                                          this.setError('ssn', null);
                                      }
                                  }}
                                  value={ssn}
                                  onBlur={(e) => {
                                      const value = e.target.value;
                                      this.handleFormChange(e, {
                                          name: 'ssn',
                                          value
                                      })
                                  }}
                    />
                    {!!this.getError('ssn') && <small
                        className={"warningRedText"}>{this.getError('ssn')}</small>}
                </Form.Field>
                <Form.Field required>
                    <label>Relationship to you</label>
                    <Select value={relationship}
                            name={"relationship"}
                            options={relationshipOptions}
                            onChange={this.handleFormChange}
                    />
                    {!!this.getError('relationship') && <small
                        className={"warningRedText"}>{this.getError('relationship')}</small>}
                </Form.Field>
            </Form.Group>
            {relationship === "Other" && <Form.Group widths={"equal"}>
                <Form.Field/>
                <Form.Field required>
                    <Input value={relationshipOther || ''}
                           name={"relationshipOther"} onChange={this.handleFormChange}
                    />
                </Form.Field>
            </Form.Group>}
            <Form.Group>
                <CustomEmailInput
                    width={12}
                    value={email || ''} name={"email"}
                    onChange={this.handleFormChange}
                    label={email}
                />
                <Form.Field required>
                    <label>Type of entity</label>
                    <Input value={typeOfEntity} disabled
                            name={"typeOfEntity"}
                    />
                </Form.Field>
            </Form.Group>
            <Divider hidden/>
            <Header as={"h4"}>Address</Header>
            <Form.Group widths={"equal"}>
                <Form.Field required>
                    <label>Country</label>
                    <Select value={country}
                            name={"country"}
                            options={countryOptions}
                            onChange={this.handleFormChange}
                            search
                    />
                    {!!this.getError('country') && <small
                        className={"warningRedText"}>{this.getError('country')}</small>}
                </Form.Field>
                <Form.Field required>
                    <label>State/province/region</label>
                    {country === "United States of America" ? <React.Fragment>
                        <Select value={selectedState}
                                name={"selectedState"}
                                options={stateOptions}
                                onChange={this.handleFormChange}
                                search
                        />
                        {!!this.getError('selectedState') && <small
                            className={"warningRedText"}>{this.getError('selectedState')}</small>}
                    </React.Fragment> : <ErrorInput required value={selectedState || ''} name={"selectedState"}
                                                    onChange={this.handleFormChange}/>
                    }
                </Form.Field>
            </Form.Group>
            <Form.Group widths={"equal"}>
                <Form.Field required>
                    <label>Street address</label>
                    <ErrorInput required value={street || ''} name={"street"}
                                onChange={this.handleFormChange}/>
                </Form.Field>
                <Form.Field required>
                    <label>City</label>
                    <ErrorInput required value={city || ''} name={"city"}
                                onChange={this.handleFormChange}/>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field required width={6}>
                    <label>Postal code/Zip</label>
                    <ErrorInput required value={zipCode || ''} name={"zipCode"}
                                onChange={this.handleFormChange}/>
                </Form.Field>
            </Form.Group>
        </Form>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Beneficiaries));
