import React, {Component} from "react"
import {Divider, Grid, Button, Header} from "semantic-ui-react";
import {NavLink, withRouter} from "react-router-dom";
import {getLocalization, interpolateString, parseMultilineString} from "../../utils/localization";
import {connect} from "react-redux";
import {setPrevRoute} from "../../actions/navigationActions";
import Footer from "../footer";
import moment from "moment";
import {S3Image} from "../custom-common";
import IMAGE from "../../constants/images";

const mapStateToProps = (state, ownProps) => {
    const {user, initialData, anualOpenEnrollment} = state;
    return {
        ...ownProps,
        lang: user.lang,
        initialData,
        user,
        anualOpenEnrollment,
        name: anualOpenEnrollment.firstName,
        upcomingPolicyEffectiveDate: state.anualOpenEnrollment.generalInitialData.upcomingPolicyEffectiveDate,
    }
};

class Success extends Component {
    componentDidMount() {
        this.props.dispatch(setPrevRoute(this.props.prevRoute));
    }

    render() {
        const localization = getLocalization(this.props.lang);
        const strings = localization.success;
        const button = (<Button primary content={"Return to my account"} as={NavLink} to={'/plan'}/>)

        return (
            <React.Fragment>
                <div className="member-main-container" style={{background: 'white'}}>
                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Grid stackable container style={{marginTop: '5em'}} textAlign={'left'}>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <S3Image {...IMAGE.SUCCESS_THANK_YOU}/>
                                    </Grid.Column>
                                    <Grid.Column textAlign={"center"}>
                                        <Header as='h1'>{interpolateString(strings.title, [this.props.name])}</Header>
                                        <p>{strings.description}</p>
                                        <p><b>{strings.yourChanges} {moment(this.props.upcomingPolicyEffectiveDate).format("MMM DD, YYYY")}</b></p>
                                        <p>{parseMultilineString(strings.lookOut)}</p>
                                    </Grid.Column>
                                    <Grid.Column textAlign={"center"}>
                                        <Divider hidden/>
                                        <Header as='h4' style={{marginBottom: '.5em'}}>{strings.allSet}</Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </div>
                <Footer showTopFooter showCenterData button={button} largeButton/>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Success));
