import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Button, Dropdown, Grid, Icon, Loader, Message, Modal, Radio, Table, Dimmer} from "semantic-ui-react";
import {connect} from "react-redux";
import axios from "axios";
import {bank, paycheck, paypal, resolveErrorMessage, venmo} from "../../utils/common";
import {setSelectedPayoutMethod} from "../../actions/newClaimActions";
import {getElectronicPayouts, PAYOUT_METHODS} from "../../payouts";
import DefaultPill from "../defaultPill";
import RenderPaypalConfigForm from './paypal';
import RenderVenmoConfigForm from './venmo';
import RenderBankConfigForm from './bank';
import {ClickableText, CustomModalHeader} from "../custom-common";


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        claimant: state.newClaim.claimant,
        selectedPayoutMethod: state.newClaim.selectedPayoutMethod
    }
};

class PayoutMethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultPayoutMethod: null,
            configuredElectronicPayoutMethods: [],
            loading: this.props.pmLoading ? this.props.pmLoading : false,
            showModal: false,
            loadError: false,
            cellNumber: null,
            confirmCellNumber: null,
            username: null,
            confirmUsername: null,
            firstName: null,
            lastName: null,
            accountNumber: null,
            routingNumber: null,
            configurePm: null,
            validationErrorsMap: new Map(),
            paperCheck: null,
            submittedPayoutMethod: this.props.selectedPayoutMethod ? this.props.selectedPayoutMethod : null,
            preparingConfigureBankAccountView: false,
            loadingConnectedAccountError: null,
            loadingSinglePM: false
        }
    }

    componentDidMount() {
        const setDefaultAsSelected = !this.state.submittedPayoutMethod
        this.loadPayoutMethods(setDefaultAsSelected, false);
    }

    selectPM = (payoutMethod = this.getPaycheckPayoutMethod()) => {
        this.props.dispatch(setSelectedPayoutMethod(payoutMethod));
    }

    setLastPayoutMethodAddedAsSelected(configuredPayoutMethods, submittedPayoutMethod) {

        if(configuredPayoutMethods){
            const defaultPayoutMethod = configuredPayoutMethods[0];

            if (!submittedPayoutMethod) {
                return defaultPayoutMethod;
            }

            return configuredPayoutMethods.find(payoutMethod => {
                if (submittedPayoutMethod.method === venmo) {
                    const cellNumber = payoutMethod.label;
                    const submittedPayoutMethodId = submittedPayoutMethod.id ? submittedPayoutMethod.label : submittedPayoutMethod.externalId
                    return cellNumber === submittedPayoutMethodId;
                }

                if (submittedPayoutMethod.method === bank || submittedPayoutMethod.externalId === payoutMethod.externalId) {
                    const accountNumberEnd = payoutMethod.label.slice(-4);
                    const submittedAccountNumberEnd = submittedPayoutMethod.id ? submittedPayoutMethod.label?.slice(-4) : submittedPayoutMethod.externalId?.slice(-4);
                    return accountNumberEnd === submittedAccountNumberEnd || submittedPayoutMethod.externalId === payoutMethod.externalId;
                }

                if (submittedPayoutMethod.method === paypal) {
                    const submittedPayoutMethodId = submittedPayoutMethod.id ? submittedPayoutMethod.label : submittedPayoutMethod.externalId
                    return payoutMethod.label === submittedPayoutMethodId;
                }

                if (payoutMethod.method !== submittedPayoutMethod.method) {
                    return false;
                }

                if (!payoutMethod.label) {
                    return false;
                }

                return payoutMethod.label === submittedPayoutMethod.externalId;
            }) || defaultPayoutMethod;
        }
    }


    loadPayoutMethods = async (setDefaultAsSelected = false, setPaycheckAsSelected = false) => {
        try {
            this.setState({ showModal: false, configPm: null });
            const { submittedPayoutMethod } = this.state;
            this.initBankPayoutTargetForm();

            const { data } = await axios.post(
                "/api/member/v2/listConfiguredPayoutMethods",
                null,
                { headers: { "Content-Type": "application/json" } }
            );

            const {
                defaultPayoutMethod,
                electronicPayoutMethodList: configuredElectronicPayoutMethods,
            } = data;

            if(submittedPayoutMethod && submittedPayoutMethod.method !== paycheck){
                const selectedPayoutMethod = this.setLastPayoutMethodAddedAsSelected(configuredElectronicPayoutMethods, submittedPayoutMethod);
                this.selectPM(selectedPayoutMethod);
            } else if (setPaycheckAsSelected || submittedPayoutMethod?.method === paycheck) {
                this.selectPM(this.getPaycheckPayoutMethod());
            } else if (setDefaultAsSelected || defaultPayoutMethod) {
                this.selectPM(defaultPayoutMethod)
            } else {
                const selectedPayoutMethod = this.setLastPayoutMethodAddedAsSelected(configuredElectronicPayoutMethods, submittedPayoutMethod);
                this.selectPM(selectedPayoutMethod);
            }

            this.setState({
                defaultPayoutMethod,
                configuredElectronicPayoutMethods: configuredElectronicPayoutMethods || [],
                loading: false,
                loadingSinglePM: false,
                loadError: false,
                paperCheck: paycheck,
            });
            this.isButtonDisabled(false)
        } catch (error) {
            console.warn(error);
            const errorMessage = resolveErrorMessage(
                error,
                "An unexpected error occurred."
            );
            this.setState({ loading: false, loadError: errorMessage });
        }
    };

    setDefault = async (payoutMethod) => {
        this.setState({ loading: true });
        try {
            const setPaycheckAsDefault = payoutMethod.method === "paycheck";
            await axios.post(
                "/api/member/v2/configureDefaultPayoutTarget",
                payoutMethod,
                { headers: { "Content-Type": "application/json" } }
            );
            await this.loadPayoutMethods(true, setPaycheckAsDefault);
            this.setState({ loading: false });
        } catch (error) {
            console.warn(error);
            const errorMessage = resolveErrorMessage(
                error,
                "An unexpected error occurred."
            );
            this.setState({ loading: false, submitError: errorMessage });
        }
    };

    closeModal = () => {
        this.initBankPayoutTargetForm()
    }

    initBankPayoutTargetForm = () => {
        this.setState({
            showModal: false,
            cellNumber: null,
            confirmCellNumber: null,
            validationErrorsMap: new Map(),
            loadingSinglePM: false,
            username: null,
            confirmUsername: null,
            accountNumber: null,
            routingNumber: null,
            defaultPayoutMethod: null,
            loadError: false,
            configurePm: null,
            paperCheck: null,
            submittedPayoutMethod: null,
            submitError: null,
            firstName: null,
            lastName: null,
        })
    }

    getPaycheckPayoutMethod = () => ({id: null, externalId: null, contactId: null, method: paycheck});


    handleChange = (e, {value, name}) => {
        this.setState({
            submitError: false,
            [name]: value
        })
    }

    getError = (key) => {
        const vem = this.state.validationErrorsMap;
        if (!!vem) {
            return vem.get(key);
        }
        return null;
    }

    setError = (key, msg) => {
        const {validationErrorsMap} = this.state;
        if (msg) {
            validationErrorsMap.set(key, msg);
        } else {
            validationErrorsMap.delete(key);
        }
        this.setState({
            validationErrorsMap,
        })
    }

    configureVenmo = async () => {
        const placeholderData = {
            contactId: null,
            externalId: this.state.cellNumber,
            label: this.state.cellNumber,
            id: null,
            method: venmo,
            placeholder: true,
        };
        try {
            let payload = {cellNumber: this.state.cellNumber}
            this.isButtonDisabled(true)
            this.setState(prevState => ({
                loadingSinglePM: true,
                submittedPayoutMethod: placeholderData,
                configuredElectronicPayoutMethods: [placeholderData , ...prevState.configuredElectronicPayoutMethods]
            }));
            await axios.post("/api/member/v2/configureVenmoPayoutTarget", payload, {headers: {'Content-Type': 'application/json'}});
            await this.loadPayoutMethods();
            this.setState({cellNumber: null, confirmCellNumber: null})
        } catch (e) {
            console.warn(e)
            this.isButtonDisabled(false)
            this.setState((prevState) => ({
              loadingSinglePM: false,
              submitError: resolveErrorMessage(e,"An unexpected error occurred."),
              submittedPayoutMethod: null,
              cellnumber: null,
              confirmCellNumber: null,
              configuredElectronicPayoutMethods:
                prevState.configuredElectronicPayoutMethods.filter(
                  (method) => method.placeholder !== true
                ),
            }));
        }
    }

    configureBank = async () => {
        this.setState({loading: true})
        const {firstName, lastName, accountNumber, routingNumber} = this.state;
        const placeholderData = {
            contactId: null,
            externalId: accountNumber,
            label: accountNumber,
            id: null,
            method: bank,
            placeholder: true,
        };
        try {
            let payload = {
                firstName,
                lastName,
                accountNumber,
                routingNumber,
            }
            this.isButtonDisabled(true)
            this.setState(prevState => ({
                loadingSinglePM: true,
                submittedPayoutMethod: placeholderData,
                configuredElectronicPayoutMethods: [placeholderData , ...prevState.configuredElectronicPayoutMethods]
            }));
            await axios.post("/api/member/v2/configureBankPayoutTarget", payload, {headers: {'Content-Type': 'application/json'}});
            await this.loadPayoutMethods();
        } catch (e) {
            console.warn(e)
            this.isButtonDisabled(false)
            this.setState((prevState) => ({
              loadingSinglePM: false,
              submitError: resolveErrorMessage(e,"An unexpected error occurred."),
              submittedPayoutMethod: null,
              configuredElectronicPayoutMethods:
                prevState.configuredElectronicPayoutMethods.filter(
                  (method) => method.placeholder !== true
                ),
            }));
        }
    }

    configurePaypal = async () => {
        let placeholderData = {
            contactId: null,
            externalId: this.state.username,
            label: this.state.username,
            id: null,
            method: paypal,
            placeholder: true,
        };
        try {
            let payload = {username: this.state.username}
            this.isButtonDisabled(true)
            this.setState(prevState => ({
                loadingSinglePM: true,
                submittedPayoutMethod: placeholderData,
                configuredElectronicPayoutMethods: [placeholderData , ...prevState.configuredElectronicPayoutMethods]
            }));
            await axios.post("/api/member/v2/configurePaypalPayoutTarget", payload, {headers: {'Content-Type': 'application/json'}});
            await this.loadPayoutMethods();
            this.setState({username: null, confirmUsername: null})
        } catch (e) {
            console.warn(e)
            this.isButtonDisabled(false)
            this.setState((prevState) => ({
              loadingSinglePM: false,
              submitError: resolveErrorMessage(e,"An unexpected error occurred."),
              submittedPayoutMethod: null,
              username: null,
              confirmUsername: null,
              configuredElectronicPayoutMethods:
                prevState.configuredElectronicPayoutMethods.filter(
                  (method) => method.placeholder !== true
                ),
            }));
        }
    }

    validateBankForm = () => {
        const required =  ['firstName', 'lastName', 'routingNumber', 'accountNumber'];
        let error = false;
        for (let i = 0; i < required.length; i++) {
            let item = required[i];
            if (!this.state[item] || this.state[item] === null || this.state[item].length === 0) {
                error = true
                break
            }
        }
        return error
    }

    configure = async ({key}) => {
        this.setState({configurePm: key})
    }

    addPM = () =>
        this.setState({
            showModal: true,
            configurePm: null
        })

    clearPM = async (payoutMethod) => {
        this.isButtonDisabled(true)
        this.setState({loading: true});
        try {
            await axios.post("/api/member/v2/removePayoutTarget", payoutMethod, {headers: {'Content-Type': 'application/json'}});
            const {submittedPayoutMethod} = this.state;
            if (payoutMethod.externalId === submittedPayoutMethod?.externalId) this.setState({submittedPayoutMethod: null});
            await this.loadPayoutMethods(true, false);
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, submitError: resolveErrorMessage(e, 'An unexpected error occurred.')})
        }
    }

    isButtonDisabled = (state) => {
        if(this.props.updatePMLoadingState){
            this.props.updatePMLoadingState(state);
        }
    }

    render() {
        const {defaultPayoutMethod, loading, showModal, configurePm, loadError, configuredElectronicPayoutMethods, preparingConfigureBankAccountView, loadingSinglePM} = this.state;
        const {selectedPayoutMethod, showRadio} = this.props;
        const trigger = <Icon name={"ellipsis horizontal"} color={"grey"}/>;
        const modalTitle = `${!!configurePm ? "Configure" : "Select"} Payout Method`;

        return preparingConfigureBankAccountView ? <Dimmer active page><Loader/></Dimmer> : <>
            {!!showModal && <Modal size={'tiny'} onClose={this.closeModal} open>
                <CustomModalHeader title={modalTitle} onClose={this.closeModal} className="customModalHeader"/>
                <Modal.Content>
                    {!!configurePm ?
                        <>
                            {configurePm === paypal && <RenderPaypalConfigForm {...this.state} loading={loadingSinglePM} setError={this.setError} getError={this.getError} handleChange={this.handleChange} configurePaypal={this.configurePaypal} closeModal={this.closeModal}/>}
                            {configurePm === venmo && <RenderVenmoConfigForm {...this.state} loading={loadingSinglePM} setError={this.setError} getError={this.getError} handleChange={this.handleChange} configureVenmo={this.configureVenmo} closeModal={this.closeModal}/>}
                            {configurePm === bank && <RenderBankConfigForm {...this.state} loading={loadingSinglePM} setError={this.setError} getError={this.getError} handleChange={this.handleChange} configureBank={this.configureBank} closeModal={this.closeModal} validationErrorsMap={this.state.validationErrorsMap} validateBankForm={this.validateBankForm}/>}
                        </>
                        : <>
                            { getElectronicPayouts().map((paymentMethod, index) => {
                                return <Grid columns={2} key={index}>
                                    <Grid.Row verticalAlign={"middle"}>
                                        <Grid.Column width={12}>
                                            <Table basic>
                                                <Table.Body>
                                                    <Table.Row verticalAlign={"middle"}>
                                                        <Table.Cell style={{height: '76px'}}>
                                                            {paymentMethod.Component()}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign={"right"}>
                                                            <Button primary basic compact onClick={() => this.configure(paymentMethod)}>
                                                                Configure
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            })}
                        </>
                    }
                </Modal.Content>
            </Modal>}

            <Grid container stackable columns={1} centered>
                <Grid.Column>
                    {(loading && !loadingSinglePM)
                        ? <div style={{height: '25vh'}}><Loader style={{zIndex: "0"}} active/></div>
                        : !!loadError
                            ? <Message negative>{loadError}</Message>
                            : <>
                                { configuredElectronicPayoutMethods?.map((payoutMethod, index) => {
                                    if (!payoutMethod) {
                                        return null;
                                    }
                                    const PayoutMethodInfo = PAYOUT_METHODS[payoutMethod?.method]?.Component?.();
                                    const isDefault = payoutMethod.externalId === defaultPayoutMethod?.externalId;
                                    const isSelected = payoutMethod.externalId === selectedPayoutMethod?.externalId;

                                    return <Grid key={payoutMethod?.externalId? payoutMethod?.externalId : index}>
                                        <Grid.Row verticalAlign={"middle"} style={payoutMethod.placeholder ? {opacity: "0.4"}: {opacity: "1"}}>
                                            {payoutMethod.placeholder && (
                                                <div><Loader style={{zIndex: "0"}} active/></div>
                                            )}
                                            {!!showRadio && <Grid.Column width={1}>
                                                <Radio
                                                    onChange={() => this.selectPM(payoutMethod)}
                                                    checked={isSelected}
                                                    disabled={payoutMethod.placeholder}
                                                />
                                            </Grid.Column>}
                                            <Grid.Column width={!!showRadio ? 11 : 12} onClick={() => this.selectPM(payoutMethod)}>
                                                <Table basic>
                                                    <Table.Body>
                                                        <Table.Row verticalAlign={"middle"}>
                                                            <Table.Cell style={{height: '76px', maxHeight: '30px'}}>
                                                                <div className={"paymentMethodDisplay"}>
                                                                    {PayoutMethodInfo}
                                                                    {isDefault && <DefaultPill/>}
                                                                </div>
                                                                <span className={"neutral700Text smaller"}>{payoutMethod.label}</span>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={"right"}>
                                                                <Dropdown trigger={trigger} icon={null} direction={"left"}>
                                                                    <Dropdown.Menu>
                                                                        {!isDefault && <Dropdown.Item text='Set default' onClick={() => this.setDefault(payoutMethod)}/>}
                                                                        <Dropdown.Item text='Remove' onClick={() => this.clearPM(payoutMethod)}/>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                })}
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <ClickableText onClick={this.addPM}>+Add payment method</ClickableText>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <b>OR</b>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row verticalAlign={"middle"}>
                                        {!!showRadio && <Grid.Column width={1}>
                                            <Radio
                                                onChange={() => this.selectPM()}
                                                checked={!!selectedPayoutMethod && !selectedPayoutMethod?.externalId}
                                            />
                                        </Grid.Column>}
                                        <Grid.Column width={!!showRadio ? 11 : 12} onClick={() => this.selectPM()}>
                                            <Table basic>
                                                <Table.Body>
                                                    <Table.Row verticalAlign={"middle"}>
                                                        <Table.Cell style={{height: '76px', maxHeight: '30px'}}>
                                                            <div className={"paymentMethodDisplay"}>
                                                                {PAYOUT_METHODS[paycheck]?.Component?.()}
                                                                {!defaultPayoutMethod?.externalId && <DefaultPill style={{marginLeft: '1em'}}/>}
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell textAlign={"right"}>
                                                            {defaultPayoutMethod?.externalId &&
                                                                <Dropdown trigger={trigger} icon={null} direction={"left"}>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item text='Set default' onClick={() => this.setDefault({method: "paycheck"})} />
                                                                    </Dropdown.Menu>
                                                                </Dropdown>}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </>}
                </Grid.Column>
            </Grid>
        </>
    }
}

export default connect(mapStateToProps)(withRouter(PayoutMethods));
