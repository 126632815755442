import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Image, Loader, Message, Radio, Table, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../../custom-common';
import Footer from "../../footer";
import {connect} from "react-redux";
import {setLeftNavigation, setPrevRoute} from "../../../actions/navigationActions";
import {toggle as toggleGetHelpModal} from '../../../actions/getHelpActions';
import FullScreenPageMenu from "../../fullScreenPageMenu";
import QLEModal from "./qle/qleModal";
import {clearPlanChange, setPlanChangeInitialData} from "../../../actions/planChangeActions";
import axios from "axios";
import {ADD_OTHER_DEPENDENT_ROUTE, CHANGE_ROUTE, resolveErrorMessage} from "../../../utils/common";
import { ClickableText } from '../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        dependents: state.initialData.dependents,
        genderOptions: state.planChange.genderOptions
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLeftNavigation: state => dispatch(setLeftNavigation(state)),
        clearPlanChange: () => dispatch(clearPlanChange()),
        setPrevRoute: route => dispatch(setPrevRoute(route)),
        setPlanChangeInitialData: payload => dispatch(setPlanChangeInitialData(payload)),
        toggleGetHelpModal: state => dispatch(toggleGetHelpModal(state)),
    }
};

class Change extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            prepareError: '',
            event: 'qle',
            showModal: false
        }
    }

    componentDidMount() {
        const {setLeftNavigation, clearPlanChange} = this.props

        setLeftNavigation(false)
        clearPlanChange()
        this.loadData().then()
    }

    loadData = async() => {
        const {setPlanChangeInitialData} = this.props

        try {
            this.setState({loading: true})
            const result = await axios.post("/api/member/v2/preparePlanChanges", null, {headers: {'Content-Type': 'application/json'}});
            const data = result.data;
            const payload = {
                genderOptions: data.genderOptions,
                nameSuffixOptions: data.nameSuffixOptions,
                states: data.states,
                relationshipOptions: data.relationshipOptions,
                countries: data.countries,
                highDeductibleOptions: data.highDeductibleOptions,
                coPayOptions: data.coPayOptions,
                disposableIncomeOptions: data.disposableIncomeOptions,
                dependentsOptions: data.dependentsOptions,
                costPerOptions: data.costPerOptions,
                familyTierOptions: data.familyTierOptions
            }
            setPlanChangeInitialData(payload)
            this.setState({loading: false, prepareError: ''})

        } catch (e) {
            console.warn(e)
            this.setState({loading: false, prepareError: resolveErrorMessage(e, "An unexpected error ocurred")})
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/plan");
    }

    selectEvent = (event) => {
        this.setState({
            event: event
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    onNext = () => {
        const {setPrevRoute} = this.props

        if (this.state.event === "qle") {
            this.props.history.push("/change/qle")
        } else if (this.state.event === "update") {
            setPrevRoute(CHANGE_ROUTE)
            this.props.history.push(ADD_OTHER_DEPENDENT_ROUTE)
        }
    }

    render() {
        const {event, showModal, loading, prepareError} = this.state;
        const {toggleGetHelpModal} = this.props;

        const hasData = !!this.props.genderOptions;
        const button = (<Button primary disabled={!event} content="Get started" onClick={this.onNext}/>)
        return (
            <React.Fragment>
                <QLEModal showModal={showModal} onModalClose={this.closeModal}/>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    {(loading || !hasData) ? <Loader active/> : !!prepareError ? <CustomContainer><Message negative>{prepareError}</Message></CustomContainer> :
                        <Grid container stackable columns={1} centered>
                            <Grid.Column width={8} className={"pageContent"} textAlign={"center"}>
                                <Divider hidden/><Divider hidden/><Divider hidden/><Divider hidden/>
                                <Header as={"h2"}>Need to make a change?</Header>
                                <p className={"neutral700Text"}>With Brella, you can make changes anytime. If you’ve had
                                    a qualifying life event, this impacts which changes you can make and when they’ll
                                    take effect. </p>
                                <Divider hidden/>
                                <Image style={{width: '180px'}} centered src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/QLEPathChoice.png"}/>
                                <Divider hidden/>
                                <p className={"neutral700Text"}>Select the reason you want to make a change—</p>
                                <Grid centered>
                                    <Grid.Row verticalAlign={"middle"}>
                                        <Grid.Column width={1} style={{paddingRight: 0}} textAlign={"right"}>
                                            <Radio
                                                onChange={() => {
                                                    this.selectEvent('qle')
                                                }}
                                                checked={event === "qle"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{width: '386px'}}>
                                            <Table basic onClick={() => {
                                                this.selectEvent('qle')
                                            }} className={"clickable"}>
                                                <Table.Body>
                                                    <Table.Row verticalAlign={"middle"}>
                                                        <Table.Cell style={{height: '76px'}}><b>I’ve had a qualifying
                                                            life event</b></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                        <Grid.Column width={1}/>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column textAlign={"center"}>
                                            <Divider hidden/>
                                            <b>If you need to fix or correct your information reach out to <ClickableText onClick={() => toggleGetHelpModal(true)}>Brella Concierge</ClickableText></b>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/*<Grid.Row verticalAlign={"middle"} style={{paddingTop: 0}}>
                                        <Grid.Column width={1} style={{paddingRight: 0}} textAlign={"right"}>
                                            <Radio
                                                onChange={() => {
                                                    this.selectEvent('update')
                                                }}
                                                checked={event === "update"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{width: '386px'}}>
                                            <Table basic onClick={() => {
                                                this.selectEvent('update')
                                            }} className={"clickable"}>
                                                <Table.Body>
                                                    <Table.Row verticalAlign={"middle"}>
                                                        <Table.Cell style={{height: '76px'}}><b>I need to update or
                                                            correct my information</b></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                        <Grid.Column width={1}/>
                                    </Grid.Row>*/}
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={12} textAlign={"center"}>
                                <Divider hidden/><Divider hidden/>
                                <p className={"smaller"}>
                                    <b>Not sure if you are eligible?</b><br/>
                                    Learn more about <ClickableText onClick={() => {
                                    this.setState({showModal: true})
                                }}>qualifying life events</ClickableText>
                                </p>
                            </Grid.Column>
                        </Grid>
                    }
                    <Footer showTopFooter={true} showCenterData={true}
                                         button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Change));
