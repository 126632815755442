import {Grid, Message, Segment, Header} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import React from "react";
import axios from "axios";
import {documentTypes, dowloadBase64} from "../../utils/common";
import DownloadIcon from "./common/downloadIcon";
import {delay, dowloadBlob, resolveErrorMessage} from "../../utils/utils";
import moment from "moment/moment";
import PDFIcon from "./common/pdfIcon";
import {connect} from "react-redux";


const mapStateToProps = (state, ownProps) => {
    const id = state.initialData || {}
    const contract = state.initialData?.contract || {}
    const groupSizeSegment = contract?.groupSizeSegment

    return {
        ...ownProps,
        documents: id.documents,
        stateCode: contract.issueState,
        groupSizeSegment: groupSizeSegment,
        policyNumber: contract.policyNumber,
        employer: state.initialData.employer,
    }
};


const PlanDocuments = ({ documents, stateCode, groupSizeSegment, policyNumber, employer }) => {
    const [groupAppDoc, setGroupAppDoc] = React.useState();
    const [error, setError] = React.useState();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const findDocument = () => {
            if (!!documents) {
                for (const doc of documents) {
                    if (doc.documentType === documentTypes.groupApplication) {
                        return doc
                    }
                }
            }
            return null
        }

        setGroupAppDoc(findDocument());
    }, [documents]);

    const setUnableToGetDocumentError = (e = {}) => setError(resolveErrorMessage(e, 'Unable to get the document'));

    const clearError = () => setError(null);

    const startLoading = () => setLoading(true);

    const stopLoading = () => setLoading(false);

    const downloadDoc = async (doc) => {
        try {
            startLoading()
            clearError();
            const result = await axios.post("/api/member/v2/downloadDocument", {"documentId": doc.documentId},
                {headers: {'Content-Type': 'application/json'}});
            const data = result.data;
            dowloadBase64(data.document.name, data.document.contentType, data.document.body)
        } catch (e) {
            console.warn(e)
            setUnableToGetDocumentError();
        } finally {
            stopLoading();
        }
    }

    const downloadCoveragePacket = async () => {
        clearError();
        startLoading();

        try {
            const payload = {
                employerId: employer.id,
                stateCode,
                groupSizeSegment,
                policyNumber,
            }

            let result
            let status

            result = await axios.post('/api/member/v2/generateCoveragePacket', payload)
            status = result.data.status

            while (status === 'pending') {
                await delay(4000)
                result = await axios.post('/api/member/v2/isCoveragePacketGenerated', payload)
                status = result.data.status
            }

            if (status !== 'success') {
                setUnableToGetDocumentError();
                return
            }

            result = await axios.post('/api/member/v2/downloadCoveragePacket', payload, { responseType: 'blob' })
            const data = result.data
            let packetName = result.headers["x-packetname"];
            if(!packetName){
                const date = moment().format('YYYY-MM-DD')
                packetName = `coverage-packet-${date}.pdf`;
            }
            dowloadBlob(packetName, 'application/pdf', data)
        } catch (e) {
            console.warn(e);
            setUnableToGetDocumentError(e);
        } finally {
            stopLoading();
        }
    }

    return (
        <>
            <Header as={"h3"}>Plan document(s)</Header>
            {error && <Message error> {error} </Message>}
            <Grid basic stackable columns={2} as={Segment} loading={loading}>
                <Grid.Column className={"no-padding"}>
                    <CustomContainer
                        data-qa-pdf_coverage
                        className={"colorful-border clickable"}
                        onClick={downloadCoveragePacket}
                    >
                        <PDFIcon/>
                        <CustomContainer basic compact vertical style={{ marginLeft: '48px'}}>
                            <b>Coverage packet</b><DownloadIcon/>
                            <p className={"smaller truncate-3"} style={{ minHeight: '40px', marginTop: '8px', width: '90%' }}>
                                This includes the limited benefit insurance certificate,
                                HIPAA notice of privacy practices and more.
                            </p>
                        </CustomContainer>
                    </CustomContainer>
                </Grid.Column>
                {groupAppDoc && (
                    <Grid.Column className={"no-padding"}>
                        <CustomContainer
                            className={"colorful-border clickable"}
                            onClick={() => downloadDoc(groupAppDoc)}
                        >
                            <PDFIcon/>
                            <CustomContainer basic compact vertical style={{ marginLeft: '48px'}}>
                                <b>Group application</b>{this.downloadIcon}
                                <p className={"smaller truncate-3"} style={{ minHeight: '40px', marginTop: '8px', width: '90%' }}>
                                    The approved limited benefit policyholder application submitted for your group's policy.
                                </p>
                            </CustomContainer>
                        </CustomContainer>
                    </Grid.Column>
                )}
            </Grid>
        </>
    )
}

export default connect(mapStateToProps)(PlanDocuments);
