import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Form, Grid, Button, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import {setNewAddress} from "../../../../actions/planChangeActions";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {CHANGE_ADDRESS_ROUTE, CONFIRM_ADDRESS_CHANGE_ROUTE} from "../../../../utils/common";
import ErrorInput from "../../../errorInput";
import ChangeAddressSteps from "./changeAddressSteps";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        newAddress: state.planChange.newAddress,
        stateOptions: state.planChange.states
    }
};

class ChangeAddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invalidDepZipCode: false,
            zipCode: this.props.newAddress.zipCode,
            street: this.props.newAddress.street,
            state: this.props.newAddress.state,
            city: this.props.newAddress.city,
            stateError: false
        }
    }

    onBackHandler = () => {
        this.props.history.push(CHANGE_ADDRESS_ROUTE);
    }

    handleFormChange = (event, {value, name}) => {
        if (name === "zipCode") {
            const valNum = parseInt(value, 10);
            if (isNaN(valNum) && value !== "") {
                return;
            }
        }
        this.setState(
            {
                [name]: value
            })
    }

    onNext = () => {
        const {street, city, state, zipCode} = this.state;
        const stateObject = this.props.stateOptions.filter(st => st.value === state)[0];
        const address = {
            zipCode: zipCode,
            street: street,
            state: state,
            stateObject: stateObject,
            city: city
        }
        this.props.dispatch(setNewAddress(address))
        this.props.history.push(CONFIRM_ADDRESS_CHANGE_ROUTE)
    }

    validAddForm = () => {
        let error = false;
        const requiredIfAddress = ['street', 'zipCode', 'city', 'state'];
        const address = this.state;
        for (let i = 0; i < requiredIfAddress.length; i++) {
            let item = requiredIfAddress[i];
            if (!address[item] || address[item].length === 0) {
                error = true;
                break;
            }
        }

        return error;
    }

    render() {
        const {stateOptions} = this.props;
        const {street, appartment, city, state, zipCode, invalidDepZipCode, stateError} = this.state;
        const error = this.validAddForm();
        const button = <Button primary content="Next step" disabled={error || stateError || invalidDepZipCode}
                               onClick={this.onNext}/>;

        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeAddressSteps activeStepIdx={1}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Header as={"h2"}>Change of address</Header>
                            <p className={"neutral700Text"}>Enter the updated address below—</p>
                            <Divider hidden/>
                            <Form>
                                <Form.Group widths={'equal'}>
                                    <ErrorInput fluid required={true}
                                                label="Street address"
                                                name='street'
                                                value={!street ? '' : street}
                                                onChange={this.handleFormChange}/>

                                    <Form.Input label="Appartment"
                                                value={!appartment ? '' : appartment}
                                                name={"appartment"} onChange={this.handleFormChange}/>
                                </Form.Group>
                                <Form.Group widths={'equal'}>
                                    <Form.Field>
                                        <Form.Input label="Zip code" required
                                                    value={!zipCode ? '' : zipCode}
                                                    error={invalidDepZipCode}
                                                    name={"zipCode"}
                                                    onFocus={() => {
                                                        this.setState({
                                                            invalidDepZipCode: false,
                                                        });
                                                    }}
                                                    onChange={this.handleFormChange}
                                                    onBlur={() => {
                                                        const isError = !zipCode || zipCode.length === 0 || ((zipCode.length > 5 && zipCode.length < 9) || zipCode.length < 5 || zipCode.length > 9);
                                                        this.setState({
                                                            invalidDepZipCode: isError || isNaN(zipCode * 1),
                                                        })
                                                    }}/>
                                        {!!invalidDepZipCode && <small style={{color: 'red'}}>Invalid zip code</small>}
                                    </Form.Field>

                                    <ErrorInput fluid required={true}
                                                label={"City"}
                                                name='city'
                                                value={!city ? '' : city}
                                                onChange={this.handleFormChange}/>
                                </Form.Group>
                                <Form.Field width={6}>
                                    <Form.Select required fluid
                                                 name={"state"}
                                                 label="State"
                                                 value={!state ? '' : state}
                                                 options={stateOptions}
                                                 error={!!stateError}
                                                 onChange={this.handleFormChange}
                                                 onBlur={()=>{
                                                     if(!state){
                                                         this.setState({stateError: "Required"});
                                                     }
                                                 }}
                                    />
                                    {!!stateError && <small style={{color: 'red'}}>{stateError}</small>}
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ChangeAddressForm));
