import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import NewClaimMenu from "./newClaimMenu";
import {Checkbox, Divider, Grid, Icon, Image, Loader, Message, Button, Header} from "semantic-ui-react";
import {CustomContainer, ClickableText} from '../../custom-common';
import NewClaimSteps from "./newClaimSteps";
import "./newClaims.css"
import {connect} from "react-redux";
import {cleanupNewClaim, setSelectedPayoutMethod, setWhatHappened} from "../../../actions/newClaimActions";
import {paycheck, readFileAsync, resolveErrorMessage} from "../../../utils/common";
import axios from "axios";
import Footer from "../../footer";
import {Document, Page} from "react-pdf";
import {updateClaimList} from "../../../actions/claimsActions";
import {PAYOUT_METHODS} from "../../../payouts";
import ClaimStepsLayout from "../claimStepsLayout";

const mapStateToProps = ({claims, initialData, newClaim}, ownProps) => {
    return {
        ...ownProps,
        claimant: newClaim.claimant,
        whatHappened: newClaim.whatHappened,
        treatmentInfo: newClaim.treatmentInfo,
        files: newClaim.files,
        facilityOptions: initialData.facilityOptions,
        selectedPayoutMethod: newClaim.selectedPayoutMethod,
        user: initialData.user,
        userIsCertificateHolder: initialData.userIsCertificateHolder,
        claims: claims.claims,
        certificateStateCode: initialData.certificate.stateCode,
    }
};

class Review extends Component {
    constructor(props) {
        super(props);
        const {claimant} = this.props;
        if (!claimant) {
            console.warn("No user info")
            this.props.history.replace("/claims");
        }
        this.state = {
            agreement: false,
            loading: false,
            submitError: null,
            editDiagnosisProvider: true,
            loadError: null,
            loadingPM: false,
            claimFraudLanguage: {},
        }
    }

    async componentDidMount() {
        this.prepareClaimReview();

        if (!this.props.userIsCertificateHolder) {
            this.setState({loadingPM: true});
            try {
                const result = await axios.post("/api/member/v2/listConfiguredPayoutMethods", null, {headers: {'Content-Type': 'application/json'}});

                this.props.dispatch(setSelectedPayoutMethod(result.data.defaultPayoutMethod));
                this.setState({loadingPM: false});
            } catch (e) {
                this.setState({loadingPM: false, loadError: resolveErrorMessage(e, 'An unexpected error occurred.')});
            }
        }
    }

    onBackHandler = () => {
        if (!!this.props.userIsCertificateHolder) {
            this.props.history.push("/claims/new/payment")
        } else {
            this.props.history.push("/claims/new/confirmUploads");
        }
    }

    onEditWhatHappened = () => {
        this.props.history.push("/claims/new/whatHappened")
    }

    onEditTreatmentInfo = () => {
        this.props.history.push("/claims/new/treatmentInfo")
    }

    onEditUploads = () => {
        this.props.history.push("/claims/new/uploads")
    }

    toggleAgreement = () => {
        let {agreement} = this.state;
        this.setState({agreement: !agreement})
    }

    filesDocument = async (files) => {
        return Promise.all(files.map(async file => {
            const content = await readFileAsync(file)
            return {fileName: file.name, contentType: file.type, contentData: content.split(",")[1]}
        }))
    }

    prepareClaimReview = () => {
        const {certificateStateCode} = this.props;
        axios.post("/api/member/v2/prepareClaimReview",
            {statePostalCode: certificateStateCode},
            {headers: {'Content-Type': 'application/json'}})
            .then(response => this.setState({claimFraudLanguage: response.data.claimFraudLanguage}))
            .catch(e => console.log(e));
    }

    submitClaim = async () => {
        const {claimant, whatHappened, treatmentInfo, files, selectedPayoutMethod, claims} = this.props
        this.setState({loading: true})
        files.length && files.forEach(file => {
            URL.revokeObjectURL(file.url)
        })
        try {
            const documents = await this.filesDocument(files);
            if (!selectedPayoutMethod?.externalId) {
                this.props.dispatch(setSelectedPayoutMethod({id: null, externalId: null, contactId: null, method: paycheck}));
            }

            const payload = {
                claimantId: claimant.id,
                whatHappened: whatHappened,
                treatmentInfo: treatmentInfo,
                documents: documents,
                selectedPayoutMethod: selectedPayoutMethod
            }
            const result = await axios.post("/api/member/v2/submitClaimDataPm", payload, {headers: {'Content-Type': 'application/json'}});
            const newClaim = result.data.claim;
            const draftClaims = claims.filter(item => item.status === "Draft");
            const noDraftClaims = claims.filter(item => item.status !== "Draft");
            const updatedClaimList = [...draftClaims, ...newClaim ? [newClaim] : [], ...noDraftClaims];
            this.props.dispatch(updateClaimList(updatedClaimList))
            this.props.history.push({pathname: "/claims/newClaimSuccess", claimNumber: result.data.claimNumber})
            this.props.dispatch(cleanupNewClaim())
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, submitError: resolveErrorMessage(e, 'An unexpected error occurred.')})
        }
    }

    formatFacilities = (options) => {
        const {facilityOptions} = this.props;
        const optionsLabel = options.map(item => {
            return facilityOptions.find(option => option.value === item).text
        })
        return optionsLabel.join(", ")
    }

    handleChange = (e, {name, value}) => {
        let {whatHappened} = this.props;
        whatHappened[name] = value;
        this.props.dispatch(setWhatHappened(whatHappened))
    }

    render() {
        const {whatHappened, treatmentInfo, files, selectedPayoutMethod, user, claimant} = this.props;
        const {agreement, loading, submitError, loadError, loadingPM, claimFraudLanguage} = this.state;
        const button = <Button primary content="Submit" disabled={!agreement || loading} loading={loading} onClick={this.submitClaim}/>;
        const showWarning = !!user && !!claimant ? ((user.id !== claimant.id) && !claimant.underAge) : null;
        if (!!loadError) {
            return <CustomContainer basic><Message negative>{loadError}</Message></CustomContainer>
        } else if (!!loadingPM) {
            return <Loader active/>
        } else {
            const methodKey = selectedPayoutMethod?.externalId ? selectedPayoutMethod.method : paycheck;
            const PayoutMethodInfo = PAYOUT_METHODS[methodKey]?.Component?.();

            return (
                <React.Fragment>
                    <NewClaimMenu onBack={this.onBackHandler}/>
                    <ClaimStepsLayout>
                        <NewClaimSteps activeStepIdx={3}/>
                        <div style={{marginTop: "56px", marginBottom: "100px"}}>
                            <Grid container stackable columns={1} centered>
                                <Grid.Column width={11} className={"pageContent reviewClaimDetails"}>
                                    <CustomContainer padded={"very"}>
                                        <Header as="h2" style={{marginTop: "0px"}}>Review your claim
                                            details</Header>
                                        <p className={"neutral700Text"}>Look over these details before submitting—</p>

                                        <Divider hidden/>

                                        <Header as="h4">Payment preference</Header>
                                        {PayoutMethodInfo}
                                        {
                                            selectedPayoutMethod?.label &&
                                            <span className={"neutral700Text smaller"}>{selectedPayoutMethod.label}</span>
                                        }
                                        <Divider hidden/>
                                        <Header as="h4">What happened</Header>
                                        <div>
                                            <strong>What symptoms did you experience?</strong>
                                            <span className="newClaimReviewEditOption">
                                                <ClickableText small onClick={this.onEditWhatHappened}><Icon name="pencil"/>Edit</ClickableText>
                                            </span>
                                            <div className='break-word'>{whatHappened.symptoms}</div>
                                        </div>
                                        <Divider hidden/>
                                        <div>
                                            <strong>What did your provider tell you was wrong?</strong>
                                            <span className="newClaimReviewEditOption">
                                                <ClickableText small onClick={this.onEditWhatHappened}><Icon name="pencil"/>Edit</ClickableText>
                                            </span>
                                            <div className='break-word'>{whatHappened.providerDiagnosis}</div>
                                        </div>
                                        <Divider hidden/>
                                        <div>
                                            <strong>What treatment did you receive?</strong>
                                            <span className="newClaimReviewEditOption">
                                                <ClickableText small onClick={this.onEditWhatHappened}><Icon name="pencil"/>Edit</ClickableText>
                                            </span>
                                            <div className='break-word'>{whatHappened.treatment}</div>
                                        </div>
                                        <Divider hidden/>
                                        <div>
                                            <strong>Is there anything else we should know?</strong>
                                            <span className="newClaimReviewEditOption">
                                                <ClickableText small onClick={this.onEditWhatHappened}><Icon name="pencil"/>Edit</ClickableText>
                                            </span>
                                            <div className='break-word'>{whatHappened.additionalInfo}</div>
                                        </div>

                                        <Divider hidden/>

                                        <Header as="h4">Where you were treated</Header>
                                        <div>
                                            <strong>What type of facility did you go to?</strong>
                                            <span className="newClaimReviewEditOption">
                                                <ClickableText small onClick={this.onEditTreatmentInfo}><Icon name="pencil"/>Edit</ClickableText>
                                            </span>
                                            <div className='break-word'>{this.formatFacilities(treatmentInfo.facilities)}</div>
                                        </div>
                                        <Divider hidden/>
                                        <div>
                                            <strong>Where did you receive care? </strong>
                                            <span className="newClaimReviewEditOption">
                                                <ClickableText small onClick={this.onEditTreatmentInfo}><Icon name="pencil"/>Edit</ClickableText>
                                            </span>
                                            <div className='break-word'>{treatmentInfo.place}</div>
                                        </div>

                                        <Divider hidden/>
                                        <Header as="h4" style={{display: 'inline-block'}}>Uploads</Header>
                                        <span className="newClaimReviewEditOption">
                                            <ClickableText small onClick={this.onEditUploads}><Icon name="pencil"/>Edit</ClickableText>
                                        </span>
                                        <Grid columns={2}>
                                            {files.map((file, index) => {
                                                return <Grid.Row key={index} verticalAlign={"middle"}>
                                                    <Grid.Column verticalAlign={"middle"}
                                                                 style={{width: 'calc(75px + 2em)', paddingRight: 0}}>
                                                        {file.type.startsWith("image/") ? (
                                                            <div style={{height: '56px', width: '75px'}}>
                                                                <Image centered
                                                                       bordered
                                                                        style={{
                                                                            objectFit: 'cover',
                                                                            height: '100%',
                                                                            width: '100%'
                                                                        }}
                                                                        src={file.url}/>
                                                                </div>
                                                            ) : (<>
                                                                {this.loading = true}
                                                                <div style={{
                                                                    height: '56px',
                                                                    width: '75px',
                                                                    border: '1px solid rgba(0, 0, 0, 0.1)',
                                                                    overflow: 'hidden'
                                                                }}>
                                                                    <Document
                                                                        file={file.url}
                                                                        width={75}
                                                                    >
                                                                        <Page pageNumber={1} width={73}/>
                                                                    </Document>
                                                                </div>
                                                            </>)
                                                        }
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {file.name}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            })}
                                        </Grid>
                                        {!!showWarning && <React.Fragment>
                                            <Divider hidden/>
                                            <Message className={"newClaimReviewOnBehalfMessage"}>Because you are submitting a claim on behalf of a dependent
                                                over the age of 18, you will not be able to see claims status or update
                                                after submission. If you have questions, please contact us.</Message>
                                        </React.Fragment>}
                                        <Divider hidden/>
                                        <Header as="h4">Attestation</Header>
                                        <Checkbox name="agreement"
                                                  onChange={this.toggleAgreement}
                                                  label={
                                                      <label>
                                                          <p style={{fontSize: '1em'}}>
                                                              By checking this box, you attest that you have provided truthful information on this form. {claimFraudLanguage?.generic}
                                                          </p>
                                                      </label>}
                                        />
                                        { !!claimFraudLanguage?.stateSpecific &&
                                        <p className={"smaller neutral700Text"} style={{marginTop: "15px"}}>
                                            FRAUD WARNING: {claimFraudLanguage.stateSpecific}
                                        </p>
                                        }
                                    </CustomContainer>
                                    <Divider hidden/>
                                    {!!submitError && <Message negative>{submitError}</Message>}
                                </Grid.Column>
                            </Grid>
                        </div>
                        <div style={{marginTop: "100px"}}>
                            <Footer showTopFooter showCenterData button={button} />
                        </div>
                    </ClaimStepsLayout>
                </React.Fragment>
            )
        }
    }
}

export default connect(mapStateToProps)(withRouter(Review));
