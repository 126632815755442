import React, {Component} from "react"
import {Grid, Image, Button, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {ADD_OTHER_DEPENDENT_ROUTE} from "../../../../utils/common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,

    }
};

class AddDependentSuccess extends Component {
    onNext = () => {
            this.props.history.push("/change/update/updateCoverage")
    }

    onBackHandler = () => {
        this.props.history.replace(ADD_OTHER_DEPENDENT_ROUTE)
    }
    render() {
        const button = <Button primary content="Next step" onClick={this.onNext}/>;
        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                    <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                        <Grid.Column width={8} className={"pageContent"}>
                            <Grid stackable container style={{marginTop: '3em'}} textAlign={'center'}>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Image centered style={{width: '70px'}} src="https://brella-platform-assets.s3.amazonaws.com/enrollment/images/success.svg"/>
                                        <Header as={"h2"}>Success!</Header>
                                        <p>Your family members have been updated!<br/>
                                            Now let’s adjust your Brella coverage—</p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                    <Footer
                        showTopFooter={true} showCenterData={true} button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(AddDependentSuccess));
