import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Dimmer, Form, Input, Loader, Message, Modal, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import axios from "axios";
import {setAccountSummaryCardLoading, setBillingData} from "../../actions/billingActions";
import moment from "moment";
import {strFormatMoney} from "../../utils/utils";
import NumberFormat from 'react-number-format';
import {creditCard} from "../../utils/common";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import {CustomModalHeader, ClickableText} from "../custom-common";
import {show as showToast} from "../../actions/toastActions";


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        historicalInvoices: state.billing.historicalInvoices,
        today: state.billing.today,
        chargeAttempts: state.billing.chargeAttempts,
        paymentMethods: state.billing.paymentMethods,
        billingAccountId: state.billing.billingAccountId,
        autoPay: state.billing.autoPay,
    }
};


class PaymentAuthorizationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPaymentMethod: null,
            amountOptionSelected: "fullAmount",
            customPaymentAmount: null,
            disableMakePaymentButton: false,
            paymentInProgress: false,
            useDifferentPaymentMethod: false
        }
    }

    componentDidMount() {
        const {paymentMethods} = this.props;
        const defaultPM = paymentMethods.filter(PM => !!PM.isDefault)[0]
        this.setState({
            selectedPaymentMethod: defaultPM,
        })
        if (this.props.showPaymentSuccess) {
            this.showToastSuccess()
        }
        if (!!this.props.chargeAttempts && this.props.chargeAttempts.length > 0) {
            this.refresh()
        }
    }

    handlePaymentMethodChange = (e, {value}) => {
        const selectedPaymentMethod = this.props.paymentMethods.filter(bank => bank.stripeId === value)[0];
        this.setState({selectedPaymentMethod: selectedPaymentMethod, useDifferentPaymentMethod: true})
    }

    handleChange = (e, {value}) => this.setState({amountOptionSelected: value})

    handleCustomPaymentAmount = (value) => this.setState({customPaymentAmount: value})

    handleDisableSubmission = () => {
        const {amountOptionSelected, customPaymentAmount} = this.state;
        const {accountBalance} = this.props;
        return amountOptionSelected === "other" && !(Number(customPaymentAmount) > 0 && Number(customPaymentAmount) <= Number(accountBalance));
    }

    handlePayBill = async () => {
        const {accountBalance, invoiceId, billingAccountId} = this.props;
        const {amountOptionSelected, customPaymentAmount, selectedPaymentMethod, updateDefaultPaymentMethod} = this.state;
        const amountToPay = amountOptionSelected === "fullAmount" ? accountBalance : customPaymentAmount;
        let payload = {
            amount: amountToPay,
            paymentMethodId: selectedPaymentMethod.stripeId,
            updateDefaultPaymentMethod: updateDefaultPaymentMethod,
            billingAccountId,
        }
        if (invoiceId) {
            payload["invoiceId"] = invoiceId
        }
        try {
            clearTimeout(this.timer)
            this.setState({
                disableMakePaymentButton: true,
                loading: true
            })
            this.setPaymentInProgress(true)
            this.props.dispatch(setAccountSummaryCardLoading(true))
            const endpointRoute = selectedPaymentMethod.type === creditCard ? "/api/member/v2/createCreditCardPayment" : "/api/member/v2/createBankAccountPayment";
            await axios.post(endpointRoute, payload);
            this.refresh()
            this.closePaymentModal()
        } catch (e) {
            console.warn(e)
            clearTimeout(this.timer)
            this.setState({loading: false, paymentInProgress: false})
            this.showToastError()
            this.props.dispatch(setAccountSummaryCardLoading(false))
            this.handleErrorMessages(e);
        }
    }

    setPaymentInProgress = async (inProgress, showPaymentSuccessful = true) => {
        const {afterRefresh} = this.props;
        if (this.state.paymentInProgress === true && inProgress === false) {
            if (afterRefresh) {
                await afterRefresh(true);
            } else {
                if (showPaymentSuccessful) {
                    this.showToastSuccess()
                }
            }
        }
        this.setState({paymentInProgress: inProgress})
    }

    handleErrorMessages(e) {
        clearTimeout(this.timer)
        console.warn(e)

        this.setPaymentInProgress(false, false)
        this.props.dispatch(setAccountSummaryCardLoading(false))

        const paymentError = `Authorization error: This bank account is not authorized for premium payments.
            To resolve the issue reach out to your financial institution, or try a different payment method.
            For additional technical support, contact us at ${STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}
            or ${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}.`

        this.setState({
            loading: false,
            paymentError,
            disableMakePaymentButton: false
        })
    }

    refresh = () => {
        this.timer = setTimeout(() => {
            this.refreshAfterPayment()
        }, 1300)
    }

    refreshAfterPayment = async () => {
        try {
            clearTimeout(this.timer)
            const result = await axios.post("/api/member/v2/prepareBilling", {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(setBillingData(result.data))
            this.props.dispatch(setAccountSummaryCardLoading(false))
            if (!!result.data.chargeAttempts && result.data.chargeAttempts.length > 0) {
                this.refresh()
            } else {
                this.setPaymentInProgress(false)
            }
        } catch (e) {
            clearTimeout(this.timer)
            console.warn(e)
            this.props.dispatch(setAccountSummaryCardLoading(false))
            this.setState({loading: false})
            this.showToastError()
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    closePaymentModal = () => {
        this.setState({
            paymentError: null,
            payBillConfirmationOpen: false,
            disableMakePaymentButton: false,
            customPaymentAmount: null
        })
    }

    buildPaymentMethodsOptions = (paymentMethods) => {
        return paymentMethods.map(item => {
            const name = item.type === creditCard ? item.brand.toUpperCase() : item.brand
            return (
                {
                    key: item.stripeId,
                    text: name + `${item.type === creditCard ? ' ending with ' + item.last4 : " (*********" + item.last4 + ")"}`,
                    value: item.stripeId,
                    label: item.isDefault ? <Label style={{marginTop: '-3px', float: 'right'}} horizontal>Default</Label> : null
                })
        })
    }

    getTotalAmountToPay = () => {
        const {customPaymentAmount, amountOptionSelected} = this.state
        const {accountBalance} = this.props
        if (amountOptionSelected === 'fullAmount') {
            return strFormatMoney(accountBalance)
        }
        return strFormatMoney(customPaymentAmount)
    }

    showToastSuccess = () => {
        this.props.dispatch(showToast({
            type: 'success',
            title: "Payment submission success",
            message: "Your payment has been successfully submitted for processing. You’ll receive an email when your payment has processed or if we have any issues.",
        }))
    }

    showToastError = () => {
        this.props.dispatch(showToast({
            type: 'error',
            title:'There was a problem processing your payment.',
            message:'Please check your bank information, and try again later.'
        }))
    }

    render() {
        const {accountBalance, historicalInvoices, today, twoColumnsLayout, paymentMethods, autoPay} = this.props;
        const {
            customPaymentAmount,
            selectedPaymentMethod,
            paymentError,
            disableMakePaymentButton,
            updateDefaultPaymentMethod
        } = this.state;
        const paymentMethodsOptions = this.buildPaymentMethodsOptions(paymentMethods);

        if (!paymentMethodsOptions || paymentMethodsOptions.length === 0) {
            return this.renderNotPaymentMethod();
        }
        const firstOrNext = !historicalInvoices || historicalInvoices.length === 0 ? "first" : "next";
        const formattedToday = moment(today, "DD/MM/YYYY").format("MMM DD, YYYY")
        const brand = !!selectedPaymentMethod ? selectedPaymentMethod.type === creditCard ? selectedPaymentMethod.brand.toUpperCase() : selectedPaymentMethod.brand : null;
        return <React.Fragment>
            <Dimmer.Dimmable dimmed={this.state.paymentInProgress}>
                <Modal
                    size={"tiny"}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    open={this.state.payBillConfirmationOpen}
                    onClose={this.closePaymentModal}
                >
                    <CustomModalHeader title={"Make a payment"} onClose={this.closePaymentModal} isCloseDisabled={this.state.paymentInProgress} className="customModalHeader" />
                    <Modal.Content>
                        <Grid columns={2}>
                            <Grid.Column width={12}><p>Please confirm the details below to make your payment—</p>
                            </Grid.Column>
                            <Grid.Column><p className={"small neutral700Text"}><b>Paying from</b></p></Grid.Column>
                            <Grid.Column textAlign={"right"}>{!!selectedPaymentMethod &&
                                <p>{brand}{selectedPaymentMethod.type === creditCard ? ' ending with ' + selectedPaymentMethod.last4 : ' (*******' + selectedPaymentMethod.last4 + ')'}</p>}</Grid.Column>
                            <Grid.Column><p className={"small neutral700Text"}><b>Date</b></p></Grid.Column>
                            <Grid.Column textAlign={"right"}><p>{formattedToday}</p></Grid.Column>
                            <Grid.Column><p className={"small neutral700Text"}><b>Amount</b></p></Grid.Column>
                            <Grid.Column textAlign={"right"}><Header as="h3">{this.getTotalAmountToPay()}</Header></Grid.Column>
                            {(autoPay && !selectedPaymentMethod?.isDefault) &&
                                <Grid.Row>
                                    <Grid.Column width={12}>
                                        <Form.Checkbox label={"Set payment method as default for ongoing automatic payments"}
                                                       checked={updateDefaultPaymentMethod}
                                                       name={"updateDefaultPaymentMethod"}
                                                       onChange={this.toggleUpdateDefaultPM}/>
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                        <Divider hidden/>
                        {paymentError && <Message negative size="mini">{paymentError}</Message>}
                        <Button primary floated={"right"}
                                onClick={this.handlePayBill}
                                loading={this.state.paymentInProgress}
                                disabled={disableMakePaymentButton}
                        > Authorize payment
                        </Button>
                        <Button
                            color="grey"
                            basic floated={"right"}
                            onClick={this.closePaymentModal}
                            disabled={disableMakePaymentButton}
                        > Cancel
                        </Button>
                    </Modal.Content>
                    <Divider hidden/>
                </Modal>
                {twoColumnsLayout ?
                    <React.Fragment>{this.renderInTwoColumns(paymentMethodsOptions, selectedPaymentMethod, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext)}</React.Fragment> :
                    <React.Fragment>{this.renderInOneColumn(paymentMethodsOptions, selectedPaymentMethod, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext, autoPay, brand)}</React.Fragment>
                }
                <Dimmer active={this.state.paymentInProgress} inverted> <Loader/> </Dimmer>
            </Dimmer.Dimmable>
        </React.Fragment>
    }

    renderInTwoColumns(paymentMethodsOptions, selectedPaymentMethod, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext) {
        return <Grid columns={2} divided>
            <Grid.Column width={12} style={{paddingBottom: 0}}>
                <Header as="h4">Payment Authorization</Header>
            </Grid.Column>
            <Grid.Row stretched>
                <Grid.Column>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                {this.renderPaymentMethodsDropdown(paymentMethodsOptions, selectedPaymentMethod)}
                            </Grid.Column>
                        </Grid.Row>

                        {!!accountBalance && accountBalance !== "0.00" &&
                            <>
                                {this.renderPaymentForm(formattedToday, customPaymentAmount, accountBalance)}
                            </>
                        }
                    </Grid>

                    {accountBalance === "0.00" &&
                        <CustomContainer style={{backgroundColor: "#FFF7EC", minHeight: 165}} textAlign={"center"}>
                            <Grid verticalAlign={"middle"}>
                                <Divider hidden/>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <p style={{maxWidth: 310}} className={"neutral700Text small"}>
                                            Payment authorization isn’t available until your {firstOrNext} invoice has been generated.
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </CustomContainer>
                    }
                </Grid.Column>
                <Grid.Column>
                    <Grid columns={1} verticalAlign={"middle"}>
                        {this.renderMakeAPaymentButton()}
                    </Grid>
                </Grid.Column>

            </Grid.Row>

        </Grid>;
    }

    renderInOneColumn(paymentMethodsOptions, selectedPaymentMethod, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext, autoPay, brand) {
        const {useDifferentPaymentMethod} = this.state;
        return <>
            <Header as="h4">Payment Authorization</Header>
            <Grid columns={1} style={{flex: 1}}>
                <Grid.Row style={{paddingBottom: 0}}>
                    <Grid.Column width={4}>
                        <Form>
                            <Form.Field><label>Paying from {selectedPaymentMethod?.lastPaymentWithError &&
                                <Icon color={"red"} name='exclamation circle'/>}</label>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign={"right"}>
                        {(autoPay && selectedPaymentMethod?.lastPaymentWithError) ?
                            !!useDifferentPaymentMethod ?
                                <ClickableText onClick={this.props.switchToBillingSettings} small>Set up a new method in settings</ClickableText>
                                : <ClickableText onClick={() => {
                                    this.setState({useDifferentPaymentMethod: true})
                                }} small>Use a different method</ClickableText>
                            : null
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0}}>
                    {(autoPay && !useDifferentPaymentMethod) ? <>
                            <Grid.Column width={8}>
                                <div
                                    style={{color: selectedPaymentMethod?.lastPaymentWithError ? '#D82000' : '#212121'}}>{brand}{selectedPaymentMethod?.type === creditCard ? ' ending with ' + selectedPaymentMethod?.last4 : ' (*******' + selectedPaymentMethod?.last4 + ')'}</div>
                            </Grid.Column>
                            <Grid.Column width={4}><Label horizontal>Default</Label></Grid.Column>
                        </> :
                        <Grid.Column
                            width={12}>{this.renderPaymentMethodsDropdown(paymentMethodsOptions, selectedPaymentMethod)}</Grid.Column>
                    }
                </Grid.Row>

                {accountBalance !== "0.00" &&
                    <>
                        {this.renderPaymentForm(formattedToday, customPaymentAmount, accountBalance, autoPay)}
                        {this.renderMakeAPaymentButton(autoPay)}
                    </>
                }
            </Grid>

            {accountBalance === "0.00" &&
                <CustomContainer className={"bkgLinen"} style={{
                    display: 'flex', alignItems: 'center', minHeight: 214,
                    marginBottom: 0, justifyContent: "center"
                }} textAlign={"center"}>
                    <Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <p style={{maxWidth: 310}} className={"neutral700Text small"}>
                                    Payment authorization isn’t available until your {firstOrNext} invoice has been generated.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </CustomContainer>
            }
        </>;
    }

    renderMakeAPaymentButton(autoPay) {
        return <Grid.Column>
            <Button primary fluid basic={autoPay}
                    disabled={this.handleDisableSubmission() || !this.state.selectedPaymentMethod || (this.props.chargeAttempts && this.props.chargeAttempts.length > 0)}
                    onClick={() => this.setState({payBillConfirmationOpen: true})}>{autoPay ? 'Make a one-time payment' : 'Make a payment'}</Button>
        </Grid.Column>;
    }

    toggleUpdateDefaultPM = () => {
        let {updateDefaultPaymentMethod} = this.state;
        this.setState({updateDefaultPaymentMethod: !updateDefaultPaymentMethod})
    }

    renderPaymentForm(formattedToday, customPaymentAmount, accountBalance) {
        return <>
            <Grid.Row columns={2}>
                <Grid.Column width={8}>
                    <Form>
                        <Form.Field>
                            <label>Amount</label>
                            <Radio
                                label={<label>Total amount due: <b>{strFormatMoney(accountBalance)}</b></label>}
                                name='fullAmount'
                                value='fullAmount'
                                checked={this.state.amountOptionSelected === 'fullAmount'}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form>
                        <Form.Field>
                            <label>Date</label>
                            <span className={"small"}>{formattedToday}</span>
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} style={{paddingTop: 0}}>
                <Grid.Column width={2} style={{minHeight: '30px'}}>
                    <Form>
                        <Form.Field>
                            <Radio
                                label='Other'
                                name='other'
                                value='other'
                                checked={this.state.amountOptionSelected === 'other'}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={4}>
                    {this.state.amountOptionSelected === 'other' &&
                        <Form style={{marginTop: '-5px'}}>
                            <Form.Field>
                                <NumberFormat thousandSeparator={true}
                                              error={this.state.amountOptionSelected === 'other' && customPaymentAmount > parseFloat(accountBalance)}
                                              prefix="$"
                                              customInput={Input}
                                              name={'customPaymentAmount'}
                                              onValueChange={(values, _) => {
                                                  const {value} = values;
                                                  this.handleCustomPaymentAmount(value)
                                              }}
                                              value={customPaymentAmount}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                />
                            </Form.Field>
                        </Form>
                    }
                </Grid.Column>

                <Grid.Column width={6}>
                    {this.state.amountOptionSelected === 'other' && customPaymentAmount > parseFloat(accountBalance) &&
                        <span style={{display: 'inline-block', lineHeight: '120%'}}
                              className={"smaller warningRedText"}>Amount cannot be over the total amount due</span>
                    }
                </Grid.Column>
            </Grid.Row>
        </>;
    }

    renderPaymentMethodsDropdown(paymentMethodsOptions, selectedPaymentMethod) {
        return (
            <Dropdown
                className={selectedPaymentMethod?.lastPaymentWithError ? "optionWithError" : ''}
                fluid
                selection
                options={paymentMethodsOptions}
                value={selectedPaymentMethod ? selectedPaymentMethod.stripeId : null}
                onChange={this.handlePaymentMethodChange}
                error={selectedPaymentMethod?.lastPaymentWithError}
            />
        )
    }

    renderNotPaymentMethod() {
        return <>
            <Header as="h4">Payment Authorization</Header>
            <CustomContainer className={"bkgLinen"} style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                <Grid columns={1} textAlign={"center"} style={{flex: 1}}>
                    <Grid.Column textAlign={"center"}>
                        <Icon circular name='university' className={"neutral600Text"}
                              style={{fontSize: 30, backgroundColor: "#F7D2CC", boxShadow: "none"}}/>
                    </Grid.Column>
                    <Grid.Column textAlign={"center"}>
                        <p style={{textAlign: "center"}}><span className={"neutral700Text small"}><b>You have no e-payment option configured.<br/>Please set up an e-payment method in</b><br/><span
                            className="linkText small"
                            onClick={this.props.switchToPaymentDetail}>payment details.</span> </span>
                        </p>
                    </Grid.Column>
                </Grid>
            </CustomContainer>
        </>
    }

}

export default connect(mapStateToProps)(withRouter(PaymentAuthorizationCard));
