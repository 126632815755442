import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Message, Loader, Container} from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {resolveErrorMessage, isValidJson} from "../../utils/common";
import {CustomDivider} from "../custom-common";

const DocuSignConsent = ({history}) => {
    const [loading, setLoading] = useState(false);
    const longPolling = useRef(null);
    const [dsSignCeremonyUrl, setDsSignCeremonyUrl] = useState('');
    const [error, setError] = useState(null);

    const checkSigned = useCallback(() => {
        if (longPolling.current) {
            return;
        }
        setLoading(true);
        const poll = async () => {
            try {
                const result = await axios.post('/api/member/v2/checkSignStatus');
                if (result.data?.signed) {
                    clearTimeout(longPolling.current);
                    longPolling.current = null;
                    setLoading(false);
                    history.push('/plan');
                } else {
                    longPolling.current = setTimeout(poll, 3000);
                }
            } catch (error) {
                clearTimeout(longPolling.current);
                longPolling.current = null;
                setLoading(false);
                setError(error);
            }
        };
        poll();
    }, [history]);

    const onMessageReceived = useCallback(async (event) => {
        const {data} = event;
        if (data === null || data === undefined){
            return;
        }
        if (typeof data === 'string' && isValidJson(data)) {
            try {
                const parsedData = JSON.parse(data);
                if (parsedData.message === 'complete_agreement') {
                    checkSigned();
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [checkSigned]);

    const prepareDocusign = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post('/api/member/v2/prepareDocusign');
            const {url, signed} = response.data;
            setDsSignCeremonyUrl(url);
            if (signed) {
                checkSigned();
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        prepareDocusign();
    }, []);

    useEffect(() => {
        window.addEventListener('message', onMessageReceived, false);
        return () => {
            window.removeEventListener('message', onMessageReceived, false);
            if (longPolling.current) {
                clearTimeout(longPolling.current);
                longPolling.current = null;
            }
        };
    }, [onMessageReceived]);

    if (error) {
        return (
            <>
                <CustomDivider hidden />
                <Container>
                    <Message negative>{resolveErrorMessage(error, "An unexpected error occurred")}</Message>
                </Container>
             </>
       )
    }
    if (loading) return <Loader active/>
    return (
        <React.Fragment>
            {dsSignCeremonyUrl && (
                <iframe
                    style={{height: '100vh', marginBottom: '-100px'}}
                    src={dsSignCeremonyUrl}
                    title='DocuSign'
                    width='100%'
                />
            )}
        </React.Fragment>
    );
};

export default withRouter(DocuSignConsent);
