import moment from 'moment'
import {getLocalization, languages} from "./localization";
import axios from 'axios';
import {addDays, calculateDaysBetween} from "./date";
import {PAYROLL_FREQUENCIES} from "../constants/payrollFrequencies";

export const displayDateFormat = "MM/DD/YYYY";
export const serverDateFormat = "YYYY-MM-DD";
export const longDateFormat = "MMMM Do YYYY"

export const bank = "bank";
export const venmo = "venmo";
export const paypal = "paypal";
export const paycheck = "paycheck";
export const check = "check";
export const stripe = "stripe";

export const creditCard = "card";

export const CHANGE_ROUTE = "/change"
export const WHEN_ROUTE = "/change/qle/when"
export const REMOVE_DEPENDENT = "/change/qle/removeDependent"
export const CONFIRM_CHILD_REMOVAL = "/change/qle/confirmRemoval"
export const UPDATE_COVERAGE_ROUTE = "/change/update/updateCoverage"
export const INCOME_ROUTE = "/change/update/disposableIncome"
export const ADD_DEPENDENT_ROUTE = "/change/qle/addDependent"
export const ADD_STEP_CHILDREN_ROUTE = "/change/qle/addStepChildren"
export const BRELLA_SELECT_ROUTE = "/change/update/select"
export const BRELLA_CHOICE_ROUTE = "/change/update/choice"
export const HEALTH_PLAN_DETAILS_ROUTE = "/change/update/healthPlan"
export const ADD_OTHER_DEPENDENT_ROUTE = "/change/update/addOtherDependent"
export const CHANGE_ADDRESS_ROUTE = "/change/qle/changeAddress"
export const CHANGE_ADDRESS_FORM_ROUTE = "/change/qle/changeAddressForm"
export const CONFIRM_ADDRESS_CHANGE_ROUTE = "/change/qle/confirmAddressChange"
export const SUCCESS_ADDRESS_CHANGE_ROUTE = "/change/qle/successAddressChange"
export const CONFIRM_DISSOLUTION = "/change/qle/confirmDis"
export const HAVE_STEP_CHILDREN_ROUTE = "/change/qle/haveStepChildren"

export const AOE_REVIEW_PLAN_ROUTE = "/anualOpenEnrollment/plan";
export const AOE_REVIEW_PERSONAL_INFO_ROUTE = "/anualOpenEnrollment/personalInfo";
export const AOE_REVIEW_FAMILY_ROUTE = "/anualOpenEnrollment/family";
export const AOE_REVIEW_SUCCESS_ROUTE = "/anualOpenEnrollment/reviewSuccess";
export const AOE_REVIEW_WOULD_YOU_MIND_ROUTE = "/anualOpenEnrollment/wouldYouMind";
export const AOE_HEALTH_PLAN_DETAILS_ROUTE = "/anualOpenEnrollment/healthPlanDetails";
export const AOE_INCOME_ROUTE = "/anualOpenEnrollment/income";
export const AOE_SELECT_PLAN_ROUTE = "/anualOpenEnrollment/selectPlan";
export const AOE_CONFIRM_PLAN_CHANGES_ROUTE = "/anualOpenEnrollment/confirmPlanChanges";
export const AOE_SUCCESS_ROUTE = "/anualOpenEnrollment/success";
export const AOE_END_COVERAGE = "/anualOpenEnrollment/endCoverage";

export const PAYMENT_REQUIRED = "/paymentRequired"
export const SUCCESSFUL_PAYMENT = "/successfulPayment"
export const ELIGIBILITY_LOSS = "/eligibilityLoss"
export const ENROLLMENT_ENDED = "/enrollmentEnded"
export const BETTER_ON_THE_APP_ROUTE = "/betterOnTheApp"
export const MFA_ROUTE = "/mfa";
export const LOGIN_ROUTE = "/login";
export const PLAN_ROUTE = "/plan";
export const SETTINGS_ROUTE = "/settings";

export const QLE_REASON_NEW_DOMESTIC_PARTNER = "New Domestic Partner"
export const QLE_REASON_MARRIAGE = "Marriage"

export const SPOUSE = "Spouse"
export const DOMESTIC_PARTNER =  "Domestic Partner"
export const CHILD = "Child"

export const STATE_SPECIFIC_CONSENT_ROUTE ="/consent/stateSpecific"
export const GENERIC_CONSENT_ROUTE = "/consent/generic"
export const DOCUSIGN_CONSENT_ROUTE = "/consent/docusign"

export const isEvenNumber = (num) => num & 2 === 0;

export const convertServerDateFormatToDisplay = (dateStr) => {
    const notInServerFormat = dateStr.split("/").length > 1;
    if(notInServerFormat){
        return dateStr;
    }
    return moment(dateStr, serverDateFormat).format(displayDateFormat)
}

export const convertDateToLongFormat = (date) => {
    return moment(date).format(longDateFormat)
}

const mdyDateRegex = /^(?:0[1-9]|1[012])\/(?:0[1-9]|[12][0-9]|3[01])\/(?:19\d{2}|20\d{2})$/

export const convertDisplayDateFormatToServer = (dateStr) => {
    if (!mdyDateRegex.test(dateStr)) return dateStr;
    const [month, day, yyyy] = dateStr.split("/");
    const mm = (`0${+month}`).slice(-2);
    const dd = (`0${+day}`).slice(-2);
    return `${yyyy}-${mm}-${dd}`;
}

export const formatPartialDate = (partialDateStr, prevValue) => {
    let response = partialDateStr;
    const userIsAddingCharacters = !prevValue || partialDateStr.length > prevValue.length;
    if (userIsAddingCharacters) {
        if (partialDateStr.length === 2) {
            response = partialDateStr + "/";
        } else if (partialDateStr.length === 5) {
            response = partialDateStr + "/";
        }
    } else {
        if (partialDateStr.length === 2) {
            response = partialDateStr.substring(0, 1)
        } else if (partialDateStr.length === 5) {
            response = partialDateStr.substring(0, 4)
        }
    }

    return response.substring(0, 10);
}

export function parseDate(value) {
    return moment(value, displayDateFormat, true);
}

export const formatMoneyAmount = (value) => {
    value = truncTwoDec(value);
    return value.toLocaleString('en-US');
}

export const truncTwoDec = (num) => {
    if (typeof num === "string") {
        return num
    }
    const fixed = num.toFixed(2);
    const parts = fixed.split(".")
    if (parts.length > 1 && parts[1] === "00") {
        return Math.floor(num * 100) / 100;
    }
    return fixed;
};

export function memberName(member) {
    if (!member) {
        return ""
    }
    return `${member.firstName} ${member.lastName}`
}

export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

export function cropText(text, maxLenght) {
    if (!text || text.length <= maxLenght) {
        return text;
    }
    return text.substring(0, maxLenght) + "..."
}

export const getStepProps = (activeStepIdx) => {
    const stepProps = [
        {
            active: false,
            completed: false,
            disabled: true,
            label: "What happened",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Upload",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Payment Preference",
        }, {
            active: false,
            completed: false,
            disabled: true,
            label: "Review",
        }
    ];

    for (let i = 0; i < activeStepIdx; i++) {
        stepProps[i].disabled = false;
        stepProps[i].completed = true;
    }
    stepProps[activeStepIdx].active = true;
    stepProps[activeStepIdx].disabled = false;
    return stepProps;
}

export function resolveErrorMessage(e, defaultError) {
    if (e.response && e.response.data && e.response.data.errorMessage) {
        return e.response.data.errorMessage
    } else {
        return defaultError
    }
}

export function resolveDisplayTier(value) {
    let familyTierMap = new Map();
    familyTierMap.set("employee", "Myself")
    familyTierMap.set("spouse", "My spouse and me")
    familyTierMap.set("child", "My child(ren) and me")
    familyTierMap.set("family", "My family and me")

    return familyTierMap.get(value)
}

export const firstCharUpper = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const documentTypes = {
    groupApplication: "GroupApplication",
    policy: "Policy",
    employerCertificate: "ER_certificate",
    chronicRider: "Chronic_Rider",
    mentalRider: "Mental_Rider",
    employeeCertificate: "EE_certificate"
}

export function dowloadBase64(fileName, contentType, data) {
    const element = document.createElement("a")
    element.href = `data:${contentType};base64,${data}`;
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element)
}

export function getNumberOfUploadsRequired(props) {
    return 4
    // Voya Protect requires 4 (https://joinbrella.atlassian.net/browse/BRL-6052). For other partners, verify and use the logic bellow:
    // if (!!props) {
    //     if (
    //         ((props.facilities.includes("Doctor's office") || props.facilities.includes("Urgent care")) && (props.doctorsOffice.includes("Told you need surgery?") || props.urgentCare.includes("Told you need surgery?"))) ||
    //         props.facilities.includes("Outpatient Surgery (in a hospital or surgical center)") || props.facilities.includes("Hospital (Inpatient)")
    //     ) {
    //         return 4
    //     } else {
    //         return 2
    //     }
    // }
}

export function resolveQLERoute(value) {
    let qle = new Map();
    qle.set("Birth or Adoption of Child", ADD_DEPENDENT_ROUTE)
    qle.set("Gain of Child Custody", ADD_DEPENDENT_ROUTE)
    qle.set(QLE_REASON_MARRIAGE, ADD_DEPENDENT_ROUTE)
    qle.set(QLE_REASON_NEW_DOMESTIC_PARTNER, ADD_DEPENDENT_ROUTE)
    qle.set("Loss of Child Custody", REMOVE_DEPENDENT)
    qle.set("Address Change", CHANGE_ADDRESS_ROUTE)
    qle.set("Death of Spouse or Dependent", CONFIRM_DISSOLUTION)
    qle.set("Dissolution of Domestic Partnership", CONFIRM_DISSOLUTION)
    qle.set("Divorce or Legal Separation", CONFIRM_DISSOLUTION)

    return qle.get(value)
}

export function resolveAddDependentTitle(value) {
    let qle = new Map();
    qle.set("Birth or Adoption of Child", "Birth or adoption of child(ren)")
    qle.set("Gain of Child Custody", "Gain of child custody")
    qle.set(QLE_REASON_MARRIAGE, "Marriage")
    qle.set(QLE_REASON_NEW_DOMESTIC_PARTNER, "New domestic partner")

    return qle.get(value)
}

export function resolveAddDependentRelationship(value) {
    let qle = new Map();
    qle.set("Birth or Adoption of Child", "Child")
    qle.set("Gain of Child Custody", "Child")
    qle.set(QLE_REASON_MARRIAGE, "Spouse")
    qle.set(QLE_REASON_NEW_DOMESTIC_PARTNER, "Domestic Partner")

    return qle.get(value)
}

export const isCoveredState = (selectedState) => {
    const notCoveredList = [
        "WA",
        "CA",
        "ME",
        "NH",
        "CT",
        "VT",
        "RI",
        "IL",
        "NC",
        "NJ",
        "NY",
        "MS",
        "AL",
        "KY",
    ];
    const match = notCoveredList.filter((nc) => nc === selectedState);
    return match.length === 0;
}

export function resolveBrellaPlanPath(value) {
    let qle = new Map();
    qle.set("Brella Select", "/change/update/select")
    qle.set("Brella Select Custom", "/change/update/select")
    qle.set("Brella Choice", "/change/update/choice")

    return qle.get(value)
}

export const findEmployerContribution = (optionValue, options, obj) => {
    if (optionValue === null || typeof optionValue === "undefined") {
        return 0.0;
    }
    let selected = '';
    options.forEach((o) => {
        if (o.value === optionValue) {
            selected = o.value;
        }
    });
    switch (selected) {
        case 'child':
            return obj.employeeChild;
        case 'family':
            return obj.family;
        case 'spouse':
            return obj.employeeSpouse;
        case 'employee':
            return obj.employeeOnly
        default:
            return 0.0;
    }
}

export function getCoverageEffectiveDate(isQle, qleReason, qleDate) {
    const mNow = moment(Date.now());
    const nextMonth = moment(mNow).add(1, 'months');
    const firstOfNextMonth = moment(nextMonth).startOf('month');

    if (!!isQle) {
        const mQLEDate = moment(qleDate, displayDateFormat);

        if (qleReason === "Loss of Child Custody" || qleReason === "Death of Spouse or Dependent" || qleReason === "Dissolution of Domestic Partnership" || qleReason === "Divorce or Legal Separation") {
            return qleDate
        } else if (qleReason === "Marriage" || qleReason === "New Domestic Partner") {
            const monthNextToQleDate = moment(mQLEDate).add(1, 'months');
            const firstOfmonthNextToQleDate = moment(monthNextToQleDate).startOf('month');
            if (mNow.isAfter(firstOfmonthNextToQleDate)) {
                return firstOfNextMonth
            } else {
                return firstOfmonthNextToQleDate
            }
        } else if (qleReason === "Birth or Adoption of Child" || qleReason === "Gain of Child Custody") {
            const oneMonthAfterQLE = moment(mQLEDate).add(31, 'days');
            if (oneMonthAfterQLE.isAfter(mNow)) {
                return mQLEDate
            } else {
                return firstOfNextMonth
            }
        }
    } else {
        return firstOfNextMonth
    }
}

export const claimLabels = {
    'draft': { text: 'Draft', className: 'draft', bulletColor: 'sunshade' },
    'open': { text: 'Under review', className: 'open', bulletColor: 'sunshade' },
    'under review': { text: 'Under review', className: 'under-review', bulletColor: 'sunshade' },
    'not in good order': { text: 'Needs more information', className: 'not-in-good-order', bulletColor: 'red' },
    'approved': { text: 'Approved', className: 'approved', bulletColor: 'green' },
    'denied': { text: 'Denied', className: 'denied', bulletColor: 'black' }
}

export const isValidClaimStatus = (value) => {
    const status = value.toLowerCase();

    const allowedClaims = Object.keys(claimLabels)

    return allowedClaims.includes(status)
}

export const getClaimStatusLabel = (value) => {
    const status = !!value ? value.toLowerCase() : value;

    return claimLabels[status] || '--'
}

export const getQleIcon = (qle) => {
    const event = qle.toLowerCase();

    const specialEvents = ['annual open enrollment', 'new hire', 'newly eligible']

    let iconName = 'user outline';
    let iconColorClass = 'primaryQLEIcon';

    if (specialEvents.includes(event) || event === "contract amendment") {
        iconName = 'file alternate outline'
        iconColorClass = 'secondaryQLEIcon'
    }

    return {iconName: iconName, iconColorClass: iconColorClass}
}

export function getNumberOfDeductions(payrollFrecuencySelected) {

    let payrollFrecuencySelectedMap = new Map();
    payrollFrecuencySelectedMap.set(PAYROLL_FREQUENCIES.BI_WEEKLY_26, 26)
    payrollFrecuencySelectedMap.set(PAYROLL_FREQUENCIES.BI_WEEKLY_24, 24)
    payrollFrecuencySelectedMap.set(PAYROLL_FREQUENCIES.SEMI_MONTHLY_24, 24)
    payrollFrecuencySelectedMap.set(PAYROLL_FREQUENCIES.MONTHLY_12, 12)
    payrollFrecuencySelectedMap.set(PAYROLL_FREQUENCIES.WEEKLY_52, 52)

    return payrollFrecuencySelectedMap.get(payrollFrecuencySelected)
}

export const getTranslatedGenderText = (text, localization) => {
    const strings = localization.dropdownOptions;
    const localizationEng = getLocalization(languages.ENGLISH);
    const engStrings = localizationEng.dropdownOptions;
    if(text === engStrings.female){
        text = strings.female;
    } else if(text === engStrings.male){
        text = strings.male;
    }
    return text;
}

export const getTranslatedPhoneTypeText = (text, localization) => {
    const strings = localization.dropdownOptions;
    const localizationEng = getLocalization(languages.ENGLISH);
    const engStrings = localizationEng.dropdownOptions;
    if(text === engStrings.mobile){
        text = strings.mobile;
    } else if(text === engStrings.home){
        text = strings.home;
    } else if(text === engStrings.standard){
        text = strings.standard;
    }
    return text;
}

export const getTranslatedRelationshipText = (text, localization) => {
    const strings = localization.dropdownOptions;
    const localizationEng = getLocalization(languages.ENGLISH);
    const engStrings = localizationEng.dropdownOptions;
    if(text === engStrings.child){
        text = strings.child;
    } else if(text.toLowerCase() === engStrings.domesticPartner.toLowerCase()){
        text = strings.domesticPartner;
    } else if(text === engStrings.spouse){
        text = strings.spouse;
    }
    return text;
}

export const savePoints = {
    [AOE_REVIEW_PLAN_ROUTE]: {step: 1, redirectTo: AOE_REVIEW_PERSONAL_INFO_ROUTE},
    [AOE_REVIEW_PERSONAL_INFO_ROUTE]: {step: 1, redirectTo: AOE_REVIEW_FAMILY_ROUTE},
    [AOE_REVIEW_FAMILY_ROUTE]: {step: 1, redirectTo: AOE_HEALTH_PLAN_DETAILS_ROUTE},
    [AOE_HEALTH_PLAN_DETAILS_ROUTE]: {step: 2, redirectTo: AOE_INCOME_ROUTE},
    [AOE_INCOME_ROUTE]: {step: 3, redirectTo: AOE_SELECT_PLAN_ROUTE},
    [AOE_SELECT_PLAN_ROUTE]: {step: 3, redirectTo: AOE_CONFIRM_PLAN_CHANGES_ROUTE},
};

export const handleSaveUserProgress = async (anualOpenEnrollmentObj, lang, handleStart, handleSuccess, handleError) => {

    const {
        dependents, typicallyPayInfo, highDeductibleInfo, leftoverMoney, keepCoverage, changedFamilyMembers,
        coverageOption, costPerOption, moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected,
        moderateCoverageRecommended, severeCoverageRecommended, catastrophicCoverageRecommended, costValue, costEnhanced,
        costPremier, selectedState, streetAddress, appartment, zipCode, city, birthDate, ssn, middleName, suffix,
        daytimePhoneNumber, phoneType, employerContribution, cost, aOEDependents,
        validSsnVerification, firstName, lastName, lastVisitedSavePoint, selectedTierBrellaSelect, fetchPlanRecommendation, gender, skipRecommendation
    } = anualOpenEnrollmentObj;

    const data = {
        dependents, typicallyPayInfo, highDeductibleInfo, leftoverMoney, lang, keepCoverage, changedFamilyMembers,
        coverageOption, costPerOption, moderateCoverageSelected, severeCoverageSelected, catastrophicCoverageSelected,
        moderateCoverageRecommended, severeCoverageRecommended, catastrophicCoverageRecommended, costValue, costEnhanced,
        costPremier, selectedState, streetAddress, appartment, zipCode, city, birthDate, ssn, middleName, suffix,
        daytimePhoneNumber, phoneType, employerContribution, cost, aOEDependents,
        validSsnVerification, firstName, lastName, lastVisitedSavePoint, selectedTierBrellaSelect, fetchPlanRecommendation, gender, skipRecommendation
    }

    try {
        !!handleStart && handleStart();
        const payload = {
                data: {
                    anualOpenEnrollment: data,
                },
        };
        await axios.post("/api/member/v2/upsertShoppingProgress", payload, {headers: {'Content-Type': 'application/json'}});
        !!handleSuccess && handleSuccess();
    } catch(e) {
        console.error(e);
        !!handleError && handleError(e);
    }
}

export function checkIsOpenEnrollment(start, end, tzStr) {
    let isOpenEnrollment = false;
    const now = moment().tz(tzStr);
    if(moment(now.format("YYYY-MM-DD")).isBetween(start, end, 'days', '[]')){
        isOpenEnrollment = true;
    }
    return isOpenEnrollment;
}

export function stringTemplate(string, vars = {}) {
    const regex = /{{(\w+)}}/g
    return string.replaceAll(regex, (_, key) => vars[key] || key)
}

export const aOEStatus = {
    pending: "Pending",
    inProgress: "InProgress",
    completed: "Completed",
    declined: "Declined"
}

export function getArrayByLanguage(array, lang) {
    console.log(array, lang)
    return array.filter(i => i.language === lang)
}

export function hasOpenEnrollmentEndReachedLimitDays(endDate) {
    const openEnrollmentDaysAfter = addDays(endDate, 30);
    const availableEnrollmentEndDays = calculateDaysBetween(moment(), openEnrollmentDaysAfter);

    return availableEnrollmentEndDays === 0;
}

export function formatDate(date, defaultValue = "", format = "MM/DD/YYYY") {
    if (!date) {
        return defaultValue
    }
    return moment.utc(date).format(format)
}

export const getTranslatedStudentStatusText = (text, localization) => {
    const strings = localization.reviewFamily;
    const localizationEng = getLocalization(languages.ENGLISH);
    const engStrings = localizationEng.reviewFamily;
    if (text === engStrings.studentStatusPartTime) {
        text = strings.studentStatusPartTime;
    } else if (text === engStrings.studentStatusFullTime) {
        text = strings.studentStatusFullTime;
    } else if (text === engStrings.studentStatusNotAStudent) {
        text = strings.studentStatusNotAStudent;
    }
    return text;
}

export const isValidJson = (input) => {
    if (typeof input !== 'string') {
        return false
    }
    const trimmedInput = input.trim();
    if (trimmedInput === '') {
        return false
    }
    try {
        JSON.parse(trimmedInput);
        return true
    } catch {
        return false
    }
}