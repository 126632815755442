import React, {useEffect, useRef} from 'react';
import {usePreventBackNavigation} from '../hooks';

const withPreventBackNavigation = (WrappedComponent) => {
    return (props) => {
        const unblock = usePreventBackNavigation();
        const unblockRef = useRef(unblock);

        useEffect(() => {
            unblockRef.current = unblock;
        }, [unblock]);

        return <WrappedComponent {...props} unblock={unblockRef.current}/>;
    };
};

export default withPreventBackNavigation;
