import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Grid, Icon, Popup, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import {formatMoneyAmount, truncTwoDec} from "../../utils/common";
import {getPayrollFrecuencyLabel} from "../../utils/utils";

const mapStateToProps = ({anualOpenEnrollment: {generalInitialData: {planComparison}, hasModifiedDependents, aOEDependents}, initialData: {riderLabels}}, ownProps) => ({
    ...ownProps,
    aOEDependents,
    hasModifiedDependents,
    planComparison,
    riderLabels,
});

class CoverageComparisonTable extends Component {
    isDifferent = (a, b) => a !== b;

    isCostDifferent = (a, b) => {
        if (a == null || b == null) {
            return false;
        }
        return truncTwoDec(a) !== truncTwoDec(b);
    };

    render() {
        const {
            showHeader,
            showCurrent,
            planComparison: {currentPlan, upcomingPlan},
            upcomingModifiedCoverage,
            date,
            showTooltip,
            hasModifiedDependents,
            aOEDependents,
            riderLabels,
        } = this.props;
        const upcomingCoverage = upcomingModifiedCoverage || upcomingPlan;
        const noBorder = {boxShadow: 'none'}
        const infoIcon = <Icon circular name={'info'} style={{marginLeft: '1em', marginTop: 0, fontSize: '12px', cursor: 'pointer'}}/>;
        const tooltipText = "Note, your cost is increasing next year. Generally costs can increase as factors like age, group rates, and employer contributions change over time."
        const upcomingPlanBenefits = upcomingCoverage.benefitAmounts;
        const isModerateDifferent = this.isDifferent(currentPlan.benefitAmounts.moderate, upcomingPlanBenefits.moderate);
        const isSevereDifferent = this.isDifferent(currentPlan.benefitAmounts.severe, upcomingPlanBenefits.severe);
        const isCatastrophicDifferent = this.isDifferent(currentPlan.benefitAmounts.catastrophic, upcomingPlanBenefits.catastrophic);
        const isChronicDifferent = this.isDifferent(currentPlan.benefitAmounts.chronic, upcomingPlanBenefits.chronic);
        const isMentalDifferent = this.isDifferent(currentPlan.benefitAmounts.mental, upcomingPlanBenefits.mental);
        const isErContribDifferent = this.isDifferent(currentPlan.employerContribution, upcomingCoverage.employerContribution);
        const isCostDifferent = this.isCostDifferent(currentPlan.costWithErContribution, upcomingCoverage.costWithErContribution) || this.isDifferent(currentPlan.payrollFrequency, upcomingCoverage.payrollFrequency)
        const areDependentsDifferent = hasModifiedDependents || aOEDependents.length > 0;
        const currentPayrollFrecuencyLabel = getPayrollFrecuencyLabel(currentPlan.payrollFrequency);
        const upcomingPayrollFrecuencyLabel = getPayrollFrecuencyLabel(upcomingCoverage.payrollFrequency);
        const isFrecuencyDifferent = currentPayrollFrecuencyLabel !== upcomingPayrollFrecuencyLabel;

        return (
            <Grid padded celled={"internally"}>
                {!!showHeader && <Grid.Row verticalAlign={date ? "top": "middle"} columns={showCurrent ? 3 : 2} className={"bkgNeutral50"}>
                    <Grid.Column style={{padding: '2em 1em'}}>
                        <Header as={'h4'}>Coverage details</Header>
                    </Grid.Column>
                    {showCurrent && <Grid.Column style={{boxShadow: 'none', padding: '2em 1em'}} textAlign={"center"}><Header as={'h3'}>Current</Header></Grid.Column>}
                    <Grid.Column textAlign={"center"} style={{padding: '2em 1em'}}>
                        <Header as={'h3'} style={{marginBottom: 0}}>Upcoming</Header>
                        {date && <span className={"neutral700Text"}><i>As of {date}</i></span>}
                    </Grid.Column>
                </Grid.Row>
                }
                <Grid.Row columns={1}>
                    <Grid.Column width={12} style={{
                        boxShadow: '0px -1px 0px 0px #d4d4d5',
                        padding: '0.5em 1em'
                    }}>
                        <div className={"darkOrangeHeader"}>BENEFIT AMOUNTS</div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={showCurrent ? 3 : 2}>
                    <Grid.Column width={showCurrent ? 4: 5}
                                 className={showCurrent ? 'neutral700Text bkgNeutral50':'neutral700Text'}><b>Moderate</b>
                    </Grid.Column>
                    {showCurrent && <Grid.Column style={noBorder} width={4} textAlign={"center"} className={"bkgNeutral50"}><Header
                        as={'h4'} className={"neutral700Text"}>${formatMoneyAmount(currentPlan.benefitAmounts.moderate)}</Header>
                    </Grid.Column>
                    }
                    <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"} className={isModerateDifferent ? "planChanged": 'bkgNeutral50'}><Header
                        as={'h4'}>{isModerateDifferent && <Icon name={"circle"}/>}${formatMoneyAmount(upcomingPlanBenefits.moderate)}</Header>
                    </Grid.Column>

                    <Grid.Column width={showCurrent ? 4: 5} className={'neutral700Text'}
                                 style={noBorder}><b>Severe</b>
                    </Grid.Column>
                    {showCurrent && <Grid.Column style={noBorder} width={4} textAlign={"center"}><Header as={'h4'} className={"neutral700Text"}>${formatMoneyAmount(currentPlan.benefitAmounts.severe)}</Header></Grid.Column>
                    }
                    <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"} className={isSevereDifferent ? "planChanged": ''}>
                        <Header as={'h4'}>{isSevereDifferent && <Icon name={"circle"}/>}${formatMoneyAmount(upcomingPlanBenefits.severe)}</Header>
                    </Grid.Column>

                    <Grid.Column width={showCurrent ? 4: 5} className={showCurrent ? 'neutral700Text bkgNeutral50':'neutral700Text'}
                                 style={noBorder}><b>Catastrophic</b></Grid.Column>
                    {showCurrent &&
                        <Grid.Column style={noBorder} width={4} textAlign={"center"} className={"bkgNeutral50"}>
                            <Header as={'h4'} className={"neutral700Text"}>
                                ${formatMoneyAmount(currentPlan.benefitAmounts.catastrophic)}
                            </Header>
                        </Grid.Column>
                    }
                    <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"} className={isCatastrophicDifferent ? "planChanged": 'bkgNeutral50'}>
                        <Header as={'h4'}>
                            {isCatastrophicDifferent && <Icon name={"circle"}/>}${formatMoneyAmount(upcomingPlanBenefits.catastrophic)}
                        </Header>
                    </Grid.Column>
                    {(!!currentPlan.benefitAmounts.chronic || !!upcomingPlanBenefits.chronic) && (
                        <>
                            <Grid.Column width={showCurrent ? 4 : 5} className={'neutral700Text'} style={noBorder}>
                                <b>{riderLabels.chronic} conditions</b>
                            </Grid.Column>
                            {showCurrent && <Grid.Column style={noBorder} width={4} textAlign={"center"}>
                                <Header as={'h4'} className={"neutral700Text"}>
                                    {!!currentPlan.benefitAmounts.chronic ? `$${formatMoneyAmount(currentPlan.benefitAmounts.chronic)}` : null}
                                </Header>
                            </Grid.Column>
                            }
                            <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"} className={isChronicDifferent ? "planChanged" : ''}>
                                <Header as={'h4'}>{isChronicDifferent && <Icon name={"circle"}/>}${formatMoneyAmount(upcomingPlanBenefits.chronic)}</Header>
                            </Grid.Column>
                        </>
                    )}
                    {(!!currentPlan.benefitAmounts.mental || upcomingPlanBenefits.mental) && (
                        <>
                            <Grid.Column width={showCurrent ? 4 : 5} className={showCurrent ? 'neutral700Text bkgNeutral50' : 'neutral700Text'} style={noBorder}>
                                <b>{riderLabels.mental} illnesses</b>
                            </Grid.Column>
                            {showCurrent && <Grid.Column style={noBorder} width={4} textAlign={"center"} className={"bkgNeutral50"}>
                                <Header as={'h4'} className={"neutral700Text"}>
                                    {!!currentPlan.benefitAmounts.mental ? `$${formatMoneyAmount(currentPlan.benefitAmounts.mental)}` : null}
                                </Header>
                            </Grid.Column>}
                            <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"} className={isMentalDifferent ? "planChanged" : 'bkgNeutral50'}>
                                <Header as={'h4'}>
                                    {isMentalDifferent && <Icon name={"circle"}/>}${formatMoneyAmount(upcomingPlanBenefits.mental)}
                                </Header>
                            </Grid.Column>
                        </>
                    )}
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={12} style={{padding: '0.5em 1em'}}>
                        <div className={"darkOrangeHeader"}>COSTS</div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={showCurrent ? 3 : 2}>
                    <Grid.Column width={showCurrent ? 4: 5} className={"neutral700Text"}>
                        <b>Who's covered?</b>
                    </Grid.Column>
                    {showCurrent &&
                        <Grid.Column style={noBorder} width={4} textAlign={"center"} className={"neutral700Text"}>
                            {(!!currentPlan.coveredFamilyMembers && currentPlan.coveredFamilyMembers.length > 0)
                                && <>
                                    {currentPlan.coveredFamilyMembers.map((dependent, index) =>
                                        <span key={index}><b>{(index ? ', ' : '') + `${dependent}`}</b></span>
                                    )}
                                </>}
                        </Grid.Column>
                    }
                    <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"} className={areDependentsDifferent ? "planChanged": ''}>
                        <span className={"darkOrangeHeader"}>
                            {areDependentsDifferent && <Icon name={"circle"}/>}{(!!upcomingCoverage.coveredFamilyMembers && upcomingCoverage.coveredFamilyMembers.length > 0)
                            && <>{upcomingCoverage.coveredFamilyMembers.map((dependent, index) => <span key={index}><b>{(index ? ', ' : '') + `${dependent}`}</b></span>)}</>}
                        </span>
                    </Grid.Column>

                    {(currentPlan.employerContribution !== 0 || upcomingCoverage.employerContribution !== 0) ? (
                        <>
                            <Grid.Column width={showCurrent ? 4 : 5} className={showCurrent ? 'neutral700Text bkgNeutral50' : 'neutral700Text'} style={noBorder}>
                                <b>Employer contribution</b>
                            </Grid.Column>
                            {showCurrent &&
                            <Grid.Column style={noBorder} width={4} textAlign={"center"} className={"bkgNeutral50"}>
                                <Header as={'h4'} className={"neutral700Text"}>
                                    ${formatMoneyAmount(currentPlan.employerContribution)}
                                </Header>
                            </Grid.Column>}
                            <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"}
                                         className={isErContribDifferent ? "planChanged" : 'bkgNeutral50'}>
                                <Header as={'h4'}>{isErContribDifferent && <Icon
                                    name={"circle"}/>}${formatMoneyAmount(upcomingCoverage.employerContribution)}</Header>
                            </Grid.Column>
                        </>) : null}

                    <Grid.Column width={showCurrent ? 4: 5} style={noBorder}>
                        {showCurrent ?
                            <span className={"neutral700Text"}>
                                <b>Your cost</b>
                            </span>
                            :
                            <Header as={'h3'} className={"neutral700Text"}>
                                Your cost {showTooltip && <Popup position={'top left'} on={'hover'} trigger={infoIcon}>
                                <span className={"smaller"}>
                                    {tooltipText}
                                </span>
                        </Popup>}
                        </Header>}
                    </Grid.Column>
                    {showCurrent && <Grid.Column style={noBorder} width={4} textAlign={"center"}>
                        <Header as={'h3'} className={"neutral700Text"}>
                                ${formatMoneyAmount(currentPlan.costWithErContribution)} / {currentPayrollFrecuencyLabel}
                        </Header>
                    </Grid.Column>
                    }
                    <Grid.Column width={showCurrent ? 4 : 7} textAlign={"center"} className={isCostDifferent ? "planChanged" : ''}>
                        <Header as={'h3'}>
                            {(isCostDifferent || isFrecuencyDifferent) &&
                                <Icon name={"circle"}/>}${formatMoneyAmount(upcomingCoverage.costWithErContribution)} / {upcomingPayrollFrecuencyLabel}
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default connect(mapStateToProps)(withRouter(CoverageComparisonTable));
