import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import Lookup from "./components/lookup";
import Transcribe from "./components/transcribe";
import Places from "./components/places";
import Claims from "./components/claims/claims";
import Interstitial from "./components/claims/newClaim/interstitial";
import WhatHappened from "./components/claims/newClaim/whatHappened";
import TreatmentInfo from "./components/claims/newClaim/treatmentInfo";
import UploadInstructions from "./components/claims/newClaim/uploadInstructions";
import Uploads from "./components/claims/newClaim/uploads";
import {Provider} from "react-redux";
import {store} from "./store"
import ConfirmUploads from "./components/claims/newClaim/confirmUploads";
import Review from "./components/claims/newClaim/review";
import NewClaimSuccess from "./components/claims/newClaim/newClaimSuccess";
import Login from "./components/pages/login";
import MemberContainer from "./components/memberContainer";
import Config from "./components/config";
import PrivateRoute from "./components/privateRoute";
import PlanInfo from "./components/planInfo/planInfo";
import PaymentPreference from "./components/claims/newClaim/paymentPreference";
import Settings from "./components/settings/settings";
import MyInfo from "./components/myInfo/myInfo";
import ClaimDetails from "./components/claims/claimDetails";
import GenericConsent from "./components/consent/genericConsent";
import DocuSignConsent from "./components/consent/docuSignConsent";
import PrepareApp from "./components/prepareApp";
import ScrollToTop from "./components/scrollToTop";
import VerifyItsYou from "./components/verifyItsYou";
import CreatePassword from "./components/createPassword";
import ExpiredToken from "./components/expiredToken";
import Welcome from "./components/welcome";
import Register from "./components/register";
import VerifyEmail from "./components/verifyEmail";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import PasswordResetRequestSent from "./components/passwordResetRequestSent";
import RequestSent from "./components/requestSent";
import Change from "./components/planInfo/change/change";
import QLEWhatHappened from "./components/planInfo/change/qle/whatHappened";
import QLEWhen from "./components/planInfo/change/qle/when";
import NotEligible from "./components/planInfo/change/qle/notEligible";
import AddDependent from "./components/planInfo/change/qle/addDependent";
import HaveStepChildren from "./components/planInfo/change/qle/haveStepChildren";
import AddStepChildren from "./components/planInfo/change/qle/addStepChildren";
import AddOtherDependent from "./components/planInfo/change/update/addOtherDependent";
import AddDependentSuccess from "./components/planInfo/change/update/success";
import UpdateCoverage from "./components/planInfo/change/update/updateCoverage";
import HealthPlanDetails from "./components/planInfo/change/update/healthPlanDetails";
import PlanSelect from "./components/planInfo/change/update/planSelect";
import PlanChoice from "./components/planInfo/change/update/planChoice";
import MessagesList from "./components/messages/messagesList";
import {
  ADD_DEPENDENT_ROUTE,
  ADD_OTHER_DEPENDENT_ROUTE,
  ADD_STEP_CHILDREN_ROUTE,
  AOE_CONFIRM_PLAN_CHANGES_ROUTE,
  AOE_END_COVERAGE,
  AOE_HEALTH_PLAN_DETAILS_ROUTE,
  AOE_INCOME_ROUTE,
  AOE_REVIEW_FAMILY_ROUTE,
  AOE_REVIEW_PERSONAL_INFO_ROUTE,
  AOE_REVIEW_PLAN_ROUTE,
  AOE_REVIEW_SUCCESS_ROUTE,
  AOE_REVIEW_WOULD_YOU_MIND_ROUTE,
  AOE_SELECT_PLAN_ROUTE,
  AOE_SUCCESS_ROUTE,
  BRELLA_CHOICE_ROUTE,
  BRELLA_SELECT_ROUTE,
  CHANGE_ADDRESS_FORM_ROUTE,
  CHANGE_ADDRESS_ROUTE,
  CHANGE_ROUTE,
  CONFIRM_ADDRESS_CHANGE_ROUTE,
  CONFIRM_CHILD_REMOVAL,
  CONFIRM_DISSOLUTION,
  ELIGIBILITY_LOSS,
  ENROLLMENT_ENDED,
  HAVE_STEP_CHILDREN_ROUTE,
  HEALTH_PLAN_DETAILS_ROUTE,
  INCOME_ROUTE,
  PAYMENT_REQUIRED,
  REMOVE_DEPENDENT,
  SUCCESS_ADDRESS_CHANGE_ROUTE,
  SUCCESSFUL_PAYMENT,
  UPDATE_COVERAGE_ROUTE,
  WHEN_ROUTE,
  LOGIN_ROUTE,
  BETTER_ON_THE_APP_ROUTE,
  STATE_SPECIFIC_CONSENT_ROUTE,
  GENERIC_CONSENT_ROUTE,
  DOCUSIGN_CONSENT_ROUTE,
  PLAN_ROUTE,
} from "./utils/common";
import DisposableIncome from "./components/planInfo/change/update/disposableIncome";
import Confirm from "./components/planInfo/change/update/confirm";
import PlanChangeSuccess from "./components/planInfo/change/update/planChangeSuccess";
import RemoveChild from "./components/planInfo/change/qle/removeChild";
import ConfirmChildRemoval from "./components/planInfo/change/qle/confirmChildRemoval";
import ConfirmDeathDisolutionDivorce from "./components/planInfo/change/qle/confirmDeathDisolutionDivorce";
import ChangeAddress from "./components/planInfo/change/qle/changeAddress";
import ChangeAddressForm from "./components/planInfo/change/qle/changeAddressForm";
import ConfirmAddressChange from "./components/planInfo/change/qle/confirmAddressChange";
import ChangeAddressSuccess from "./components/planInfo/change/qle/changeAddressSuccess";
import BenAdminAlert from "./components/planInfo/change/benAdminAlert";
import MessagesProvider from './contexts/messages'
import Toasts from './components/toasts/toasts'
import SuccessfulPayment from "./components/successfulPayment";
import PaymentRequired from "./components/paymentRequired";
import ReviewPlan from "./components/anualOpenEnrollment/reviewPlan";
import ReviewPersonalInfo from "./components/anualOpenEnrollment/reviewPersonalInfo";
import ReviewFamily from "./components/anualOpenEnrollment/reviewFamily";
import Success from "./components/anualOpenEnrollment/success";
import WouldYouMind from "./components/anualOpenEnrollment/wouldYouMind";
import AnualOpenEnrollmentRoute from "./components/anualOpenEnrollment/aOERoute";
import AOEHealthPlanDetails from "./components/anualOpenEnrollment/healthPlanDetails";
import AOEDisposableIncome from "./components/anualOpenEnrollment/disposableIncome";
import SelectPlan from "./components/anualOpenEnrollment/plan";
import ThanksForReview from "./components/anualOpenEnrollment/thanksForReview";
import ConfirmPlan from "./components/anualOpenEnrollment/confirmPlan";
import EndCoverage from "./components/anualOpenEnrollment/endCoverage";
import EligibilityLoss from "./components/eligibilityLoss";
import EnrollmentEnded from "./components/enrollmentEnded";
import Billing from "./components/billing/billing";
import InvoiceDetails from "./components/billing/invoiceDetails";
import StripeWrapper from "./components/stripeWrapper";
import MobileDetector from "./components/mobile/mobileDetector";
import MobileAppDialog from "./components/mobile/mobileAppDialog";
import { theme, ThemeProvider } from './mfa/design-system';
import MfaAuth from "./components/mfaAuth"
import EconsentInterstitial from './components/consent/interstitial'
import {axiosInit} from './utils/axios';
import {
  MFA_ROUTE
} from "./utils/common";


import './app.css'

import handleRumSetup from "./datadog/rum";

handleRumSetup();

axiosInit()

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
        <MobileDetector>
          <Config>
            <Switch>
              <Redirect exact from={"/"} to={LOGIN_ROUTE} />
              <Route exact path={LOGIN_ROUTE} component={Login} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/passwordResetRequestSent" component={PasswordResetRequestSent} />
              <Route exact path="/resetPassword" component={ResetPassword} />
              <Route exact path="/verify"
                render={() => <VerifyItsYou nextRoute={"/createPassword"} />} />
              <Route exact path="/createPassword"
                render={() => <CreatePassword />} />
              <Route exact path={BETTER_ON_THE_APP_ROUTE} render={(props) => <MobileAppDialog {...props} nextRoute={"/login"} />} />
              <Route exact path="/welcome" render={() => <Welcome nextRoute={"/login"} />} />
              <Route exact path="/expired" render={() => <ExpiredToken />} />
              <Route exact path="/requestSent" render={() => <RequestSent />} />
              <Route exact path="/expiredRegisterToken" render={() => <Register title="Link has expired" />} />
              <Route exact path="/register" render={() => <Register />} />
              <Route exact path="/verifyEmail" render={() => <VerifyEmail />} />
              <PrivateRoute exact path={MFA_ROUTE} component={MfaAuth}/>
              <PrivateRoute exact path={DOCUSIGN_CONSENT_ROUTE} component={DocuSignConsent} />
              <PrepareApp>
                <ScrollToTop />
                <MessagesProvider>
                  <MemberContainer>
                    <PrivateRoute exact path={SUCCESSFUL_PAYMENT} component={SuccessfulPayment} />
                    <PrivateRoute exact path={ELIGIBILITY_LOSS} component={EligibilityLoss} />
                    <PrivateRoute exact path={ENROLLMENT_ENDED} component={EnrollmentEnded} />
                    <PrivateRoute exact path={STATE_SPECIFIC_CONSENT_ROUTE} component={EconsentInterstitial} />
                    <PrivateRoute exact path={GENERIC_CONSENT_ROUTE} component={GenericConsent} />
                    <PrivateRoute exact path={PLAN_ROUTE} component={PlanInfo} />
                    <PrivateRoute exact path="/benAdmin" component={BenAdminAlert} />
                    <PrivateRoute exact path={CHANGE_ROUTE} component={Change} />
                    <PrivateRoute exact path="/change/notEligible" component={NotEligible} />
                    <PrivateRoute exact path="/change/qle" component={QLEWhatHappened} />
                    <PrivateRoute exact path={WHEN_ROUTE} component={QLEWhen} />
                    <PrivateRoute exact path={ADD_DEPENDENT_ROUTE} component={AddDependent} />
                    <PrivateRoute exact path={REMOVE_DEPENDENT} component={RemoveChild} />
                    <PrivateRoute exact path={CONFIRM_CHILD_REMOVAL} component={ConfirmChildRemoval} />
                    <PrivateRoute exact path={CONFIRM_DISSOLUTION}
                      component={ConfirmDeathDisolutionDivorce} />
                    <PrivateRoute exact path={HAVE_STEP_CHILDREN_ROUTE} component={HaveStepChildren} />
                    <PrivateRoute exact path={ADD_STEP_CHILDREN_ROUTE} component={AddStepChildren} />
                    <PrivateRoute exact path={CHANGE_ADDRESS_ROUTE} component={ChangeAddress} />
                    <PrivateRoute exact path={CHANGE_ADDRESS_FORM_ROUTE} component={ChangeAddressForm} />
                    <PrivateRoute exact path={CONFIRM_ADDRESS_CHANGE_ROUTE}
                      component={ConfirmAddressChange} />
                    <PrivateRoute exact path={SUCCESS_ADDRESS_CHANGE_ROUTE}
                      component={ChangeAddressSuccess} />
                    <PrivateRoute exact path={ADD_OTHER_DEPENDENT_ROUTE} component={AddOtherDependent} />
                    <PrivateRoute exact path="/change/update/success" component={AddDependentSuccess} />
                    <PrivateRoute exact path={UPDATE_COVERAGE_ROUTE} component={UpdateCoverage} />
                    <PrivateRoute exact path={HEALTH_PLAN_DETAILS_ROUTE} component={HealthPlanDetails} />
                    <PrivateRoute exact path={INCOME_ROUTE} component={DisposableIncome} />
                    <PrivateRoute exact path={BRELLA_SELECT_ROUTE} component={PlanSelect} />
                    <PrivateRoute exact path={BRELLA_CHOICE_ROUTE} component={PlanChoice} />
                    <PrivateRoute exact path="/change/update/confirm" component={Confirm} />
                    <PrivateRoute exact path="/change/update/planChangeSuccess"
                      component={PlanChangeSuccess} />

                    <PrivateRoute exact path="/messages" component={MessagesList} />

                    <PrivateRoute exact path="/settings" component={Settings} />
                    <PrivateRoute exact path="/info" component={MyInfo} />
                    <PrivateRoute exact path="/claims/new" component={Interstitial} />
                    <PrivateRoute exact path="/claims/new/whatHappened" component={WhatHappened} />
                    <PrivateRoute exact path="/claims/new/treatmentInfo" component={TreatmentInfo} />
                    <PrivateRoute exact path="/claims/new/uploadInstructions"
                      component={UploadInstructions} />
                    <PrivateRoute exact path="/claims/new/uploads" component={Uploads} />
                    <PrivateRoute exact path="/claims/new/confirmUploads" component={ConfirmUploads} />
                    <PrivateRoute exact path="/claims/new/payment" component={PaymentPreference} />
                    <PrivateRoute exact path="/claims/new/review" component={Review} />
                    <Route exact path="/lookup" component={Lookup} />
                    <Route exact path="/transcribe" component={Transcribe} />
                    <Route exact path="/places" component={Places} />
                    <PrivateRoute exact path="/claims" component={Claims} />
                    <PrivateRoute exact path="/claims/newClaimSuccess" component={NewClaimSuccess} />
                    <PrivateRoute exact path="/claims/details" component={ClaimDetails} />

                    <AnualOpenEnrollmentRoute exact path={AOE_REVIEW_PLAN_ROUTE} component={ReviewPlan} nextRoute={AOE_REVIEW_PERSONAL_INFO_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_REVIEW_PERSONAL_INFO_ROUTE} component={ReviewPersonalInfo} prevRoute={AOE_REVIEW_PLAN_ROUTE} nextRoute={AOE_REVIEW_FAMILY_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_REVIEW_FAMILY_ROUTE} component={ReviewFamily} prevRoute={AOE_REVIEW_PERSONAL_INFO_ROUTE} nextRoute={AOE_REVIEW_SUCCESS_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_REVIEW_SUCCESS_ROUTE} component={ThanksForReview} prevRoute={AOE_REVIEW_FAMILY_ROUTE} nextRoute={AOE_REVIEW_WOULD_YOU_MIND_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_REVIEW_WOULD_YOU_MIND_ROUTE} component={WouldYouMind} prevRoute={AOE_REVIEW_SUCCESS_ROUTE} nextRoute={AOE_HEALTH_PLAN_DETAILS_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_HEALTH_PLAN_DETAILS_ROUTE} component={AOEHealthPlanDetails} prevRoute={AOE_REVIEW_WOULD_YOU_MIND_ROUTE} nextRoute={AOE_INCOME_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_INCOME_ROUTE} component={AOEDisposableIncome} prevRoute={AOE_HEALTH_PLAN_DETAILS_ROUTE} nextRoute={AOE_SELECT_PLAN_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_SELECT_PLAN_ROUTE} component={SelectPlan} prevRoute={AOE_INCOME_ROUTE} nextRoute={AOE_CONFIRM_PLAN_CHANGES_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_CONFIRM_PLAN_CHANGES_ROUTE} component={ConfirmPlan} prevRoute={AOE_SELECT_PLAN_ROUTE} nextRoute={AOE_SUCCESS_ROUTE} />
                    <AnualOpenEnrollmentRoute exact path={AOE_SUCCESS_ROUTE} component={Success} />
                    <AnualOpenEnrollmentRoute exact path={AOE_END_COVERAGE} component={EndCoverage} prevRoute={AOE_SELECT_PLAN_ROUTE} />

                    <StripeWrapper>
                      <PrivateRoute exact path={PAYMENT_REQUIRED} component={PaymentRequired} />
                      <PrivateRoute exact path="/billing" component={Billing} />
                      <PrivateRoute exact path={"/invoice/:id"} component={InvoiceDetails} prevRoute={"/billing"} />
                    </StripeWrapper>

                    <Route exact path='/'><Redirect to='/plan' /></Route>
                  </MemberContainer>
                </MessagesProvider>
              </PrepareApp>
            </Switch>
          </Config>
        </MobileDetector>
      </BrowserRouter>
    <Toasts />
    </Provider>
  </ThemeProvider>
)

export default App
