import React, {Component} from "react"
import {Divider, Grid, Image, Loader, Message, Header} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import {ADD_OTHER_DEPENDENT_ROUTE, resolveErrorMessage} from "../../../../utils/common";
import axios from "axios";
import {setInitialData} from "../../../../actions/initialDataActions";
import {clearPlanChange} from "../../../../actions/planChangeActions";

const mapStateToProps = (state, ownProps) => {
    const {auth} = state;
    return {
        ...ownProps,
        contactSfId: !!auth.loginInfo ? auth.loginInfo.contactSfId : null,
        planChangeDependents: state.planChange.planChangeDependents
    }
};

class PlanChangeSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
        }
    }

    onNext = async (nextRoute) => {
        this.props.dispatch(clearPlanChange())
        this.setState({loading: true})
        try {
            const result = await axios.post("/api/member/v2/prepareApp", null, {headers: {'Content-Type': 'application/json'}});
            const data = result.data;
            let user = null;

            const userIsCertificateHolder = this.props.contactSfId === data.certificateHolder.id;
            if (userIsCertificateHolder) {
                user = data.certificateHolder
            } else if (!!data.dependents && data.dependents.length > 0) {
                const dependent = data.dependents.filter(dependent => dependent.id === this.props.contactSfId)
                user = dependent[0]
            }

            const payload = {
                dependents: data.dependents,
                employer: data.employer,
                user: user,
                certificateHolder: data.certificateHolder,
                benefits: data.certificate.assetCoverages,
                doctorsOfficeOptions: data.doctorsOfficeOptions,
                urgentCareOptions: data.urgentCareOptions,
                facilityOptions: data.facilityOptions,
                documents: data.documents,
                cost: data.cost,
                employerContribution: data.employerContribution,
                payrollFrequency: data.payrollFrequency,
                certificate: data.certificate,
                userIsCertificateHolder: userIsCertificateHolder,
                qleReasonOptions: data.qleReasonOptions,
                contract: data.contract,
                increments: data.increments,
                eligibility: data.certificate.ratedClass.contractTerms.eligibility,
                quoteInfo: data.quoteInfo,
                employerContributions: data.employerContributions,
                presets: data.certificate.ratedClass.contractTerms.presets
            }
            this.props.dispatch(setInitialData(payload))
            this.props.history.replace(nextRoute)
        } catch (e) {
            this.setState({
                    error: resolveErrorMessage(e, "An unexpected error ocurred."),
                    loading: false
                }
            )
        }
    }

    render() {
        const {error, loading} = this.state;
        const {planChangeDependents} = this.props;
        const hasAddedDependentsOutsideQle = planChangeDependents.filter(dependent => dependent.isQle === false).length > 0;
        if (error) {
            return <CustomContainer basic padded><Message negative>{error}</Message></CustomContainer>
        } else if (loading) {
            return <Loader active={true}/>
        } else {
            return (
                <React.Fragment>
                    <FullScreenPageMenu center={"Change my benefits"}/>
                    <div className="member-main-container"
                         style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
                        <Grid container stackable columns={1} centered style={{alignItems: 'center', flex: 1}}>
                            <Grid.Column width={8} className={"pageContent"}>
                                <Grid stackable container style={{marginTop: '3em'}} textAlign={'center'}>
                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <Image centered style={{width: '70px'}}
                                                   src="https://brella-platform-assets.s3.amazonaws.com/enrollment/images/success.svg"/>
                                            <Header as={"h2"}>Success!</Header>
                                            <p>Your elections have been submitted to Brella</p>
                                            <Divider hidden/>

                                            {hasAddedDependentsOutsideQle ?
                                                <React.Fragment>
                                                    <p><span className={"linkText"} onClick={() => {
                                                        this.onNext("/plan")
                                                    }}>Go back to your account</span></p>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <p>You can also add other family members not related to your
                                                        qualifying life
                                                        event. If you’d like to continue <span className={"linkText"}
                                                                                               onClick={() => {
                                                                                                   this.onNext(ADD_OTHER_DEPENDENT_ROUTE)
                                                                                               }}>add other family members.</span>
                                                    </p>
                                                    <Divider hidden/>
                                                    <Divider hidden/>
                                                    <Divider hidden/>
                                                    <p>or <span className={"linkText"} onClick={() => {
                                                        this.onNext("/plan")
                                                    }}>go back to your account</span></p>
                                                </React.Fragment>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        <Footer
                            showTopFooter={false} showCenterData={true}
                        />
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps)(PlanChangeSuccess));
