import {Grid, Button} from "semantic-ui-react";
import React from "react";
import {PaymentDetailsContext} from "./PaymentDetailsContainer";
import {ConfirmDifferentPaymentMethodModal} from "../confirmDifferentPaymentMethodModal";
import AutoPayBox from "./autoPayBox";
import CreditCardInfo from "./creditCardInfo";
import BankAccountInfo from "./bankAccountInfo";
import YourCosts from "./yourCosts";
import ConfirmPaymentModal from "../confirmPaymentModal";
import PaymentProcessing from "./paymentProcessing";
import MicroDepositsBankAccount from "./microDepositsBankAccount"

const PaymentMethodSelected = ({cost, formattedCostPayment, employerContribution, removeBankAccount, strings, setPlaidData}) => {
    const {
        isBankAccountPmSelected,
        isCreditCardPmSelected,
        isMicroDepositsBankAccountSelected,
        bankAccountPmData,
        hasEPaymentFailed,
        removePm,
        makePaymentLoading,
        microDepositsBankAccountPmData,
        removeMicroDepositsBankAccount,
        setOpenRemovePendingConfirmationModal,
        bankAccountVerificationStatus,
        isBankAccountManuallyVerified,
    } = PaymentDetailsContext.useContainer()

    const [displayConfirmPaymentModal, setDisplayConfirmPaymentModal] = React.useState(false);
    const [displayConfirmDifferentPaymentMethodModal, setDisplayConfirmDifferentPaymentMethodModal] = React.useState(false);

    const paymentButtonAvailable = (isCreditCardPmSelected || isBankAccountPmSelected) &&
        (!microDepositsBankAccountPmData || bankAccountVerificationStatus === "Verified")

    const showUseDifferentPaymentMethod = hasEPaymentFailed || isBankAccountManuallyVerified() || isMicroDepositsBankAccountSelected()

    const handleUseDifferentPaymentMethod = () => {
        if(isMicroDepositsBankAccountSelected() && bankAccountVerificationStatus === "Pending verification"){
            setOpenRemovePendingConfirmationModal(true)
        }else if (isMicroDepositsBankAccountSelected() && bankAccountVerificationStatus === "Unable to verify") {
            removeMicroDepositsBankAccount()
        }else{
            setDisplayConfirmDifferentPaymentMethodModal(true)
        }
    }

    const handleConfirmDifferentPaymentMethod = () => {
      setDisplayConfirmDifferentPaymentMethodModal(false)

      if(bankAccountPmData) {
        removeBankAccount(bankAccountPmData.paymentMethodId)
      }

      removePm()
    }

    return (
        <>
            <PaymentProcessing loading={makePaymentLoading}/>

            <ConfirmDifferentPaymentMethodModal
                strings={strings.confirmRemovePendingVerification}
                show={displayConfirmDifferentPaymentMethodModal}
                handleSubmit={handleConfirmDifferentPaymentMethod}
                handleClose={() => setDisplayConfirmDifferentPaymentMethodModal(false)}
            />

            <Grid.Row columns={2}>
                <Grid.Column style={{paddingLeft: 0}}>
                    {isCreditCardPmSelected() && <CreditCardInfo/>}
                    {isBankAccountPmSelected() &&
                        <BankAccountInfo
                            removeBankAccount={removeBankAccount}
                            data={bankAccountPmData}
                        />
                    }
                    {  isMicroDepositsBankAccountSelected() &&
                        <MicroDepositsBankAccount
                            data={microDepositsBankAccountPmData}
                            strings={strings}
                            setPlaidData={setPlaidData}
                        />
                    }
                    <div style={{padding: 0, borderTop: "1px dashed lightgray", paddingTop: "15px"}}>
                        <YourCosts cost={cost}
                                   employerContribution={employerContribution}
                                   formattedCostPayment={formattedCostPayment} />
                    </div>
                    { paymentButtonAvailable &&
                        <Button style={{width: "100%"}} primary loading={makePaymentLoading}
                              onClick={() => setDisplayConfirmPaymentModal(true)}>
                          Make a payment
                        </Button>
                    }
                    { showUseDifferentPaymentMethod &&
                    <div style={{marginTop: 15}}>
                        <a href className={'vibranBlueHeader'} onClick={handleUseDifferentPaymentMethod}>Use different payment method</a>
                    </div>
                    }
                </Grid.Column>
                <Grid.Column style={{paddingRight: 0}}>
                    <AutoPayBox strings={strings} />
                </Grid.Column>
            </Grid.Row>
            {!isMicroDepositsBankAccountSelected() &&
                <ConfirmPaymentModal
                    displayModal={displayConfirmPaymentModal}
                    onClose={() => setDisplayConfirmPaymentModal(false)}
                    paymentAmount={cost}
                    strings={strings}
                />
            }
        </>
    )
}

export default PaymentMethodSelected;
