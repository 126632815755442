import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import {convertDateToLongFormat, REMOVE_DEPENDENT, UPDATE_COVERAGE_ROUTE,} from "../../../../utils/common";
import moment from "moment";
import {setTotalDependents} from "../../../../actions/planChangeActions";
import STRATEGIC_PARTNER from "../../../../constants/strategicPartners";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        planChangeDependents: state.planChange.planChangeDependents,
        dependents: state.initialData.dependents,
        qleDate: state.planChange.qleDate
    }
};

class ConfirmChildRemoval extends Component {

    onBackHandler = () => {
        this.props.history.replace(REMOVE_DEPENDENT);
    }

    onNext = () => {
        this.props.history.push(UPDATE_COVERAGE_ROUTE)
    }

    submit = () => {
        const {planChangeDependents, dependents} = this.props;
        const totalDependents = dependents.filter(item => !planChangeDependents.includes(item));
        this.props.dispatch(setTotalDependents(totalDependents));
    }

    render() {
        const {planChangeDependents, qleDate} = this.props;
        const button = <Button primary content="Next step" onClick={this.onNext}/>;

        return (
            <React.Fragment>
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={1}/>
                    <Grid container stackable columns={1} centered>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as={"h2"}>Review and confirm</Header>
                                <p className={"neutral700Text"}>Please review the following information before finalizing—</p>

                                <CustomContainer padded basic className={"bkgLinen"}>
                                    {STRATEGIC_PARTNER.LABEL} coverage ends on <b>{convertDateToLongFormat(qleDate)}</b>
                                </CustomContainer>
                                <Divider hidden/>
                                <p><b>Coverage will be terminated for the following members</b></p>
                                {(!!planChangeDependents && planChangeDependents.length > 0) && planChangeDependents.map((dependent => {
                                    const mNow = moment(Date.now());
                                    const mDate = moment(dependent.birthDate)
                                    const age = mNow.diff(mDate, "years")
                                    return <CustomContainer>
                                        <b>{dependent.firstName} {dependent.middleName ? dependent.middleName + ' ' : null}{dependent.lastName}</b><br/>
                                        {dependent.relToEmployee}, Age {age}
                                    </CustomContainer>
                                }))}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ConfirmChildRemoval));
