import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Checkbox, Divider, Form, FormGroup, Grid, Modal, Segment, Button, Header} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {connect} from "react-redux";
import Footer from "../../../footer";
import FullScreenPageMenu from "../../../fullScreenPageMenu";
import ChangeSteps from "../changeSteps";
import moment from "moment";
import {convertDisplayDateFormatToServer, isCoveredState, UPDATE_COVERAGE_ROUTE,} from "../../../../utils/common";
import ErrorInput from "../../../../components/errorInput";
import FormSelectState from "../../../../components/formSelectState";
import {setDependent} from "../../../../actions/planChangeActions";
import {CustomModalHeader, DOBInput} from '../../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        qleDate: state.planChange.qleDate,
        qleReason: state.planChange.qleReason,
        planChangeDependents: state.planChange.planChangeDependents,
        stateOptions: state.planChange.states,
        genderOptions: state.planChange.genderOptions,
        childMaxEligibilityAge: state.initialData.contract.childMaxEligibilityAge,
        nameSuffixOptions: state.planChange.nameSuffixOptions
    }
};

class AddStepChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            validationErrorsMap: new Map(),
            invalidZipCode: false,
            invalidDepZipCode: false,
            curDependent: {
                showSpouseWarning: false,
                displayChildEligibilityAgreement: false,
                selectedChildButNotAvailable: false,
                selectedSpouseButNotAvailable: false,
                selectedRelationship: '',
                firstName: '',
                middleName: '',
                suffix: '',
                lastName: '',
                birthdate: '',
                gender: '',
                addressSameAsEmployee: true,
                ssn: '',
                relationship: '',
                streetAddress: '',
                appartment: '',
                zipCode: '',
                city: '',
                state: '',
                userAgrees: false,
            },
            showDependentsSubmitErrorMessage: false
        }
    }

    openModal = () => {
        let curDependent = {
            firstName: '',
            middleName: '',
            suffix: '',
            lastName: '',
            relationship: 'Child',
            gender: '',
            ssn: '',
            birthdate: '',
            addressSameAsEmployee: true,
            streetAddress: '',
            appartment: '',
            zipCode: '',
            city: '',
            state: '',
            userAgrees: false,
            showSpouseWarning: false,
            displayChildEligibilityAgreement: false,
            selectedChildButNotAvailable: false,
            selectedSpouseButNotAvailable: false,
            selectedRelationship: '',
        }

        this.setState({
            showModal: true,
            curDependent: curDependent,
            showDependentsSubmitErrorMessage: false
        })
    }

    onBackHandler = () => {
        this.props.history.replace("/change/qle/haveStepChildren");
    }

    handleDependentFormChange = (event, {value, name}) => {
        if (name === "zipCode") {
            const valNum = parseInt(value, 10);
            if (isNaN(valNum) && value !== "") {
                return;
            }
        }
        this.setState(
            {
                curDependent: {
                    ...this.state.curDependent,
                    [name]: value
                }
            })
    }

    onNext = () => {
        this.props.history.push(UPDATE_COVERAGE_ROUTE)
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            curDependent: {}
        })
    }

    renderDependentForm = () => {
        const {curDependent, invalidDepZipCode} = this.state;
        const {stateOptions, genderOptions, childMaxEligibilityAge, nameSuffixOptions} = this.props;
        const {
            relationship, firstName, lastName, birthdate, gender, addressSameAsEmployee, ssn, streetAddress, appartment, city, state, zipCode, userAgrees,
            displayChildEligibilityAgreement, selectedChildButNotAvailable, selectedSpouseButNotAvailable, middleName, suffix,
        } = curDependent;

        const error = this.validAddForm();

        const isChild = relationship === "Child";
        const disableFields = selectedChildButNotAvailable || selectedSpouseButNotAvailable;
        const invalidState = !addressSameAsEmployee && !!state && !isCoveredState(state);
        return (
            <Form>
                <Header as="h4">Role</Header>
                <Form.Input value={relationship} fluid width={12}
                            name={"relationship"}
                            required label={"Relationship to employee"}
                            readOnly
                />

                <Header as="h4">Details</Header>
                <Form.Group widths="equal">
                    <ErrorInput fluid required={true}
                                label="First name"
                                name='firstName'
                                disabled={disableFields}
                                value={!firstName ? '' : firstName}
                                onChange={this.handleDependentFormChange}/>

                    <ErrorInput fluid
                                label="Middle name"
                                name='middleName'
                                         disabled={disableFields}
                                         value={!middleName ? '' : middleName}
                                         onChange={this.handleDependentFormChange}/>
                </Form.Group>

                <Form.Group widths="equal">
                    <ErrorInput fluid required={true}
                                         label="Last name"
                                         name='lastName'
                                         disabled={disableFields}
                                         value={!lastName ? '' : lastName}
                                         onChange={this.handleDependentFormChange}/>

                    <Form.Select value={!suffix ? '' : suffix}
                                 fluid
                                 options={nameSuffixOptions} name={"suffix"}
                                 label="Suffix"
                                 onChange={this.handleDependentFormChange}/>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field required>
                        <DOBInput value={!!birthdate ? birthdate : ''} fluid
                                     name={"birthdate"}
                                     label={"Date of birth"}
                                     setFormError={(msg) => {
                                         this.setState({
                                             dobError: msg,
                                         })
                                     }}
                                     onFocus={() => {
                                         this.handleDependentFormChange(null, {
                                             value: false,
                                             name: 'displayChildEligibilityAgreement'
                                         });
                                     }}
                                     onChange={(event, {value}) => {
                                         this.handleDependentFormChange(event, {value, name: 'birthdate'});
                                     }}
                                     onBlur={() => {
                                         const age = moment().diff(birthdate, 'years');
                                         if (age >= childMaxEligibilityAge) {
                                             this.handleDependentFormChange(null, {
                                                 value: true,
                                                 name: 'displayChildEligibilityAgreement'
                                             })
                                         }
                                     }}
                                     required
                        />
                    </Form.Field>

                    <Form.Select fluid required label={"Gender"} disabled={disableFields}
                                 value={gender}
                                 name={"gender"}
                                 options={genderOptions}
                                 onChange={this.handleDependentFormChange}
                    />
                </Form.Group>

                {(!!displayChildEligibilityAgreement && !!isChild) &&
                <Form.Field>
                    <div style={{backgroundColor: '#f5f5f5', padding: '1em'}} className={"small"}>
                        <b>In order for your child over {childMaxEligibilityAge} to be covered, the following must apply—</b>
                        <ul>
                            <li>the child is incapable of self-sustaining employment because of a mental or physical disability; and</li>
                            <li>the child is chiefly dependent on You for support and maintenance; and</li>
                            <li>proof is provided of the disability upon our request (proof will be required at the time of claim).</li>
                        </ul>
                        <Checkbox label={"I agree"} checked={userAgrees} onClick={() => {
                            const newValue = this.state.curDependent;
                            newValue.userAgrees = !userAgrees
                            this.setState({
                                curDependent: {...newValue},
                            });
                        }}/>
                    </div>
                </Form.Field>
                }

                <Form.Group>
                    <Form.Input label={<label>Social Security Number</label>}
                                width={6} value={ssn} name={"ssn"}
                                disabled={disableFields}
                                onChange={(e, {value}) => {
                                    const isNumeric = value.toLocaleLowerCase() === value.toUpperCase();
                                    if (value.length <= 9 && isNumeric) {
                                        this.handleDependentFormChange(e, {name: "ssn", value: value});
                                    }
                                }}/>

                </Form.Group>
                <Header as="h4">Address</Header>
                <Form.Group>
                    <Form.Field width={6}>
                        <Checkbox label="Same as employee"
                                  name={"addressSameAsEmployee"}
                                  checked={addressSameAsEmployee}
                                  onChange={(e) => {
                                      this.handleDependentFormChange(e, {
                                          name: "addressSameAsEmployee",
                                          value: !addressSameAsEmployee
                                      })
                                  }}
                        />
                    </Form.Field>
                </Form.Group>
                {!addressSameAsEmployee &&
                <React.Fragment>
                    <FormGroup widths={'equal'}>
                        <ErrorInput fluid required={true}
                                             label="Street address"
                                             name='streetAddress'
                                             disabled={disableFields}
                                             value={!streetAddress ? '' : streetAddress}
                                             onChange={this.handleDependentFormChange}/>

                        <Form.Input label="Apartment" disabled={disableFields}
                                    value={!appartment ? '' : appartment}
                                    name={"appartment"} onChange={this.handleDependentFormChange}/>
                    </FormGroup>
                    <FormGroup widths={'equal'}>
                        <Form.Field>
                            <Form.Input label="Zip code" required
                                        value={!zipCode ? '' : zipCode}
                                        error={invalidDepZipCode}
                                        name={"zipCode"}
                                        onFocus={() => {
                                            this.setState({
                                                invalidDepZipCode: false,
                                            });
                                        }}
                                        onChange={this.handleDependentFormChange}
                                        onBlur={() => {
                                            const isError = !zipCode || zipCode.length === 0 || ((zipCode.length > 5 && zipCode.length < 9) || zipCode.length < 5 || zipCode.length > 9);
                                            this.setState({
                                                invalidDepZipCode: isError || isNaN(zipCode * 1),
                                            })
                                        }}/>
                            {!!invalidDepZipCode && <small style={{color: 'red'}}>Invalid zip code</small>}
                        </Form.Field>

                        <ErrorInput fluid required={true}
                                             label={"City"}
                                             name='city'
                                             disabled={disableFields}
                                             value={!city ? '' : city}
                                             onChange={this.handleDependentFormChange}/>
                    </FormGroup>
                    <FormGroup>
                        <FormSelectState name={"state"}
                                         initialValue={state}
                                         options={stateOptions}
                                         disabled={disableFields}
                                         onChange={this.handleDependentFormChange}/>
                    </FormGroup>
                </React.Fragment>
                }

                <Segment basic vertical clearing style={{paddingBottom: 0}}>
                    <Button primary
                            disabled={!!error || invalidState || invalidDepZipCode ||
                            (!!isChild && moment().diff(birthdate, 'years') >= childMaxEligibilityAge && !userAgrees) ||
                            (!!displayChildEligibilityAgreement && !!isChild && !userAgrees)}
                            floated={"right"}
                            onClick={() => {this.submitNewDependent()}}>
                        Save
                    </Button>
                    <Button color='grey' basic floated={"right"} onClick={this.closeModal}>Cancel</Button>
                </Segment>
            </Form>
        )
    }

    submitNewDependent = () => {
        const {curDependent} = this.state;
        const {planChangeDependents} = this.props;
        let dependentIdx = !!planChangeDependents ? planChangeDependents.length : 0;
        const dependent = {
            firstName: curDependent.firstName,
            middleName: curDependent.middleName,
            lastName: curDependent.lastName,
            suffix: curDependent.suffix,
            birthDate: convertDisplayDateFormatToServer(curDependent.birthdate),
            gender: curDependent.gender,
            ssn: curDependent.ssn,
            relToEmployee: curDependent.relationship,
            addressSameAsEmployee: curDependent.addressSameAsEmployee,
            address: !curDependent.addressSameAsEmployee ? {
                streetAddress: curDependent.streetAddress,
                appartment: curDependent.appartment,
                zipCode: curDependent.zipCode,
                city: curDependent.city,
                state: curDependent.state
            } : null,
            isQualifiedForCoverage: curDependent.userAgrees,
            isQle: true
        }
        this.props.dispatch(setDependent(dependent, dependentIdx));
        this.closeModal();
    }

    validAddForm = () => {
        const {curDependent} = this.state;
        const required = ['firstName', 'lastName', 'birthdate', 'gender'];
        let error = false;

        for (let i = 0; i < required.length; i++) {
            let item = required[i];
            if (!curDependent[item] || curDependent[item].length === 0) {
                error = true;
                break;
            }
        }

        if (!curDependent.addressSameAsEmployee) {
            const requiredIfAddress = ['streetAddress', 'zipCode', 'city', 'state'];
            for (let i = 0; i < requiredIfAddress.length; i++) {
                let item = requiredIfAddress[i];
                if (!curDependent[item] || curDependent[item].length === 0) {
                    error = true;
                    break;
                }
            }
        }

        return error;
    }

    render() {
        const {showModal} = this.state;
        const {planChangeDependents} = this.props;
        const button = <Button primary content="Next step" onClick={this.onNext}/>;

        return (
            <React.Fragment>
                {!!showModal && <Modal size={'tiny'} onClose={this.closeModal} open={showModal}>
                    <CustomModalHeader title={"Add dependent"} onClose={this.closeModal} className="customModalHeader" />
                    <Modal.Content className={"small"}>
                        {this.renderDependentForm()}
                    </Modal.Content>
                </Modal>}
                <FullScreenPageMenu onBack={this.onBackHandler} center={"Change my benefits"}/>
                <div className="member-main-container" style={{background: 'white'}}>
                    <ChangeSteps activeStepIdx={0}/>

                    <Grid container stackable columns={1} centered>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as={"h2"}>Marriage</Header>
                                <p className={"neutral700Text"}>Congratulations! Let’s update your family members—</p>
                                {(!!planChangeDependents && planChangeDependents.length > 0) && planChangeDependents.map(((dependent, i) => {
                                    const mNow = moment(Date.now());
                                    const mDate = moment(dependent.birthDate)
                                    const age = mNow.diff(mDate, "years")
                                    return <CustomContainer key={i}>
                                        <b>{dependent.firstName} {dependent.middleName ? dependent.middleName + ' ' : null}{dependent.lastName}</b><br/>
                                        {dependent.relToEmployee}, Age {age}
                                    </CustomContainer>
                                }))}
                                <Divider hidden/>
                                <p>Please add your step-children to your family members—</p>
                                <Button primary basic onClick={() => this.openModal()}>+ Add dependent</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Footer showTopFooter={true} showCenterData={true}
                            button={button}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(AddStepChildren));
