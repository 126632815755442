import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {CustomContainer} from '../custom-common';
import {Dimmer, Divider, Dropdown, Loader, Message, Header} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import PaymentMethod from "./paymentMethod";
import Plaid from "./plaid";
import axios from "axios";
import {resolveErrorMessage} from "../../utils/utils";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import CreditCardModal from "./creditCardModal";
import {getLocalization} from "../../utils/localization";
import {creditCard} from "../../utils/common";
import MicroDepositsBankAccount from "./microDepositsBankAccount";
import {deleteMicroDepositsBankAccount, setAutoPay, setMicroDepositsBankAccount, setPaymentMethods,} from "../../actions/billingActions";
import AutoPayBox from "../paymentDetails/autoPayBox";
import {PaymentDetailsContext} from "../paymentDetails/PaymentDetailsContainer";

const mapStateToProps = (state, ownProps) => {
    const strings = getLocalization(state.initialData.user.lang);
    return {
        ...ownProps,
        microDepositsBankAccounts: state.billing.microDepositsBankAccounts,
        paymentMethods: state.billing.paymentMethods,
        plaidLinkToken: state.billing.plaidLinkToken,
        plaidEnvironment: state.billing.plaidEnvironment,
        autoPay: state.billing.autoPay,
        billingAccountId: state.billing.billingAccountId,
        carrier: state.initialData.contract.carrier,
        strings
    }
};

class PaymentSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            savingPM: false,
            savingAP: false,
            error: null,
            showCCModal: false,
            showRemovePMModal: false,
            editCreditCard: false,
            current: null
        }
    }

    prepareBankAccountPayload = ({public_token, account_id, metadata, status}) => {
        const {billingAccountId, carrier} = this.props
        return {
            status,
            metadata,
            billingAccountId,
            publicToken: public_token,
            accountId: account_id,
            carrier,
        }
    }

    setPlaidData = async (data) => {
        if (data.account?.verification_status === "pending_manual_verification") {
            await this.saveMicroDepositsBankAccount(this.prepareBankAccountPayload(data))
        } else {
            await this.saveBankAccount(this.prepareBankAccountPayload(data))
        }
    }

    saveMicroDepositsBankAccount = async (payload) => {
        try {
            this.setState({savingPM: true, error: null})
            const axiosPostconfig = {headers: {'Content-Type': 'application/json'}}
            const response = await axios.post("/api/member/v2/addMicroDepositsBankAccount", payload, axiosPostconfig)
            this.props.dispatch(setMicroDepositsBankAccount(response.data.microDepositsBankAccount))
            this.setState({savingPM: false, error: null})
        } catch (err) {
            console.log(err)
            this.setState({savingPM: false, error: resolveErrorMessage(err, "An unexpected error ocurred.")})
        }
    }

    hideMicroDepositsBankAccount = (accountId) => {
        this.props.dispatch(deleteMicroDepositsBankAccount(accountId))
    }

    removeMicroDepositsBankAccount = async (accountId) => {
        try {
            this.setState({error: null})
            const axiosPostconfig = {headers: {'Content-Type': 'application/json'}}
            const payload = this.prepareBankAccountPayload({account_id: accountId})
            await axios.post(`/api/member/v2/removeMicroDepositsBankAccount`, payload, axiosPostconfig)
        } catch (e) {
            this.setState({error: resolveErrorMessage(e, "An unexpected error ocurred.")})
        }
    }

    updateMicroDepositsBankAccountStatus = async ({account_id, status, public_token}) => {
        try {
            this.setState({loading: true})
            const payLoad = this.prepareBankAccountPayload({status, account_id, public_token})
            const axiosPostconfig = {headers: {'Content-Type': 'application/json'}}
            await axios.post("/api/member/v2/updateMicroDepositsBankAccountStatus", payLoad, axiosPostconfig);
            this.setState({loading: false})
        }  catch (err) {
            this.setState({
                loading: false,
                error: resolveErrorMessage(err, "An unexpected error ocurred.")
            })
        }
    }

    saveBankAccount = async (payload) => {
        try {
            this.setState({savingPM: true, error: null})
            await axios.post("/api/member/v2/addBankAccount", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({savingPM: false, error: null})
            this.refreshPmList()
        } catch (err) {
            this.setState({savingPM: false, error: resolveErrorMessage(err, "An unexpected error ocurred.")})
        }
    }

    saveCreditCard = async (id, expiry) => {
        const {isEdit} = this.state;
        try {
            this.setState({savingPM: true, error: null})
            const save = isEdit ? 'updateCreditCard' : 'addCreditCard';
            let payload = {
                paymentMethodId: id,
                billingAccountId: this.props.billingAccountId,
            };
            if (isEdit) {
                const parts = expiry.split("/");
                payload = {
                    paymentMethodId: id,
                    expMonth: parts[0],
                    expYear: parts[1]
                }
            }
            await axios.post("/api/member/v2/" + save, payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({savingPM: false, error: null})
            this.refreshPmList()
        } catch (err) {
            this.setState({savingPM: false, error: resolveErrorMessage(err, "An unexpected error ocurred.")})
            this.closeCreditCardModal()
        }
    }

    setDefaultPaymentMethod = async (id) => {
        const {billingAccountId} = this.props
        try {
            this.setState({loading: true, error: null})
            const payload = {
                paymentMethodId: id,
                billingAccountId
            }
            await axios.post("/api/member/v2/updateDefaultPaymentMethod", payload, {headers: {'Content-Type': 'application/json'}});
            this.refreshPmList()
        } catch (e) {
            this.setState({loading: false, error: resolveErrorMessage(e, "An unexpected error ocurred.")})
        }
    }

    removePaymentMethod = async (pm) => {
        try {
            this.setState({loading: true, error: null})
            const payload = {
                paymentMethodId: pm.stripeId,
                billingAccountId: this.props.billingAccountId,
            }
            const remove = pm.type === creditCard ? "removeCreditCard" : "removeBankAccount" ;
            await axios.post("/api/member/v2/"+remove, payload, {headers: {'Content-Type': 'application/json'}});
            this.refreshPmList()
        } catch (e) {
            this.setState({loading: false, error: resolveErrorMessage(e, "An unexpected error ocurred.")})
        }
    }

    refreshPmList = async () => {
        try {
            this.setState({loading: true})
            let result = await axios.post("/api/member/v2/listPaymentMethod", {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(setPaymentMethods(result.data.paymentMethods));
            this.setState({loading: false})
        } catch (err) {
            this.setState({
                loading: false,
                error: resolveErrorMessage(err, "An unexpected error ocurred.")
            })
        }
    }

    closeCreditCardModal = () => {
        if (this.state.isEdit) {
            this.setState({isEdit: false, current: null})
        } else {
            this.setState({showCCModal: false, current: null})
        }
    }

    editCreditCard = (cc) => {
        this.setState({isEdit: true, current: cc})
    }

    onConfirmAutoPay = async (autoPay) => {
        try {
            this.setState({savingAP: true, loading: true})
            let payload = {autopay: autoPay}
            let result = await axios.post("/api/member/v2/setAutoPay", payload, {headers: {'Content-Type': 'application/json'}})
            if (result.status === 200) {
                this.props.dispatch(setAutoPay(autoPay));
            }
            this.setState({savingAP: false, loading: false})
        } catch (err) {
            this.setState({
                savingAP: false,
                loading: false,
                error: resolveErrorMessage(err, "An unexpected error ocurred.")
            })
        }
    };

    render() {
        const {
            paymentMethods,
            plaidEnvironment,
            plaidLinkToken,
            strings,
            autoPay,
            microDepositsBankAccounts,
            billingAccountId,
        } = this.props;

        const {savingPM, savingAP, error, loading, showCCModal, isEdit, current} = this.state;
        const hasPaymentMethods = () => paymentMethods && paymentMethods.length > 0;
        const hasMicroDepositBankAccounts = () => microDepositsBankAccounts && microDepositsBankAccounts.length > 0

        return (
            <>
                {(!!showCCModal || !!isEdit) &&
                    <CreditCardModal strings={strings}
                                     save={this.saveCreditCard}
                                     savingPM={savingPM}
                                     show={showCCModal || isEdit}
                                     close={this.closeCreditCardModal}
                                     error={error}
                                     isEdit={isEdit}
                                     current={current}
                    />
                }

                {error &&
                    <Grid columns='equal' as={CustomContainer} padded>
                        <Grid.Column width={12}><Message error>{error}</Message></Grid.Column>
                    </Grid>
                }

                <Grid columns='equal' as={CustomContainer} padded>
                    <Grid.Row verticalAlign={"middle"}>
                        <Grid.Column textAlign={"left"}>
                            <Header as="h4">Payment methods</Header>
                        </Grid.Column>
                        <Grid.Column textAlign={"right"}>
                            <Dropdown text='Add payment method' className='button primary' disabled={savingPM || loading}>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.setState({showCCModal: true})} style={{ lineHeight: '21px' }}>
                                        Credit Card
                                    </Dropdown.Item>
                                    {!!plaidLinkToken && <Plaid plaidEnv={plaidEnvironment}
                                                                plaidLinkToken={plaidLinkToken}
                                                                setPlaidData={this.setPlaidData}
                                                                loading={savingPM || loading}
                                                                setLoader={this.setLoader}
                                    />}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Column>
                        <p>By adding an electronic payment method you authorize Brella Services, Inc. to save the bank or financial institution information to your file for future transactions on your account. You may cancel this authorization at any time by contacting us. This authorization will  remain in effect until cancelled.</p>
                    </Grid.Column>
                        <Grid.Column width={12}>
                            {savingPM || loading ? <Loader active/> :
                                    <>
                                        { hasPaymentMethods() && paymentMethods.map((pm) => {
                                            return (
                                                <PaymentMethod key={pm.stripeId}
                                                               refreshList={this.refreshPmList}
                                                               setDefault={this.setDefaultPaymentMethod}
                                                               remove={this.removePaymentMethod}
                                                               paymentMethod={pm}
                                                               autoPay={autoPay}
                                                               pmListLength={paymentMethods.length}
                                                               editCreditCard={() => {
                                                                   this.editCreditCard(pm)
                                                               }}
                                                />
                                            )})
                                        }
                                        { hasMicroDepositBankAccounts() && microDepositsBankAccounts.map((data) => {
                                            return (
                                                <MicroDepositsBankAccount
                                                    key={data.accountId}
                                                    removeBankAccount={this.removeMicroDepositsBankAccount}
                                                    hideAccount={this.hideMicroDepositsBankAccount}
                                                    data={data}
                                                    autoPay={autoPay}
                                                    setPlaidData={this.setPlaidData}
                                                    updateStatus={this.updateMicroDepositsBankAccountStatus}
                                                />
                                            )
                                        })}
                                    </>}
                                    { (!hasPaymentMethods() && !hasMicroDepositBankAccounts()) &&
                                        <CustomContainer className={"bkgLinen"}>
                                            <Grid columns={1} textAlign={"center"}>
                                                <Grid.Column textAlign={"center"}>
                                                    <Divider hidden/>
                                                    <Icon circular name='university' className={"neutral600Text"}
                                                          style={{
                                                              fontSize: 30,
                                                              backgroundColor: "#F7D2CC",
                                                              boxShadow: "none"
                                                          }}/>
                                                </Grid.Column>
                                                <Grid.Column textAlign={"center"}>
                                                    <p style={{textAlign: "center"}}><span
                                                        className={"neutral700Text small"}><b>You have no e-payment option configured.<br/>Please set up an e-payment method.</b></span>
                                                    </p>
                                                    <Divider hidden/>
                                                </Grid.Column>
                                            </Grid>
                                        </CustomContainer>
                                    }
                                    { !hasPaymentMethods() &&
                                        <><Grid style={{marginTop: 0}}>
                                            <Grid.Column className={"small"}>
                                                <p>Prefer to mail in a check? Please download the PDF and enclose it with your payment—</p>
                                                <p>Checks should be made payable to Brella Services Inc.</p>

                                                <div className={"vibranBlueHeader"}>Mail to</div>
                                                <div>Brella Services Inc.</div>
                                                <div>P.O. Box 735880</div>
                                                <p>Dallas, TX, 75373-5880</p>

                                                <a href="https://brella-forms.s3.amazonaws.com/Brella+Claims+Overview.pdf" target={"_blank"} rel="noopener noreferrer">
                                                    Download PDF
                                                </a>
                                            </Grid.Column>
                                        </Grid></>
                                    }
                        </Grid.Column>
                    </Grid>
                {paymentMethods && paymentMethods.length > 0 && (
                    <Dimmer.Dimmable as={CustomContainer} dimmed={savingAP || loading} padded>
                        <Dimmer active={savingAP || loading} inverted><Loader active/></Dimmer>
                        <Grid.Column columns='equal'>
                            <PaymentDetailsContext.Provider initialState={{ paymentMethods, initialAutoPay: !!autoPay, billingAccountId}}>
                                <AutoPayBox strings={strings} onConfirmAutoPay={this.onConfirmAutoPay} inSettings />
                            </PaymentDetailsContext.Provider>
                        </Grid.Column>
                    </Dimmer.Dimmable>
                )}
            </>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PaymentSettings));
