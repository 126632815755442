import React, {Component} from "react"
import {Container, Grid, Image, Loader, Segment, Header} from "semantic-ui-react";
import {CustomContainer, ClickableText} from './custom-common';
import {Link} from "./base";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {resolveErrorMessage} from "../utils/common";
import STRATEGIC_PARTNER from "../constants/strategicPartners";


const mapStateToProps = (state, ownProps) => {
    const {user, initialData} = state;
    return {
        ...ownProps,
        token: initialData.token,
        resetTokenEmail: user.resetTokenEmail,
    }
};

class RequestSent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleReSend = async () => {
        // const localization = getLocalization(this.props.lang);
        // const strings = localization.expiredToken;

        const handleError = (message) => {
            this.setState({loading: false, error: true, message});
        }

        try {
            this.setState({loading: true, error: false});
            const {token, resetTokenEmail} = this.props;
            let result = await axios.post("/api/member/v2/resetToken", {email: resetTokenEmail, token});
            if (result.status === 200) {
                this.setState({loading: false});
                window.onbeforeunload = null;
                this.props.history.push("/requestSent");
            } else {
                handleError("Unexpected error");
            }
        } catch (e) {
            handleError(resolveErrorMessage(e, "Unexpected error"));
        }
    }

    render() {
        const {loading} = this.state;
        const strings = {
            title: "Your request was sent",
            descr: `Check your email for an updated link. If we have an account associated with that email, you’ll receive an email with a new link to access ${STRATEGIC_PARTNER.LABEL}.`,
            didntReceive: "Didn't receive an email?",
            either: "Either ",
            reSend: "re-send the link",
            to: " or ",
            tryDiff: "try a different email",
            stillNeed: "Still need help? Reach out to our technical support team\n(888) 300-5382 or ",
        }

        return (
            <React.Fragment>
                <Container className={'contentContainer'}>
                    <Grid container stackable centered>
                        <Grid.Column style={{marginTop: '5em'}} width={6} className={"pageContent"}>
                            <Segment style={{padding: '3em 4em 2em 4em'}}>
                                {loading ? (
                                    <Loader active={true}/>
                                ):(
                                    <React.Fragment>
                                        <Image
                                            src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/Easy-Enrollment.png"}
                                            centered style={{width: '110px'}}/>
                                        <Header as={"h3"} textAlign={"center"}>{strings.title}</Header>

                                        <p>{strings.descr}</p>

                                        <CustomContainer>
                                            <p className={"smaller"}>
                                                <b>{strings.didntReceive}</b><br/>
                                                {strings.either}
                                                <ClickableText onClick={this.handleReSend}>{strings.reSend}</ClickableText>
                                                {strings.to}
                                                <Link to={"expiredRoute"}>{strings.tryDiff}</Link>
                                            </p>
                                        </CustomContainer>
                                    </React.Fragment>
                                )}
                            </Segment>
                            <Segment basic style={{padding: '1em 4em'}}>
                                <p className={"smaller"}>
                                    {strings.stillNeed}
                                    <a href={`mailto:${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}</a>
                                </p>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(RequestSent));
