import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { Dropdown } from "semantic-ui-react";
import axios from "axios";

const Plaid = (props) => {

    const onSuccess = useCallback((_, metadata) => {
        props.setPlaidData(metadata)
    }, [props]);

    const onExit = useCallback(async (error, metadata) => {
        if(error) {
            console.log({error, metadata})
            try {
                await axios.post("/api/member/v2/plaidError", {error, metadata}, {headers: {'Content-Type': 'application/json'}});
            } catch (e) {

            }
        }
    }, []);

    const config = {
        token: props.plaidLinkToken,
        env: props.plaidEnv,
        onSuccess,
        onExit,
    };

    const {open, ready, loading} = usePlaidLink(config);

    return (
        <Dropdown.Item onClick={() => open()} style={{lineHeight: '21px'}}
                       disabled={!ready || loading || !!props.loading}>
            Bank account
        </Dropdown.Item>
    )
}

export default Plaid;
