import React from 'react';
import { Header, Input } from 'semantic-ui-react'
import {
    MFAHeading,
    MFAText,
    MFAGrid,
    MyMFAMethodContainer,
    MyMFAMethodTitle,
    MFAMethodConfigured,
    MFAMethodDescription
} from '../../styled';
import styled from 'styled-components';

import {
    MFAMethodContainer,
    MFAMethodTitle,
    MFAMethodComingSoon,
    MFAContainer,
    MFAForm,
    MFALabel
} from "../../styled";
import {MFA_TYPE} from "../../constants";

const PinGreen = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
          <circle cx="4" cy="4.5" r="4" fill="#214E41"/>
      </svg>
    )
}

const MethodItem = ({
  title,
  isItConfigured,
  disabled,
  description,
  isComingSoon
}) => {
    return (
      <MyMFAMethodContainer className={`${!isItConfigured && "shortMode"}`}>
          <MyMFAMethodTitle
            as="h5"
            method={disabled ? disabled.toString() : 'false'}
            className={disabled ? "disabled": null}
          >
              {title}
          </MyMFAMethodTitle>

          {isItConfigured && (
            <MFAMethodConfigured>
                <PinGreen />
                Configured
            </MFAMethodConfigured>
          )}

          {isComingSoon && (
            <MFAMethodComingSoon>
                Coming soon
            </MFAMethodComingSoon>
          )}

          <MFAMethodDescription className={`neutral900Text ${!isItConfigured && "shortMode"}`}>
              {description}
          </MFAMethodDescription>
      </MyMFAMethodContainer>
    )
}

const MethodItemComingSoon = ({title, disabled}) => {
    return (
      <MFAMethodContainer method={disabled.toString()}>
            <MFAMethodTitle
              as="h5"
              method={disabled.toString()}
              className={"disabled"}>
                {title}
            </MFAMethodTitle>

        <MFAMethodComingSoon>Coming soon</MFAMethodComingSoon>
      </MFAMethodContainer>
    )
}

const MethodItemComingSoonStyled = styled(MethodItemComingSoon)`
    display: ${props => props.disabled ? 'flex' : 'block'};
    color: #757575!important;
    font-weight: 400!important;
`

const MethodSelector = ({
  methodType,
  selectMethodHandler,
  isEmailConfigured,
  isTotpConfigured,
  isSmsConfigured
}) => {

    const wrapSelectMethodHandler = (disable, type) => {
        if(disable) return;
        selectMethodHandler(type)
    }

    return (
        <>
        <MFAContainer className="sectionWrapper" maxWidth={"530px"}>
            <MFAGrid>
                <MFAGrid.Row>
                    <MFAGrid.Column>
                        <MFAHeading as="h2">Select your method</MFAHeading>
                        <MFAText>Choose one of the two-factor authentication methods below—</MFAText>
                    </MFAGrid.Column>
                </MFAGrid.Row>
                <MFAGrid.Row>
                        <MFAGrid.Column>
                            <MFAForm className={'methodsList'}>
                                <MFAForm.Group grouped>
                                    <MFAGrid padded={false}>
                                        <MFAGrid.Row className="noVerticalPadding">
                                            <MFAGrid.Column>
                                                <MFAForm.Field
                                                  inline
                                                  className="methodField"
                                                  onClick={() => wrapSelectMethodHandler(isTotpConfigured, MFA_TYPE.TOTP)}
                                                >
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id="authenticator"
                                                        checked={(methodType === MFA_TYPE.TOTP && !isTotpConfigured)}
                                                        disabled={isTotpConfigured}
                                                    />
                                                    <MFALabel className="methodLabel"  htmlFor="authenticator">
                                                        <MethodItem
                                                          title={"Authenticator App"}
                                                          type={MFA_TYPE.TOTP}
                                                          isItConfigured={isTotpConfigured}
                                                          disabled={isTotpConfigured}
                                                          description="Use an authenticator app to generate authentication codes."
                                                        />
                                                    </MFALabel>
                                                </MFAForm.Field>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                        <MFAGrid.Row>
                                            <MFAGrid.Column>
                                                <Header className="methodsListHeader" as="h5">OR</Header>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                        <MFAGrid.Row className="noPadding">
                                            <MFAGrid.Column>
                                                <MFAForm.Field
                                                  inline
                                                  className="methodFieldDisabled"
                                                  onClick={() => wrapSelectMethodHandler(isEmailConfigured, MFA_TYPE.EMAIL)}
                                                >
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id='email'
                                                        checked={(methodType === MFA_TYPE.EMAIL && !isEmailConfigured)}
                                                        disabled={isEmailConfigured}
                                                        />
                                                    <MFALabel htmlFor="email">
                                                        <MethodItem
                                                          title="Email"
                                                          type={MFA_TYPE.EMAIL}
                                                          isItConfigured={isEmailConfigured}
                                                          disabled={isEmailConfigured}
                                                          description="Receive an email message containing a verification code."
                                                        />
                                                    </MFALabel>
                                                </MFAForm.Field>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                        <MFAGrid.Row className="noPadding">
                                            <MFAGrid.Column>
                                                <MFAForm.Field inline className="methodFieldDisabled"  onClick={null}>
                                                    <Input
                                                        control='input'
                                                        type='radio'
                                                        name='method'
                                                        id='sms'
                                                        checked={methodType === MFA_TYPE.SMS}
                                                        disabled />
                                                    <MFALabel>
                                                      <MethodItemComingSoonStyled title={"Text message (SMS)"} disabled={true}/>

                                                    </MFALabel>
                                                </MFAForm.Field>
                                            </MFAGrid.Column>
                                        </MFAGrid.Row>
                                    </MFAGrid>
                                </MFAForm.Group>
                            </MFAForm>
                        </MFAGrid.Column>
                    </MFAGrid.Row>
                </MFAGrid>
            </MFAContainer>
        </>
    )
}

export default MethodSelector;
