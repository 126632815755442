import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Divider, Grid, Image, Modal, Header} from "semantic-ui-react";
import {CustomContainer} from '../../../custom-common';
import {connect} from "react-redux";
import {CustomModalHeader} from '../../../custom-common';

const mapStateToProps = ({ initialData: { certificate }}, ownProps) => ({
    ...ownProps,
    contractTerms: certificate.ratedClass.contractTerms,
});

class QLEModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event: "qle",
            showModal: false
        }
    }

    onBackHandler = () => {
        this.props.history.replace("/plan");
    }

    selectEvent = (event) => {
        this.setState({
            event: event
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const {showModal, onModalClose, contractTerms} = this.props;
        const benefitWaitingPeriod = contractTerms?.benefitWaitingPeriod || null;
        return (
                <Modal onClose={this.closeModal} open={showModal} size={"large"}>
                    <CustomModalHeader title={"What is a qualifying life event?"} onClose={onModalClose} className="customModalHeader" />
                    <Modal.Content>
                        <p>A qualifying life event (QLE) is triggered by changes in your household or residence. You can make changes to your Brella plan anytime, but a QLE gives you 60 days to make changes or add members to your plan with no benefit waiting period.</p>
                        <p>Note: If you add family members to your plan outside of Open Enrollment or a QLE, they will be subject to a {benefitWaitingPeriod} day benefit waiting period. Brella will not pay any cash benefits for conditions diagnosed during the waiting period.</p>
                        <p>The most common qualifying life events include:</p>
                        <Divider hidden/>
                        <Grid columns={2} stretched>
                            <Grid.Column width={3}>
                                <CustomContainer raised style={{background: '#FAFAFA', display: 'flex', alignItems: 'center'}}>
                                    <div>
                                        <Header as={"h4"} textAlign={"center"}>Changes in household</Header>
                                        <Image style={{width: '145px'}} centered
                                               src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/QLE+Change+in+household.png"}/>
                                    </div>
                                </CustomContainer>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <div><b>- Birth or Adoption of Child</b> When a new child is born or adopted they become eligible to be covered by Brella.</div>
                                <div><b>- Gain of Child Custody</b> If you become a child’s legal guardian or are newly responsible for a child’s health care, you can typically add them to your plan. This includes grandchildren, foster children, or custody changes between divorced parents.</div>
                                <div><b>- Marriage</b> Employees who get married can typically add their spouse to their insurance plan.</div>
                                <div><b>- New Domestic Partner</b> In some groups, new partners may be added to your plan. Check with your employer or call your Brella Concierge.</div>
                                <div><b>- Divorce or Legal Separation</b> You may request a spouse or partner to be removed from your plan in the case of divorce or legal separation.</div>
                                <div><b>- Death in the family</b> If this happens, the deceased person may be removed from your plan.</div>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <CustomContainer raised style={{background: '#FAFAFA', display: 'flex', alignItems: 'center'}}>
                                    <div>
                                        <Header as={"h4"} textAlign={"center"}>Changes in residence</Header>
                                        <Image style={{width: '145px'}} centered
                                               src={"https://brella-platform-assets.s3.amazonaws.com/common/Illustrations/QLE+Change+in+Residence.png"}/>
                                    </div>
                                </CustomContainer>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <p>
                                <b>- Moving to a different ZIP code or country</b> If you’ve moved, you may be eligible to make changes to your plan.<br/>
                                <b>- Moving to a different state</b> At this time, Brella is not available in all 50 states. If you move to a new state, you may be able to participate and make changes depending on your new state of residence. We are unable to issue certificates in the following states:
                                </p>
                                <table style={{marginBottom: '1em'}}>
                                    <tr>
                                        <td>
                                            Alabama<br/>
                                            California<br/>
                                            Connecticut<br/>
                                            Illinois<br/>
                                            Kentucky<br/>
                                            Maine<br/>
                                            Mississippi
                                        </td>
                                        <td style={{paddingLeft: '2em'}}>
                                            New Hampshire<br/>
                                            New Jersey<br/>
                                            New York<br/>
                                            North Carolina<br/>
                                            Rhode Island<br/>
                                            Vermont<br/>
                                            Washington
                                        </td>
                                    </tr>
                                </table>
                                <p>If you are an eligible employee living outside of Texas or moving to a state that is not listed above, you may be able to continue participating and make changes to your Brella plan.</p>
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                </Modal>
        )
    }
}

export default connect(mapStateToProps)(withRouter(QLEModal));
