import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {setLogin, setLogout} from '../../../actions/authActions'
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {CustomDivider, S3Image} from "../../custom-common";
import IMAGE from "../../../constants/images";
import {Button, Header} from "semantic-ui-react";
import LoginForm from "./loginForm";

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const goToRegister = () => {
    history.push({pathname: "/register"});
  }

  useEffect(() => {
    dispatch(setLogout())

    const urlSearchParams = new URLSearchParams(window.location.search);
    const authToken = urlSearchParams.get('t');

    if (!authToken) return

    const contractSfId = urlSearchParams.get('cSfId');
    const step = urlSearchParams.get('s');
    const username = urlSearchParams.get('n');

    setLoading(true)

    dispatch(setLogin({authToken, contractSfId, step, username}));
    // history.push({pathname: resolveStepUrl(step)});
  }, [dispatch, history])

  return (
      <div className={`memberLoginLayout ${loading && 'memberLoginLoading'}`}>
          <div className={'memberLoginInfoPanelLayout'}>
              <div className={'memberLoginBrand'}>
                  <S3Image {...IMAGE.OFFICIAL_LOGO}/>
              </div>
              <div className={'memberLoginInfoLayout'}>
                  <Header as={'h1'} color={'orange'} className={'memberLoginMainHeader'}>
                    Simple, group limited benefit insurance for all.
                  </Header>
              </div>
              <div className={'memberLoginSeparator'}></div>
          </div>
          <div className={'memberLoginContentPanel'}>
              <div className={'memberLoginRegisterWrapper'}>
                  <span className={'smaller'}>
                    Not yet registered? Create an account
                  </span>
                  <Button primary basic onClick={goToRegister}>Register</Button>
              </div>
              <div className={'memberLoginContent'}>
                  <Header as={'h3'} color={'orange'}>Sign in to your Member Portal</Header>
                  <p className={'small memberLoginContentText'}>
                    Enter your details below to access {STRATEGIC_PARTNER.LABEL}
                  </p>
                  <CustomDivider hidden/>
                  <LoginForm/>
                  <CustomDivider hidden/>
                  <div className={"small"}>
                      <i>ReliaStar Life Insurance Company (Minneapolis, MN), a member of the Voya® family of companies.</i>
                  </div>
              </div>
              <div className={'memberLoginFooter'}>
                  <div> © Ansel Health, Inc. All Rights Reserved</div>
                  <div className={'memberLoginFooterLinks'}>
                      <a href={STRATEGIC_PARTNER.LEGAL.TERMS} target="_blank">
                          Terms
                      </a>
                        <a href={STRATEGIC_PARTNER.LEGAL.PRIVACY} target="_blank">
                            Privacy
                        </a>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Login
