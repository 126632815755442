import React from 'react';
import {usePlaidLink} from 'react-plaid-link';
import {Button} from "semantic-ui-react";

const Plaid = ({ setPlaidData, plaidLinkToken, plaidEnv, strings }) => {
    const onSuccess = React.useCallback((_, metadata) => {
        setPlaidData(metadata)
    }, [setPlaidData]);
    const config = {
        token: plaidLinkToken,
        env: plaidEnv,
        onSuccess,
    };
    const { open, ready, error } = usePlaidLink(config);

    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    React.useEffect(() => {
        setButtonDisabled(!ready && !error)
    }, [ready, error])

    return (<Button primary basic onClick={open} disabled={buttonDisabled}>
        {strings.paymentRequired.addBankAccount}
    </Button>);
}
export default Plaid
