import {eConsentActionTypes} from "../actions/actionTypes";

const initialState = {
    signed: false,
    revoked: false,
};

export const eConsentReducer = (state = initialState, action) => {
    switch (action.type) {
        case eConsentActionTypes.SET_ECONSENT_SIGNED:
            return {
                ...state,
                signed: action.payload,
            }
        case eConsentActionTypes.SET_ECONSENT_REVOKED:
            return {
                ...state,
                revoked: true,
            }
        default:
            return state
    }
}