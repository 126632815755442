import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Divider, Grid, Icon, Loader, Message, Button, Header} from 'semantic-ui-react'
import {toggle as toggleGetHelpModal} from '../../actions/getHelpActions'
import {setLeftNavigation} from "../../actions/navigationActions";
import ThreadCard from './partials/threadCard'
import {NoContent} from './partials/noContent'
import Footer from '../footer'
import {MessagesContext} from '../../contexts/messages'

const mapDispatchToProps = dispatch => {
    return {
        toggleGetHelpModal: state => dispatch(toggleGetHelpModal(state)),
        setLeftNavigation: state => dispatch(setLeftNavigation(state)),
    }
}

class MessagesList extends Component {
    static contextType = MessagesContext

    componentDidMount() {
        const { setLeftNavigation } = this.props

        setLeftNavigation(true);
    }

    renderLoader = () => {
        return <div style={{height: 'calc(100vh - 150px)'}}><Loader active/></div>
    }

    render() {
        const {fetching, list: threads, refresh, fetchingError} = this.context
        const {toggleGetHelpModal} = this.props

        if (threads === null && fetching) return this.renderLoader()

        return (<>
            <div className="member-main-container">
                <Grid container stackable columns={1} centered>
                    <Grid.Column className={"pageContent"}>
                        <Divider hidden/>
                        {fetchingError && <Message negative>{fetchingError}</Message>}
                        <Divider hidden/>
                        <Grid columns={2} verticalAlign={"middle"}>
                            <Grid.Column>
                                <Header as="h2">
                                    Messages
                                    &nbsp;&nbsp;
                                    {!!threads?.length && (
                                        <Button
                                            primary basic compact
                                            size="tiny"
                                            loading={fetching}
                                            disabled={fetching}
                                            onClick={() => refresh()}
                                            aria-label="Check for new messages"
                                        ><Icon name="sync alternate" fitted/></Button>
                                    )}
                                </Header>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"}>
                                <Button primary content="Send new message"
                                        onClick={() => toggleGetHelpModal(true)}/>
                            </Grid.Column>
                        </Grid>
                        {!!threads?.length ? (<>
                            <Divider hidden/>
                            {threads.map(thread => (
                                <ThreadCard key={thread.caseId} thread={thread}/>
                            ))}
                        </>) : (!fetchingError && <NoContent/>)}
                    </Grid.Column>
                </Grid>
                <Footer showTopFooter={false} showCenterData={true}/>
            </div>
        </>)
    }
}

export default connect(null, mapDispatchToProps)(MessagesList);
